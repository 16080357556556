import React from 'react';
import { Center, Box, Text, VStack } from 'native-base';
import { useLinkTo } from '@react-navigation/native';
import { useDimensions } from '@react-native-community/hooks';
import { useCTSDimensions } from '@/hooks/useCTSDimensions';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';
import { useTranslation } from 'react-i18next';
import { AlertCard } from '@/components/shared/AlertCard';
import { MetaEventDetailsFragment } from '@/graphql/components';

interface Props {
  userId: string;
  threats: number;
  updateQuery: any;
  alert: MetaEventDetailsFragment;
}

const AlertMessage = ({ userId, threats, alert, updateQuery }: Props) => {
  const linkTo = useLinkTo();
  const { isDesktopWeb } = useCTSDimensions();
  const dimensions = useDimensions();
  const { t } = useTranslation();
  const bgColor = useThemeBackgroundColor('card');
  const secondary = useThemeFontColor('secondary');
  const threatText =
    threats > 1
      ? t('dashboardThreats', { days: '30', threats: threats })
      : t('dashboardThreat', { days: '30', threats: threats });

  const textSize = ['xl', '2xl'];
  const eventId = alert?.event_id || '';

  return (
    <Box
      justifyContent={'space-evenly'}
      bg={bgColor}
      borderRadius={10}
      p={5}
      {...(!isDesktopWeb || dimensions.window.width < 900 ? {} : { flex: 1 })}
    >
      <VStack width="100%" space={[3, 5]}>
        {threats === 0 ? (
          <>
            <Text fontSize={['lg', '2xl']}>
              {t('dashboardNoAlert', { days: '30' })}
            </Text>
          </>
        ) : (
          <>
            <Text
              fontSize={textSize}
              maxW="100%"
              overflowWrap={isDesktopWeb ? 'normal' : undefined}
            >
              {threatText}
            </Text>

            <Center mb={3}>
              <Text fontSize={['md', 'lg']} color={secondary}>
                {t('dashboardUpdate')}
              </Text>
            </Center>

            <AlertCard
              selectedAlert={eventId}
              updateQuery={updateQuery}
              currentUserId={userId}
              alert={alert}
            />

            <Center w="100%">
              <Text
                onPress={() => linkTo('/alerts')}
                fontSize={['md', 'lg']}
                color="orange.500"
              >
                {t('viewAll')}
              </Text>
            </Center>
          </>
        )}
      </VStack>
    </Box>
  );
};
export default AlertMessage;
