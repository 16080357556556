import React from 'react';
import { Box, Center, Text, useTheme } from 'native-base';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { ChartData } from '@/types/Dashboard/types';
import { useTranslation } from 'react-i18next';
import {
  useThemeFontColor,
  useThemeBackgroundColor,
} from '@/hooks/useThemeColor';

export function ThreatsChartsWeb({ data }: { data: ChartData[] }) {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const bgColorScreen = useThemeBackgroundColor('screen');
  const secondaryColor = useThemeFontColor('secondary2');

  const CustomTooltip = ({ active, payload }: any) => {
    return (
      active &&
      payload &&
      payload.length && (
        <Box bg={bgColorScreen} p={4} borderRadius={10}>
          <Text>
            {payload[0].payload.value}&nbsp;{t('alerts')}
          </Text>
        </Box>
      )
    );
  };

  return (
    <Center width="100%">
      <Text fontSize="lg" mb={5}>
        {t('dashboardAlertsTime')}
      </Text>
      <ResponsiveContainer
        minWidth="100%"
        width="100%"
        minHeight={340}
        height="100%"
        debounce={1}
      >
        <BarChart
          barGap={30}
          data={data}
          style={{
            fontFamily: 'Sarabun_500Medium',
            color: secondaryColor,
          }}
        >
          <Bar dataKey="value" type="monotone" fill={colors.orange[500]} />
          <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
          <XAxis dataKey="month" tick={{ fill: secondaryColor }} />
          <YAxis
            dataKey="value"
            tick={{ fill: secondaryColor }}
            stroke="#ccc"
            width={30}
          />
          <Tooltip cursor={false} content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    </Center>
  );
}
