import React, { PropsWithChildren } from 'react';
import { Box } from 'native-base';

import { useCTSDimensions } from '../hooks/useCTSDimensions';

interface Props {
  type?: 'slim' | 'wide';
  headerType?: 'tall' | 'short';
  bgColor?: string;
}

export function CustomBox({
  children,
  type,
  bgColor,
  headerType,
}: PropsWithChildren<Props>) {
  const { paddingTopTall, paddingTopShort, paddingHSlim, paddingHWide } =
    useCTSDimensions();

  const paddingTop = headerType === 'short' ? paddingTopShort : paddingTopTall;
  const paddingH = type === 'slim' ? paddingHSlim : paddingHWide;

  return (
    <Box
      testID="custom-box"
      flex={1}
      px={type && paddingH}
      pt={headerType && paddingTop}
      bg={bgColor ? bgColor : 'transparent'}
    >
      {children}
    </Box>
  );
}
