import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import { CtsEnv, CtsSite } from './types/cts';

const configs = {
  [CtsEnv.LAB]: {
    Auth: {
      identityPoolId: 'eu-central-1:1f27752b-f6e2-4a2c-b1dd-9e6c30acc001',
      userPoolId: 'eu-central-1_PBM2xHouq',
      userPoolWebClientId: 'n5lp0qbqbdcfpgjcknqab4v0m',
      region: 'eu-central-1',
    },
    oauth: {
      domain: 'cts-tenants-lab.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: Linking.createURL('/'),
      redirectSignOut: Linking.createURL('/'),
      responseType: 'code',
    },
    endpoints: {
      [CtsSite.EU]: {
        url: 'https://k3wurzlm3zei3din24xqtnnwoa.appsync-api.eu-central-1.amazonaws.com/graphql',
        region: 'eu-east-1',
      },
    },
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: '15b2663a15cb4e1a982252249a2bad9f',
      // Amazon service region
      region: 'eu-central-1',
      mandatorySignIn: false,
    },
  },
  [CtsEnv.LOCAL]: {
    Auth: {
      identityPoolId: 'eu-central-1:1f27752b-f6e2-4a2c-b1dd-9e6c30acc001',
      userPoolId: 'eu-central-1_KAhEuuEoE',
      userPoolWebClientId: '21qnjfjt1o5cjbqi6giugijn8p',
      region: 'eu-central-1',
    },
    oauth: {
      domain: 'cts-tenants-lab-local.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: Linking.createURL('/'),
      redirectSignOut: Linking.createURL('/'),
      responseType: 'code',
    },
    endpoints: {
      [CtsSite.EU]: {
        url: 'https://ukjgaw5bfjdcppd4wqeede3vey.appsync-api.eu-central-1.amazonaws.com/graphql',
        region: 'eu-central-1',
      },
    },
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: 'bf20f701df9a417d9bad3e29467300e0',
      // Amazon service region
      region: 'eu-central-1',
      mandatorySignIn: false,
    },
  },
  [CtsEnv.SIT]: {
    Auth: {
      identityPoolId: 'eu-central-1:03ae64d3-d91f-4538-87a8-843ab7df1710',
      userPoolId: 'eu-central-1_N4xoTjP2E',
      userPoolWebClientId: '391c9slc90jt785td8sdnji9rs',
      region: 'eu-central-1',
    },
    oauth: {
      domain: 'cts-tenants-develop.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: Linking.createURL('/'),
      redirectSignOut: Linking.createURL('/'),
      responseType: 'code',
    },
    endpoints: {
      [CtsSite.EU]: {
        url: 'https://4dvlagw2rfaxzkbmxsshss6d44.appsync-api.eu-central-1.amazonaws.com/graphql',
        region: 'eu-central-1',
      },
    },
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: 'c2b57418368a4c28b00576a7006d37db',
      // Amazon service region
      region: 'eu-central-1',
      mandatorySignIn: false,
    },
  },
  [CtsEnv.PROD]: {
    Auth: {
      identityPoolId: 'eu-central-1:a5f7ee88-d345-4683-9825-441aff12dbca',
      userPoolId: 'eu-central-1_mZLz0f6TO',
      userPoolWebClientId: '6memuo508k7fjauhk7t0e3turv',
      region: 'eu-central-1',
    },
    oauth: {
      domain: 'cts-tenants.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: Linking.createURL('/'),
      redirectSignOut: Linking.createURL('/'),
      responseType: 'code',
    },
    endpoints: {
      [CtsSite.EU]: {
        url: 'https://65riqm62sbdllpprbmzfpfozgm.appsync-api.eu-central-1.amazonaws.com/graphql',
        region: 'eu-east-1',
      },
      [CtsSite.JAPAN]: {
        url: 'https://3luplhe5pzelxmowexqenpk6ym.appsync-api.ap-northeast-1.amazonaws.com/graphql',
        region: 'ap-northeast-1',
      },
    },
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: '2699b7d12790401d874dd045a605acab',
      // Amazon service region
      region: 'eu-central-1',
      mandatorySignIn: false,
    },
  },
};

function getConfig() {
  const releaseChannel = Constants?.manifest?.releaseChannel;
  console.log('Did deploy from separate job');
  console.log('RELEASE CHANNEL: ', releaseChannel);

  // Dont do optional chaining here: process?.env?.CTS_ENV. No idea why but that breaks it
  const processEnv = process && process.env && process.env.CTS_ENV;
  console.log('PROCESS ENV: ', processEnv);

  const target = (releaseChannel || processEnv || 'local') as CtsEnv;
  console.log('TARGET: ', target);

  if (Object.values(CtsEnv).includes(target)) {
    console.log('Returning valid target config');
    return configs[target];
  }

  console.log('Returning default local config');

  return configs[CtsEnv.LOCAL];
}

const config = getConfig();

// // export default getConfig();

// const config =
//   // eslint-disable-next-line
//   //@ts-ignore
//   configs[(process && process.env && process.env.CTS_ENV) || 'local'];

// console.log(process && process.env && process.env.CTS_ENV);

export default config;
