import { Analytics } from 'aws-amplify';

export function registerPageView(page: string) {
  try {
    Analytics.record({
      name: 'pageNavigation',
      attributes: {
        page,
      },
    });
  } catch (e) {
    console.log(e);
  }
}
