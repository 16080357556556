import { gql, useApolloClient } from '@apollo/client';
import { Box, Text, Spinner } from 'native-base';
import { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface Section {
  title: string;
  content: string;
  translated_content?: string; // Optional translated content
  vars: { [key: string]: string };
  display_order: number;
}

const GET_TRANSLATION = gql`
  query GetTranslation($text: String!) {
    getTranslation(text: $text) {
      translation
    }
  }
`;

const interpolateContent = (content: string | undefined, vars: { [key: string]: string }) => {
  if (!content) return '';
  return content.replace(/{(\w+)}/g, (_, key) => vars[key] || `{${key}}`);
};

export const Faererec = ({ faererec }: { faererec: Section[] }) => {
  const client = useApolloClient();
  const [sections, setSections] = useState(faererec);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<string>('en-US'); // Default language

  useEffect(() => {
    const fetchLanguageAndTranslations = async () => {
      setIsLoading(true);

      try {
        const storedLanguage = await AsyncStorage.getItem('settings.lang');
        if (storedLanguage) setCurrentLanguage(storedLanguage);
      } catch (error) {
        console.error('Failed to fetch language setting:', error);
      }

      if (currentLanguage === 'ja-JP') {
        const translationPromises = faererec.map(section =>
          client.query({
            query: GET_TRANSLATION,
            variables: { text: section.content }
          }).then(response => ({
            ...section,
            translated_content: response.data.getTranslation.translation
          })).catch(() => ({
            ...section,
            translated_content: section.content
          }))
        );

        const updatedSections = await Promise.all(translationPromises);
        setSections(updatedSections);
      } else {
        setSections(faererec.map(section => ({
          ...section,
          translated_content: section.content
        })));
      }

      setIsLoading(false);
    };

    fetchLanguageAndTranslations();
  }, [faererec, client, currentLanguage]);

  if (isLoading) {
    return <Spinner color="orange.500" size="sm" />;
  }

  return (
    <>
      {sections.map((section, index) => (
        <Box key={index} my={2}>
          <Text bold fontSize="md">
            {section.title}
          </Text>
          <Text>{currentLanguage === 'ja-JP' ? interpolateContent(section.translated_content, section.vars) : interpolateContent(section.content, section.vars)}</Text>
        </Box>
      ))}
    </>
  );
};

