export enum CtsEnv {
  LAB = 'lab',
  LOCAL = 'local',
  SIT = 'sit',
  PROD = 'prod',
}

export enum CtsSite {
  JAPAN = 'jp1',
  EU = 'eu1',
  US = 'us1',
}
