import React from 'react';
import {
  Center,
  HStack,
  Pressable,
  Select,
  Text,
  VStack,
} from 'native-base';

import {
  useThemeFontColor,
  useThemeBackgroundColor,
} from '@/hooks/useThemeColor';
import { Device } from '@/graphql/components';
import { CheckSimpleIcon, XIcon } from '@/components/CustomIcons';

export const DeviceFilter = ({
  localDevices,
  setLocalDevices,
  data,
  t,
}: {
  localDevices: string[];
  setLocalDevices: React.Dispatch<React.SetStateAction<string[]>>;
  data: any;
  t: any;
}) => {
  return (
    <VStack space={2}>
      <Text fontSize="lg">{t('alertModalFilterBy')}</Text>
      {localDevices.map((item, index) => (
        <HStack justifyContent="space-between" key={index}>
          <Text>
            {data?.listTenantDevices?.items?.filter((i: Device) => i.id === item)[0].alias ||
              item}
          </Text>
          {localDevices[0] !== undefined && (
            <Center w={4}>
              <Pressable
                onPress={() => setLocalDevices(localDevices.filter((i) => item !== i))}
              >
                <XIcon iconSize={3} color={useThemeFontColor('primary')} />
              </Pressable>
            </Center>
          )}
        </HStack>
      ))}
      <Select
        selectedValue={''}
        flex={1}
        accessibilityLabel="devices"
        placeholder={t('alertModalAddDevice')}
        _selectedItem={{
          bg: 'orange.500',
          endIcon: <CheckSimpleIcon iconSize={5} color={useThemeFontColor('primary')} />,
        }}
        fontSize="md"
        onValueChange={(itemValue) => setLocalDevices([...localDevices, itemValue])}
        bg={useThemeBackgroundColor('input')}
        borderWidth={0}
        p={1.5}
      >
        {data?.listTenantDevices?.items
          ?.filter((item: Device) => !localDevices.includes(item.id))
          .map((item: Device, index: number) => (
            <Select.Item key={index} label={item.alias || item.name} value={item.id} />
          ))}
      </Select>
    </VStack>
  );
};
