import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from 'native-base';
import { gql, useQuery } from '@apollo/client';

import { timeFrameOptions } from '@/utils/Dashboard/utils';
import { useThemeBackgroundColor } from '@/hooks/useThemeColor';
import { TimeSelector } from '@/components/Dashboard/TimeSelector';
import EventsFunnel from "@/components/Dashboard/EventsFunnel"
import { getMetaEventCountDates } from '@/utils/Dashboard/utils';

const CURRENTUSERQUERY = gql`
  query CurrentUserQuery{
    getCurrentUser {
      id
      tenant {
        id
      }
    }
  } 
`

const PROMMETRICQUERY = gql`
  query PromMetricQuery(
    $events: String!
    $indicatorOfThreats: String!
    $to: AWSDateTime!
    $from: AWSDateTime!
    $type: String
  ) {
    events: prometheusQuery(query: $events, type: "query") {
      ... on PrometheusInstantResponse {
        status
        data {
          result {
            value {
              metricValue
            }
          }
        }
      }
    }
    threats: prometheusQuery(query: $indicatorOfThreats, type: "query") {
      ... on PrometheusInstantResponse {
        status
        data {
          result {
            value {
              metricValue
            }
          }
        }
      }
    }
    getTenantMetaEventCount(from: $from, to: $to, type: $type)
  }
`

interface EventsFunnelContainerProps {
  refetchFunctions: (() => Promise<any>)[];
}

export const EventsFunnelContainer: React.FC<EventsFunnelContainerProps> = ({ refetchFunctions }) => {
  const [selectedIndex, setSelectedIndex] = useState(3);
  const [tenantId, setTenantId] = useState()

  const { range, offset } = timeFrameOptions[selectedIndex];
  const { dateTo, dateFrom } = getMetaEventCountDates(offset);

  const { data, refetch: refetchData, loading } = useQuery(PROMMETRICQUERY, {
    skip: !tenantId,
    variables: {
      events: `sum(increase(dredd_wrangling_parsed_total_counter{customername="${tenantId}"}[${range}]))`,
      indicatorOfThreats: `sum(increase(dredd_alerts_total_counter{customername="${tenantId}"}[${range}]))`,
      to: dateTo,
      from: dateFrom,
      type: 'bounceralert',

    }
  })

  const { loading: currentUserQueryLoading } = useQuery(CURRENTUSERQUERY, {
    onCompleted: (data) => {
      setTenantId(data.getCurrentUser.tenant.id)
    }
  })
  useEffect(() => {
    if (tenantId && !data) {
      refetchData()
    }
  }, [tenantId, refetchData])

  React.useEffect(() => {
    refetchFunctions.push(refetchData) 
  },[])
  const eventsAnalyzed = data ? [
    {
      label: "Events",
      value: Math.round(Number(data?.events?.data?.result[0]?.value[0]?.metricValue)) || 0
    },
    {
      label: "Indicators of Threats",
      value: Math.round(Number(data?.threats?.data?.result[0]?.value[0]?.metricValue)) || 0
    },
    {
      label: "Incidents",
      value: data?.getTenantMetaEventCount
    },
  ] : []

  const bgColor = useThemeBackgroundColor('card');

  if (currentUserQueryLoading || loading) {
    return <Skeleton width="100%" height={510} borderRadius={10} />;
  }

  return (
    <Box bg={bgColor} borderRadius={10} overflow="hidden" px={5} py={5}>
      <EventsFunnel eventsAnalyzed={eventsAnalyzed} />
      <TimeSelector
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
    </Box>
  );
}

