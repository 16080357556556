import React from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Text, VStack } from 'native-base';

import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';

interface Props {
  initKey: string;
  hasCopied?: boolean;
}

export default function InitKeyCard({ initKey, hasCopied }: Props) {
  const { t } = useTranslation();
  const inputColor = useThemeBackgroundColor('input');
  const secondary = useThemeFontColor('secondary');
  return (
    <VStack space={3}>
      <Center width="100%" bg={inputColor} borderRadius={6} p={2.5}>
        <Text fontSize="xl" bold color="orange.500">
          {initKey}
        </Text>
      </Center>
      <Center>
        <Text color={secondary}>
          {hasCopied ? t('deviceInitCopied') : t('deviceInitCopy')}
        </Text>
      </Center>
    </VStack>
  );
}
