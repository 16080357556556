import React from 'react';
import {
  Box,
  HStack,
  Pressable,
  useColorMode,
  useColorModeValue,
  useTheme,
} from 'native-base';
import {
  useRoute,
  getFocusedRouteNameFromRoute,
  useLinkTo,
} from '@react-navigation/native';

import * as Icons from '@/components/CustomIcons';

export function BottomTab({ unseen }: { unseen: number }) {
  const linkTo = useLinkTo();
  const route = useRoute();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();

  const bgColor = useColorModeValue('white.50', 'black.900');
  const borderColor = useColorModeValue('white.50', 'black.900');
  const [selected, setSelected] = React.useState(0);

  const routeName = getFocusedRouteNameFromRoute(route);
  const isDark = colorMode === 'dark' ? true : false;

  React.useEffect(() => {
    setCurrentScreen();
  }, [routeName]);

  const setCurrentScreen = () => {
    switch (routeName) {
      case 'Device':
        return setSelected(1);
      case 'Alert':
        return setSelected(2);
      case 'Settings':
        return setSelected(3);
      default:
        return setSelected(0);
    }
  };

  const iconColor = !isDark ? colors.black['900'] : colors.white['50'];
  //determines size of navbar and icons

  const iconSize = 10;
  const selectedHeight = 1.5;
  const selectedWidth = 6;

  type BoxProps = {
    children?: React.ReactNode | false;
  };

  const CustomBox = ({ children }: BoxProps) => (
    <Box width={30}>{children}</Box>
  );

  const Selected = () => (
    <Box
      alignSelf="center"
      bg={iconColor}
      height={selectedHeight}
      rounded="lg"
      width={selectedWidth}
    />
  );
  return (
    <Box
      alignSelf="center"
      backgroundColor={bgColor}
      borderTopWidth={.5}
      pt={2}
      pb={2}
      borderTopColor={colors.gray['300']}
      justifyContent="center"
      width={'100%'}
    >
      <HStack
        alignItems="center"
        backgroundColor="transparent"
        justifyContent="space-evenly"
      >
        <CustomBox>
          <Pressable
            onPress={() => {
              linkTo('/dashboard');
            }}
          >
            <Icons.DashboardIcon iconSize={iconSize} color={iconColor} />
          </Pressable>
        </CustomBox>

        <CustomBox>
          <Pressable
            onPress={() => {
              linkTo('/devices');
            }}
          >
            <Icons.DevicesIcon iconSize={iconSize} color={iconColor} />
          </Pressable>
        </CustomBox>

        <CustomBox>
          <Pressable
            onPress={() => {
              linkTo('/alerts');
            }}
          >
            <Icons.NotificationsEmptyIcon
              iconSize={iconSize}
              color={iconColor}
            />
            {unseen > 0 && (
              <Box
                bg="orange.500"
                rounded="full"
                h={4}
                w={4}
                mb={-4}
                position="relative"
                bottom={4}
                left={4}
                borderColor={borderColor}
                borderWidth={2}
              />
            )}
          </Pressable>
        </CustomBox>

        <CustomBox>
          <Pressable
            onPress={() => {
              linkTo('/settings');
            }}
          >
            <Icons.SettingsIcon iconSize={iconSize} color={iconColor} />
          </Pressable>
        </CustomBox>
      </HStack>

      <HStack
        alignItems="center"
        backgroundColor="transparent"
        justifyContent="space-evenly"
      >
        <CustomBox>{selected === 0 && <Selected />}</CustomBox>
        <CustomBox>{selected === 1 && <Selected />}</CustomBox>
        <CustomBox>{selected === 2 && <Selected />}</CustomBox>
        <CustomBox>{selected === 3 && <Selected />}</CustomBox>
      </HStack>
    </Box>
  );
}
