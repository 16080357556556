import React, { useEffect } from 'react';
import { ScrollView, Center, Spinner } from 'native-base';
import { useRoute, RouteProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { useGetTenantDeviceQuery } from '@/graphql/components';

import { Error } from '@/components/Error';
import { DeviceDetailsContainer } from '@/containers/Device/DeviceDetailsContainer';
import { DeviceStackParamList } from '@/navigation/DeviceNavigator';
import { registerPageView } from '@/analytics/navigation';

export function DeviceDetailsScreen() {
  const { t } = useTranslation();
  const { params } =
    useRoute<RouteProp<DeviceStackParamList, 'DeviceDetails'>>();

  useEffect(() => {
    registerPageView('deviceDetails');
  }, []);

  const { data, error, loading, refetch } = useGetTenantDeviceQuery({
    variables: {
      id: params.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const device = data?.getTenantDevice;

  if (loading) {
    return (
      <Center flex={1}>
        <Spinner color="orange.500" size="lg"/>
      </Center>
    );
  }

  if (error || !device) {
    return <Error text={t('errorDevice')} retry={() => refetch()} />;
  }

  return (
    <ScrollView
      flexGrow={1}
      px={4}
      contentContainerStyle={{
        justifyContent: 'center',
      }}
    >
      <DeviceDetailsContainer device={device} />
    </ScrollView>
  );
}
