import React from 'react';
import { View, Text, Center } from 'native-base';
import { Svg, Polygon } from 'react-native-svg';
import { scaleLinear } from 'd3-scale';
import { FunnelData } from '@/types/Dashboard/types';

interface Props {
  data: FunnelData[] | [];
  width: number;
  height: number;
  colors: string[];
  header: string;
}

const FunnelChart = ({ data, width, height, colors, header }: Props) => {
  const padding = 16;
  const chartHeight = height - padding;

  const y = scaleLinear().domain([0, chartHeight]).range([0, chartHeight]);


  const generatePolygonPoints = (i: number) => {
    const topWidth = (1 - i / data.length) * (width / 2);
    const bottomWidth = (1 - (i + 1) / data.length) * (width / 2);
    const yPosition = y(i * (chartHeight / data.length));
    const padding = 13

    return `
    ${width / 2 - topWidth + padding/2},${yPosition + padding}
    ${width / 2 + topWidth - padding/2},${yPosition + padding}
    ${width / 2 + bottomWidth},${yPosition + chartHeight / data.length}
    ${width / 2 - bottomWidth},${yPosition + chartHeight / data.length}
  `;
  };



  const renderLegend = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        {data.map((d, i) => (
          <View
            key={i}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 4,
              marginLeft: 8,
              marginRight: 8,
            }}
          >
            <View
              style={{
                width: 16,
                height: 16,
                backgroundColor: colors[i % colors.length],
                marginRight: 4,
                borderColor: "black",
                borderWidth: 1
              }}
            />
            <Text>{d.label}</Text>
          </View>
        ))}
      </View>
    );
  };

  return (
    <Center>

      <Text fontSize="lg" mb={5}>
        {header}
      </Text>
      <View style={{ alignItems: "center" }}>
        <Svg width={width} height={chartHeight}>
          {data.map((_, i) => (
            <Polygon
              key={i}
              points={generatePolygonPoints(i)}
              fill={colors[i % colors.length]}
              stroke="black"
              strokeWidth={1}
            />
          ))}
        </Svg>
        {data.map((d, i) => (
          <Text
            key={i}
            style={{
              position: 'absolute',
              top: y(i * (chartHeight / data.length)) + chartHeight / data.length / 2 - 12,
              fontSize: 14,
            }}
          >
            {d.value}
          </Text>
        ))}
      </View>
      <View style={{ marginTop: 16 }}>{renderLegend()}</View>
    </Center>
  );
};

export default FunnelChart;
