import React, { useEffect } from 'react';
import { useLinkTo, useRoute, RouteProp } from '@react-navigation/native';

import { ResetPassword } from '@/components/Auth/ResetPassword';
import { AuthStackParamList } from '@/navigation/AuthNavigator';

export function ResetPasswordScreen() {
  const linkTo = useLinkTo();
  const { params } = useRoute<RouteProp<AuthStackParamList, 'ResetPassword'>>();
  // Redirect to sign in if no reset code provided
  useEffect(() => {
    if (!params?.resetCode || !params?.email) {
      linkTo('/auth/signIn');
    }
  }, [params?.resetCode, params?.email]);

  function linkToSignIn() {
    linkTo('/auth/signIn');
  }

  function linkToForgotPassword() {
    linkTo(`/auth/forgotPassword?email=${params?.email}`);
  }

  return (
    <ResetPassword
      email={params?.email.toLowerCase()}
      resetCode={params?.resetCode}
      linkToSignIn={linkToSignIn}
      linkToForgotPassword={linkToForgotPassword}
    />
  );
}
