
import React from 'react';
import { SafeAreaView } from 'react-native';
import FunnelChart from '@/components/Charts/FunnelChart';
import { FunnelData } from '@/types/Dashboard/types';
import { useTranslation } from 'react-i18next';

interface Props {
  eventsAnalyzed?: FunnelData[];
}

const EventsFunnel = ({ eventsAnalyzed }: Props) => {
  const { t } = useTranslation()

  const header =
    !eventsAnalyzed || !!eventsAnalyzed.length
      ? t('dashboardEventsAnalyzed')
      : t('dashboardNoData');

  const colors = [
    '#D18228',
    '#EA6B2F',
    '#EF4444',
    '#EAAE2F',
    '#F3A64D',
  ];

  return (
    <SafeAreaView>
      <FunnelChart data={eventsAnalyzed || []} width={330} height={350} colors={colors} header={header} />
    </SafeAreaView>
  );
};

export default EventsFunnel;
