import React from 'react';
import { useLinkTo } from '@react-navigation/native';

import { SignIn } from '@/components/Auth/SignIn';
import { useInitialParams } from '@/hooks/useInitialParams';

export function SignInScreen() {
  const [params] = useInitialParams();
  const linkTo = useLinkTo();

  function linkToForgotPassword(email: string) {
    linkTo(`/auth/forgotPassword?email=${email}`);
  }

  function linkToCreateTenant() {
    linkTo(`/auth/createTenant?initKey=${params.initKey}`);
  }

  function linkToVeriyEmail(email: string) {
    linkTo(`/auth/verifyEmail?email=${email}`);
  }

  return (
    <SignIn
      showCreateTenantLink={!!params.initKey}
      linkToCreateTenant={linkToCreateTenant}
      linkToForgotPassword={linkToForgotPassword}
      linkToVerifyEmail={linkToVeriyEmail}
    />
  );
}
