import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import { Button } from 'native-base';

import { AuthForm } from '@/components/Forms/AuthForm';
import { FormInput } from '@/components/Forms/form-input.component';
import { ForgotPasswordSchema } from '@/components/Forms/validationSchemas';
import { submitOnEnter } from '@/utils/form';
import { useTranslation } from 'react-i18next';

interface Props {
  email?: string;
  linkToSignIn: () => void;
}

export function ForgotPassword({ email, linkToSignIn }: Props) {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: email || '' }}
      validateOnBlur
      validationSchema={ForgotPasswordSchema}
      onSubmit={async (values, actions) => {
        setError('');
        setSuccess('');
        try {
          await Auth.forgotPassword(values.email.toLowerCase());
          setSuccess(t('emailSuccess'));
        } catch (e) {
          setError(t('errorDefault'));
          actions.setSubmitting(false);
        }
      }}
    >
      {(props) => (
        <AuthForm error={error} success={success}>
          <FormInput
            id="email"
            placeholder={t('email')}
            keyboardType="email-address"
            autoComplete="username"
            autoCompleteType="email"
            autoCapitalize="none"
            onKeyPress={submitOnEnter(props.submitForm)}
          />
          <Button
            disabled={props.isSubmitting}
            onPress={props.submitForm}
            isLoading={props.isSubmitting}
            colorScheme="orange"
            _text={{
              color: 'white.50',
              // fontSize: 'xl',
            }}
            testID="Resend Email"
          >
            {success ? t('emailResend') : t('emailSendReset')}
          </Button>
          <Button
            disabled={props.isSubmitting}
            size="lg"
            variant="link"
            _text={{
              color: 'orange.500',
            }}
            onPress={linkToSignIn}
            testID="Sign In"
          >
            {t('signIn')}
          </Button>
        </AuthForm>
      )}
    </Formik>
  );
}
