import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, VStack } from 'native-base';
import Card from './Card';
import { metricMap } from '@/utils/Device/utils';
import { DeviceProps } from '@/types/Device/types';

interface DeviceMetrics {
  deviceInfo: DeviceProps;
}

const DeviceInfoCard = ({ deviceInfo }: DeviceMetrics) => {
  const { t } = useTranslation();

  const primaryMap: DeviceProps = {
    cpuProcessor: t('deviceProcessor'),
    os: t('deviceOs'),
    machine: t('deviceMachine'),
    cpuCoresP: t('deviceCores'),
  };

  const secondaryMap: DeviceProps = {
    disks: t('deviceDisks'),
    nics: t('deviceNics'),
    memorySwap: t('deviceMemorySwap'),
    memoryTotal: t('deviceMemoryTotal'),
  };

  const firstColumn = metricMap({
    metricMap: primaryMap,
    metricInfo: deviceInfo,
  });
  const secondColumn = metricMap({
    metricMap: secondaryMap,
    metricInfo: deviceInfo,
  });

  if (firstColumn || secondColumn) {
    return (
      <Card>
        <VStack space={3}>
          <HStack space={3}>
            {firstColumn}
            {secondColumn}
          </HStack>
        </VStack>
      </Card>
    );
  } else {
    return null;
  }
};

export default DeviceInfoCard;
