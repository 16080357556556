import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { Box, HStack, Text, VStack } from 'native-base';
import { GetMetaEventDetailsFragment } from 'graphql/components';
import { Card } from "@/components/Alerts/Card"

interface ReportData {
  [key: string]: any;
}

interface Props {
  mispData?: any;
  reportData?: ReportData[];
  alert: GetMetaEventDetailsFragment;
}

export default function AttackCard({ alert, mispData, reportData }: Props) {
  const { t } = useTranslation();
  const orange = 'orange.500';

  const parseForKillchain = (reportData: any) => {
    const response: string[] = [];
    reportData?.details?.signatures?.forEach((element: any) => {
      if (Object.keys(element).includes('killchain')) {
        response.push(element.killchain);
      }
    });
    return [...new Set(response)];
  };

  const killchain = parseForKillchain(reportData);
  const networkActivity = mispData ?
    mispData[Object.keys(mispData)[0]]?.Tags?.[0]?.galaxy?.meta?.synonyms :
    null;
  const mispDescription = mispData ?
    mispData[Object.keys(mispData)[0]]?.Tags?.[0]?.galaxy?.description : null;
  const mispRefs = mispData ?
    mispData[Object.keys(mispData)[0]]?.Tags?.[0]?.galaxy?.meta?.refs : null;
  return (
    <Card>
      <VStack space={3}>
        <HStack space={3}>
          <VStack w="50%" space={3}>
            <VStack w="100%">
              <Text bold w="100%">
                {t('alertAttack')}
              </Text>
              <VStack w="100%">
                {killchain.length ? (
                  killchain.map((string, index) => {
                    return (
                      <Text pl={2} key={index}>
                        {string.charAt(0) +
                          string.slice(1).toLowerCase().replace('/_/g', ' ')}
                      </Text>
                    );
                  })
                ) : (
                  <Text>{t('alertNA')}</Text>
                )}
              </VStack>
            </VStack>
          </VStack>

          <VStack w="100%" space={3}>
            <VStack w="50%">
              <Text bold w="100%">
                {t('alertDetectingDevice')}
              </Text>
              <VStack w="100%" space={3} pl={2}>
                {alert.device_alias ? (
                  <Text>{alert.device_alias}</Text>
                ) : (
                  <Text>{t('alertNA')}</Text>
                )}
              </VStack>
            </VStack>
          </VStack>
        </HStack>

        {/* start misp data */}
        {networkActivity && (
          <>
            <Box w="100%" height="1px" bgColor={orange} />
            <Text bold>{t('alertNetworkActivity')}</Text>
            <HStack w="100%" space={3}>
              {networkActivity.map((item: string, index: number) => (
                <Box
                  key={index}
                  py={1}
                  px={4}
                  bgColor={orange}
                  borderRadius="100px"
                >
                  <Text>{item}</Text>
                </Box>
              ))}
            </HStack>
          </>
        )}

        {mispDescription && (
          <>
            <Box w="100%" height="1px" bgColor={orange} />
            <Text bold>{t('alertDescription')}</Text>
            <Text>{mispDescription}</Text>
          </>
        )}

        {mispRefs && mispRefs.length && (
          <>
            <Text bold>{t('alertReferences')}</Text>
            <VStack w="100%">
              {mispRefs.slice(0, 5).map((item: string, index: number) => (
                <Text
                  key={index}
                  onPress={() => Linking.openURL(item)}
                  color="orange.500"
                >
                  {item}
                </Text>
              ))}
            </VStack>
          </>
        )}
      </VStack>
    </Card>
  );
}
