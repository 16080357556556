import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Center, Text, VStack, HStack, Skeleton, Button } from 'native-base';
import { AlertListFiltersContainer } from "@/containers/Alerts/AlertListFiltersContainer"
import { storedAlert } from '@/utils/Alert/utils';
import { AlertCard } from '@/components/shared/AlertCard';
import { useQuery } from '@apollo/client'
import { MarkSeenUnseen } from '@/components/Alerts/MarkSeenUnseen';
import {
  useThemeBackgroundColor,
} from '@/hooks/useThemeColor';
import { MetaEvent, TableCtsMetaEventsFilterInput } from '@/graphql/components';
import { ALERT_LIST_QUERY, CURRENT_USER_ID_QUERY } from '@/graphql/AlertListQueries';

interface AlertListContainerProps {
  refetchFunctions: (() => Promise<any>)[];
}

export const AlertListContainer: React.FC<AlertListContainerProps> = ({ refetchFunctions }) => {
  const [userId, setUserId] = useState()
  const [selectedAlert, setSelectedAlert] = useState('');
  const { t } = useTranslation();
  const cardBG = useThemeBackgroundColor('card');
  const [filters, setFilters] = useState<TableCtsMetaEventsFilterInput>({ event_name: { eq: 'bounceralert' } })
  const [nextToken] = useState<string>("");
  const {
    data,
    refetch: refetchData,
    loading,
    updateQuery,
    variables: currentFilterVariables,
    fetchMore
  } = useQuery(ALERT_LIST_QUERY, {
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: filters,
      limit: 30,
      nextToken: nextToken,
    }
  })

  const { loading: currentUserIDQueryLoading } = useQuery(CURRENT_USER_ID_QUERY, {
    onCompleted: (data) => {
      setUserId(data?.getCurrentUser?.id)
    }
  })

  useEffect(() => {
    if (userId && !data) {
      refetchData()
    }
  }, [userId, refetchData, filters])

  useEffect(() => {
    refetchFunctions.push(refetchData)
  },[])

  useEffect(() => {
    storedAlert.get().then((current) => {
      current && setSelectedAlert(current);
    });
  }, [selectedAlert]);

  const fetchMoreAlerts = () => {
    const nextToken = data?.listTenantMetaEvents?.nextToken;

    if (!nextToken) {
      return;
    }

    fetchMore({
      variables: {
        ...currentFilterVariables,
        nextToken: nextToken,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const prevItems = prev?.listTenantMetaEvents?.items ?? [];
        const nextItems = fetchMoreResult?.listTenantMetaEvents?.items ?? [];
        const nextToken =
          fetchMoreResult?.listTenantMetaEvents?.nextToken ?? null;

        const items = prevItems.concat(nextItems);

        const next = {
          ...prev,
          listTenantMetaEvents: {
            ...prev.listTenantMetaEvents,
            items,
            nextToken,
          },
        };

        return next;
      },
    });
  };

  return (
    <>
      {currentUserIDQueryLoading || loading ? (
        <VStack space={2} px={5} width="100%" maxW="1000px">
          <HStack
            justifyContent="space-between"
            maxW="1000px"
            mb={2}
            width="100%"
          >
            <Skeleton h="6" w="5%" />
            <Skeleton h="6" w="15%" />
          </HStack>
          {Array.from(Array(12)).map((_, index) => (
            <VStack
              borderColor={cardBG}
              borderRadius={10}
              borderWidth={1}
              justifyContent="space-between"
              key={index}
              marginBottom={2}
              p={5}
              space={2}
              width="100%"
              maxW="1000px"
            >
              <Skeleton h="5" w="50%" />
              <Skeleton h="4" w="20%" />
            </VStack>
          ))}
        </VStack>
      ) :
        <>
          <HStack
            justifyContent="space-between"
            px={5}
            maxW="1000px"
            mb={2}
            width="100%"
            space={2}
          >

            <AlertListFiltersContainer
              userId={userId || ""}
              setFilters={setFilters}
              currentFilterVariables={
                currentFilterVariables ?? currentFilterVariables
              }
            />
            <MarkSeenUnseen
              updateQuery={updateQuery}
              alerts={data?.listTenantMetaEvents?.items}
              currentUserId={userId || ""}
            />
          </HStack>
          {
            data?.listTenantMetaEvents?.items?.length === 0 ?
              <Center>
                <Text fontSize="2xl" testID="no-alerts">
                  {t('alertNone')}
                </Text>
              </Center>
              :
              <VStack space={2} px={5} width="100%" maxW="1000px">
                {data?.listTenantMetaEvents?.items?.map((item: MetaEvent, index: number) => (
                  <AlertCard
                    key={index}
                    updateQuery={updateQuery}
                    currentUserId={userId || ''}
                    alert={item}
                    selectedAlert={selectedAlert}
                    setSelectedAlert={setSelectedAlert}
                  />

                ))}
              </VStack>
          }
          {data?.listTenantMetaEvents?.nextToken && (
            <Button
              onPress={() => fetchMoreAlerts()}
              variant="ghost"
              _text={{
                color: 'orange.500',
              }}
              isLoading={currentUserIDQueryLoading || loading}
              isLoadingText={t('alertFetchMore')}
              testID="load-more"
            >
              {t('alertViewEarlier')}
            </Button>
          )}
        </>
      }
    </>
  );
}
