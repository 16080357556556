import React from 'react';
import { Skeleton } from 'native-base';
import { gql, useQuery } from '@apollo/client';
import { getMetaEventCountDates } from '@/utils/Dashboard/utils';
import AlertMessage from '@/components/Dashboard/AlertMessage';
import { Platform } from 'react-native';

const DASHBOARDALERT = gql`
  query DashBoardAlert(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $type: String
    $limit: Int
  ) {
    getCurrentUser {
      id
    }
    listTenantMetaEvents(
      limit: $limit
      filter: { event_name: { eq: "bounceralert" } }
    ) {
      items {
        SK
        title
        timestamp
        seen_by
        event_id
      }
    }
    getTenantMetaEventCount(from: $from, to: $to, type: $type)
  }
`;

interface AlertContainerProps {
  refetchFunctions: (() => Promise<any>)[];
}

export const AlertContainer: React.FC<AlertContainerProps> = ({ refetchFunctions }) => {
  const { dateTo, dateFrom } = getMetaEventCountDates();

  const { data, loading, updateQuery, refetch } = useQuery(DASHBOARDALERT, {
    variables: {
      from: dateFrom,
      to: dateTo,
      type: 'bounceralert',
      limit: 1,
    },
  });

  React.useEffect(() => {
    refetchFunctions.push(refetch) 
  },[])
  const userId = data?.getCurrentUser?.id || '';
  const threats = data?.getTenantMetaEventCount;
  const alert = data?.listTenantMetaEvents?.items[0];

  return loading ? (
    <Skeleton width={Platform.OS == 'web' ? '50%' : '100%'} height="300px" borderRadius={10} />
  ) : (
    <AlertMessage
      userId={userId}
      threats={threats}
      alert={alert}
      updateQuery={updateQuery}
    />
  );
}
