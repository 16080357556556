import React from 'react';
import { VStack } from 'native-base';
import { DeviceListDeviceFragment } from 'graphql/components';

import { DeviceCard } from '@/components/shared/DeviceCard';
import { localStorage } from '@/utils/localStorage';

interface Props {
  devices: DeviceListDeviceFragment[];
  fetchMore: () => void;
  fetchingMore: boolean;
  hasMore: boolean;
  statusOrder: string | null;
}

export function DeviceListContainer({ devices, statusOrder }: Props) {
  const [selectedDevice, setSelectedDevice] = React.useState<string>('');

  React.useEffect(() => {
    localStorage.get('storedDevice').then((current) => {
      current && setSelectedDevice(current);
    });
  }, []);

  React.useEffect(() => {
    localStorage.set('storedDevice', selectedDevice);
  }, [selectedDevice]);

  const onlineDevicesFirst = (array: DeviceListDeviceFragment[]) => {
    return array.sort((a, b) => {
      const statusA = a.status?.state || 0;
      const statusB = b.status?.state || 0;
      if (statusA > statusB) return -1;
      if (statusB > statusA) return 1;
      return 0;
    });
  };
  const filteredDevices = statusOrder
    ? devices.filter((element) => element.status?.state === statusOrder)
    : onlineDevicesFirst(devices);

  return (
    <VStack
      width="100%"
      space={2}
      maxWidth="1000px"
      px={5}
      pb={5}
      testID="device-card-list"
    >
      {filteredDevices.map((item) => (
        <DeviceCard
          item={item}
          key={item.id}
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
        />
      ))}
    </VStack>
  );
}
