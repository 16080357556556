import { Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import {
  ListTenantMetaEventsQuery,
  MetaEvent,
  useUpdateSeenByMutation,
} from '@/graphql/components';

interface Props {
  updateQuery: any;
  alerts: any;
  currentUserId: string;
}

export const MarkSeenUnseen = ({ updateQuery, alerts, currentUserId }: Props) => {
  const { t } = useTranslation();

  const [updateSeenBy] = useUpdateSeenByMutation({
    onCompleted: () => {
      // console.log('seen by updated');
      // TODO: add alert here
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const markAllAsSeen = async () => {
    if (alerts.length > 0) {
      alerts.forEach((alert: MetaEvent) => {
        const seenByArray = alert?.seen_by ?? [];

        if (!alert?.seen_by?.includes(currentUserId)) {
          updateSeenBy({
            variables: {
              input: {
                SK: alert.SK,
                seen_by: [...seenByArray, currentUserId],
              },
            },
          });
        }
      });
      updateQuery((prev: ListTenantMetaEventsQuery) => {
        const items = prev?.listTenantMetaEvents?.items?.map((item: MetaEvent) => {
          if (item.seen_by?.includes(currentUserId)) {
            return item;
          }
          return {
            ...item,
            seen_by: [...(item?.seen_by || []), currentUserId],
          };
        });
        const next = {
          ...prev,
          listTenantMetaEvents: {
            ...prev.listTenantMetaEvents,
            items,
          },
        };
        return next;
      });
    }
  };

  return (
    <Text
      fontSize="xl"
      color={'orange.500'}
      bold
      onPress={markAllAsSeen}
    >
      {t('alertMarkAllSeen')}
    </Text>
  );
};
