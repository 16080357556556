import Constants from 'expo-constants';
import { useState, useEffect } from 'react';
import { Platform, Vibration } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';

import initFirebase from '../firebase';
import {
  useUpdateUserPushTokenMutation,
  useSubscribeToFcmTopicMutation,
} from '../graphql/components';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

type EventName = 'devicealert' | 'systemalert' | 'blob' | 'bounceralert';
interface NotificationResponsePayload {
  id: string;
  confidence: number | null;
  eventId: string;
  eventName: EventName;
  PK: string;
  SK: string;
  tenantId: string;
}

export function usePushNotifications() {
  const linkTo = useLinkTo();

  const [subscribeToFcmTopic] = useSubscribeToFcmTopicMutation({
    onError: (error) => {
      console.error(error);
    },
  });

  async function subToFCM() {
    const token = await initFirebase();

    console.log(token);
    if (token) {
      subscribeToFcmTopic({ variables: { token } });
    }
  }

  const linkToAlertDetails = (id: string) => {
    linkTo(`/alerts/${id}`);
  };

  const [expoPushToken, setExpoPushToken] = useState<string | undefined>('');
  const [notification, setNotification] = useState<boolean>(false);

  const [updateUserToken] = useUpdateUserPushTokenMutation({
    onCompleted: (data) => {
      console.log('Completed push token update: ', data?.updateUserPushToken);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (Platform.OS === 'web') {
      subToFCM();
    } else if (Constants.isDevice) {
      registerForPushNotificationsAsync().then((token) => {
        if (token) {
          setExpoPushToken(token);
          console.log(expoPushToken);
          updateUserToken({
            variables: {
              token,
            },
          });
        }
      });

      const notificationListener =
        Notifications.addNotificationReceivedListener((notif) => {
          Vibration.vibrate(1000);
          setNotification(!!notif);
          console.log(notification);
        });

      const responseListener =
        Notifications.addNotificationResponseReceivedListener((response) => {
          if (response) {
            console.log(response);

            const responseData = response?.notification?.request?.content?.data
              ?.payload as NotificationResponsePayload;

            if (responseData.eventName === 'bounceralert') {
              linkToAlertDetails(responseData.eventId);
            }
          }
        });

      return () => {
        Notifications.removeNotificationSubscription(notificationListener);
        Notifications.removeNotificationSubscription(responseListener);
      };
    }
  }, []);
}

async function registerForPushNotificationsAsync() {
  if (Platform.OS === 'web') {
    return;
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== 'granted') {
    console.log('Failed to get push token for push notification!');
    return;
  }
  const token = (await Notifications.getExpoPushTokenAsync()).data;
  console.log(token);

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
}
