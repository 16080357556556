import Amplify, { Analytics } from 'aws-amplify';
// import Amplify from 'aws-amplify';
import { Platform } from 'react-native';
import 'expo-asset';

import config from './config';

function initAmplify() {
  Amplify.configure({
    Auth: config.Auth,
    oauth: config.oauth,
  });
}

function initAnalytics() {
  Analytics.configure({
    AWSPinpoint: config.AWSPinpoint,
  });
}

async function initNotifications() {
  if (Platform.OS === 'web') {
    return;
  }
  const Notifications = await import('expo-notifications');

  Notifications.setNotificationCategoryAsync('myCategoryName', [
    {
      identifier: 'vanillaButton',
      buttonTitle: 'Plain Option',
      options: {
        isDestructive: false,
        isAuthenticationRequired: false,
      },
    },
    {
      identifier: 'highlightedButton',
      buttonTitle: 'Destructive!!!',
      options: {
        isDestructive: true,
        isAuthenticationRequired: false,
      },
    },
    {
      identifier: 'requiredAuthenticationButton',
      buttonTitle: 'Click to Authenticate',
      options: {
        isDestructive: false,
        isAuthenticationRequired: true,
      },
    },
    {
      identifier: 'textResponseButton',
      buttonTitle: 'Click to Respond with Text',
      textInput: { submitButtonTitle: 'Send', placeholder: 'Type Something' },
      options: {
        isDestructive: false,
        isAuthenticationRequired: false,
      },
    },
  ]);
}

export default async function () {
  initAmplify();
  initAnalytics();
  await initNotifications();
}
