import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import { Button } from 'native-base';
import { LanguageSelector } from '@/components/shared/LanguageSelector';

import { AuthForm } from '@/components/Forms/AuthForm';
import { FormInput } from '@/components/Forms/form-input.component';
import { SignInSchema } from '@/components/Forms/validationSchemas';
import { submitOnEnter } from '@/utils/form';
import { useTranslation } from 'react-i18next';

interface Props {
  linkToCreateTenant: () => void;
  linkToForgotPassword: (email: string) => void;
  linkToVerifyEmail: (email: string) => void;
  showCreateTenantLink: boolean;
}

export function SignIn({
  linkToForgotPassword,
  linkToCreateTenant,
  linkToVerifyEmail,
  showCreateTenantLink,
}: Props) {
  const { t } = useTranslation();
  const [formError, setFormError] = useState('');

  function handleError(e: any, email: string) {
    switch (e.code) {
      case 'NotAuthorizedException':
        setFormError(t('errorNotAuth'));
        break;
      case 'UserNotConfirmedException':
        linkToVerifyEmail(email);
        setFormError(t('errorNotVerified'));
        break;
      default:
        setFormError(t('errorDefault'));
    }
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={SignInSchema}
      onSubmit={async (values, actions) => {
        const email = values.email.toLowerCase()
        try {
          await Auth.signIn({
            username: email,
            password: values.password,
          });
        } catch (e) {
          handleError(e, email);
          actions.setSubmitting(false);
        }
      }}
    >
      {(props) => (
        <AuthForm error={formError}>
          <FormInput
            id="email"
            placeholder={t('email')}
            keyboardType="email-address"
            autoComplete="username"
            autoCompleteType="email"
            autoCapitalize="none"
            onKeyPress={submitOnEnter(props.submitForm)}
          />
          <FormInput
            type="password"
            id="password"
            placeholder={t('password')}
            secureTextEntry
            autoComplete="password"
            autoCompleteType="password"
            autoCapitalize="none"
            textContentType="password"
            onKeyPress={submitOnEnter(props.submitForm)}
          />
          <Button
            disabled={props.isSubmitting}
            isLoading={props.isSubmitting}
            onPress={() => {
              props.submitForm();
            }}
            colorScheme="orange"
            _text={{
              color: 'white.50',
            }}
            testID="Sign In"
          >
            {t('signIn')}
          </Button>
          <Button
            disabled={props.isSubmitting}
            variant="link"
            onPress={() => {
              props.resetForm();
              linkToForgotPassword(encodeURIComponent(props?.values?.email?.toLowerCase()));
            }}
            _text={{
              color: 'orange.500',
            }}
            testID="Forgot Password"
          >
            {t('forgotPassword')}
          </Button>

          {showCreateTenantLink && (
            <Button
              disabled={props.isSubmitting}
              variant="link"
              onPress={() => {
                props.resetForm();
                linkToCreateTenant();
              }}
              _text={{
                color: 'orange.500',
              }}
              testID="Regsiter Organization"
            >
              {t('registerOrganization')}
            </Button>
          )}
          {/* <Button */}
          {/*   disabled={props.isSubmitting} */}
          {/*   variant="link" */}
          {/*   onPress={() => { */}
          {/*     Linking.openURL( */}
          {/*       'https://cyberthreatsensor.io/free-subscription/' */}
          {/*     ); */}
          {/*   }} */}
          {/*   _text={{ */}
          {/*     color: 'orange.500', */}
          {/*   }} */}
          {/*   testID="link to microsite from sign in" */}
          {/* > */}
          {/*   {t('createAccount')} */}
          {/* </Button> */}

          <LanguageSelector />
        </AuthForm>
      )}
    </Formik>
  );
}
