import React from 'react';
import {
  Badge,
  Box,
  HStack,
  Pressable,
  Text,
  useColorMode,
  useColorModeValue,
  useTheme,
  VStack,
} from 'native-base';
import {
  useRoute,
  getFocusedRouteNameFromRoute,
  useLinkTo,
} from '@react-navigation/native';
import { Auth } from 'aws-amplify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-native';

import * as Icons from '@/components/CustomIcons';

interface Props {
  unseen: number;
  username: string;
}

export function LeftTab({ unseen, username }: Props) {
  const { t } = useTranslation();
  const linkTo = useLinkTo();
  const route = useRoute();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();
  const routeName = getFocusedRouteNameFromRoute(route);

  const bgColor = useColorModeValue('white.100', 'black.800');
  const navbarBGColor = useColorModeValue('white.300', 'black.600');

  const selectedBorderColor = useColorModeValue('white.300', 'black.600');
  const unselectedBorderColor = useColorModeValue('white.100', 'black.800');

  const badgeColor = useColorModeValue('orange.400', 'orange.600');

  const [selected, setSelected] = React.useState(0);
  const [numberOfUnseen, setNumberOfUnseen] = React.useState(0);
  const [isCollapsed, setIsCollapsed] = React.useState<boolean | null>(null);

  const isDark = colorMode === 'dark' ? true : false;
  const logo = isDark ? require('@/assets/cts-logo-dark.png') : require('@/assets/cyber-logo.png')

  React.useEffect(() => {
    setCurrentScreen();
    setNumberOfUnseen(unseen);
  }, [routeName, unseen]);

  React.useEffect(() => {
    setInitialCollapsedValue();
  }, []);

  const setCurrentScreen = () => {
    switch (routeName) {
      case 'Device':
        return setSelected(1);
      case 'Alert':
        return setSelected(2);
      case 'Settings':
        return setSelected(3);
      default:
        return setSelected(0);
    }
  };

  const setCollapsedValues = async () => {
    try {
      isCollapsed !== null && setIsCollapsed(!isCollapsed);
      const jsonValue = JSON.stringify(!isCollapsed);

      await AsyncStorage.setItem('@storage_Key', jsonValue);
    } catch (e) {
      // saving error
      console.log(e);
    }
  };

  const setInitialCollapsedValue = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('@storage_Key');
      const parsedValue: boolean =
        jsonValue !== null ? JSON.parse(jsonValue) : false;

      return setIsCollapsed(parsedValue);
    } catch (e) {
      // error reading value
      console.log(e);
    }
  };

  const iconColor = isDark ? colors.white['50'] : colors.black['900'];
  const barHeight = '100vh';
  const iconSize = 7;

  const NavbarLink = ({
    children,
    link,
    number,
  }: {
    children?: React.ReactNode | false;
    link: string;
    number: number;
  }) => {
    const BG = selected === number ? navbarBGColor : 'transparent';

    return (
      <Pressable
        justifyContent="center"
        p={1}
        onPress={() => {
          linkTo(link);
          setSelected(number);
        }}
        // TODO: implement "full" everywhere needed
        width="full"
        background={BG}
        borderRadius={10}
      >
        {children}
      </Pressable>
    );
  };

  // TODO: animations

  return (
    <VStack
      alignSelf="center"
      backgroundColor={bgColor}
      height={barHeight}
      justifyContent="space-between"
      left={0}
      position="sticky"
      px={2}
      py={20}
      top={0}
    >
      <VStack alignItems="center" justifyContent="center" space={2}>
        {isCollapsed ? (
          <Box height="55px" width="35px">
            <Image
              onError={({ nativeEvent: { error } }) => console.log(error)}
              style={{ width: '100%', height: '100%' }}
              resizeMethod="scale"
              resizeMode="contain"
              source={logo}
            />
          </Box>
        ) : (
          <Box height="55px" width="155px">
            <Image
              onError={({ nativeEvent: { error } }) => console.log(error)}
              style={{ width: '100%', height: '100%' }}
              resizeMethod="scale"
              resizeMode="contain"
              source={logo}
            />
          </Box>
        )}
        <Pressable
          justifyContent="center"
          p={1}
          onPress={setCollapsedValues}
          width="full"
          borderRadius={10}
        >
          <HStack space={2}>
            {isCollapsed ? (
              <Icons.CircleChevronRightIcon
                iconSize={iconSize}
                color={iconColor}
              />
            ) : (
              <Icons.CircleChevronLeftIcon
                iconSize={iconSize}
                color={iconColor}
              />
            )}
            {!isCollapsed && (
              <Text fontSize="lg" bold>
                {t('navCollapse')}
              </Text>
            )}
          </HStack>
        </Pressable>
      </VStack>

      <VStack alignItems="center" justifyContent="center" space={2}>
        <NavbarLink link="/dashboard" number={0}>
          <HStack space={2}>
            <Icons.DashboardIcon iconSize={iconSize} color={iconColor} />
            {!isCollapsed && (
              <Text fontSize="lg" bold>
                {t('navDashboard')}
              </Text>
            )}
          </HStack>
        </NavbarLink>

        <NavbarLink link="/devices" number={1}>
          <HStack space={2}>
            <Icons.DevicesIcon iconSize={iconSize} color={iconColor} />
            {!isCollapsed && (
              <Text fontSize="lg" bold>
                {t('devices')}
              </Text>
            )}
          </HStack>
        </NavbarLink>

        {isCollapsed ? (
          <NavbarLink link="/alerts" number={2}>
            <VStack justifyContent="space-between" alignItems="center">
              <HStack space={2}>
                <Icons.NotificationsEmptyIcon
                  iconSize={iconSize}
                  color={iconColor}
                />
                {!isCollapsed && (
                  <Text fontSize="lg" bold>
                    {t('alerts')}
                  </Text>
                )}
              </HStack>
              {unseen > 0 && (
                <Box
                  bg="orange.500"
                  rounded="full"
                  h={3}
                  w={3}
                  mb={-3}
                  position="relative"
                  bottom={3}
                  left={2}
                  borderColor={
                    selected === 2 ? selectedBorderColor : unselectedBorderColor
                  }
                  borderWidth={2}
                />
              )}
            </VStack>
          </NavbarLink>
        ) : (
          <NavbarLink link="/alerts" number={2}>
            <HStack justifyContent="space-between" alignItems="center">
              <HStack space={2}>
                <Icons.NotificationsEmptyIcon
                  iconSize={iconSize}
                  color={iconColor}
                />
                {!isCollapsed && (
                  <Text fontSize="lg" bold>
                    {t('alerts')}
                  </Text>
                )}
              </HStack>

              {unseen > 0 && (
                <Badge
                  bg={badgeColor}
                  rounded="full"
                  variant="solid"
                  alignSelf="flex-end"
                  _text={{
                    fontSize: 13,
                  }}
                >
                  <Text>{numberOfUnseen <= 99 ? numberOfUnseen : '99+'}</Text>
                </Badge>
              )}
            </HStack>
          </NavbarLink>
        )}

        <NavbarLink link="/settings" number={3}>
          <HStack space={2}>
            <Icons.SettingsIcon iconSize={iconSize} color={iconColor} />
            {!isCollapsed && (
              <Text fontSize="lg" bold>
                {t('settings')}
              </Text>
            )}
          </HStack>
        </NavbarLink>
      </VStack>

      <VStack alignItems="center" justifyContent="center" space={2}>
        <Pressable
          justifyContent="center"
          p={1}
          onPress={() => {
            linkTo('/settings/user');
          }}
          width="full"
        >
          <HStack space={2}>
            <Icons.UserIcon iconSize={iconSize} color={iconColor} />
            {!isCollapsed && username && (
              <Text fontSize="lg" bold>
                {username}
              </Text>
            )}
          </HStack>
        </Pressable>

        <Pressable
          justifyContent="center"
          p={1}
          onPress={() => Auth.signOut()}
          width="full"
          borderRadius={10}
        >
          <HStack space={2}>
            <Icons.SignOutIcon iconSize={iconSize} color={iconColor} />
            {!isCollapsed && (
              <Text fontSize="lg" bold>
                {t('signOut')}
              </Text>
            )}
          </HStack>
        </Pressable>
      </VStack>
    </VStack>
  );
}
