import { Platform } from 'react-native';
import { useDimensions } from '@react-native-community/hooks';
import { isDesktop } from 'react-device-detect';

export function useCTSDimensions() {
  const dimensions = useDimensions();

  const isWeb = Platform.OS === 'web' ? true : false;
  const isLandscape =
    dimensions.window.width > dimensions.window.height ? true : false;
  const deviceHeight = dimensions.window.height;
  const isDesktopWeb = isDesktop && isWeb;

  const desktopWidth = '8%';

  const paddingTopTall = isWeb
    ? isLandscape
      ? '13vh'
      : '5vh'
    : deviceHeight * 0.05;

  const paddingTopShort = isWeb
    ? isLandscape
      ? '10vh'
      : '2vh'
    : deviceHeight * 0.01;

  const paddingHSlim =
    isDesktop && isWeb ? desktopWidth : dimensions.window.width < 350 ? 6 : 8;

  const paddingHWide = isDesktop && isWeb ? desktopWidth : 4;

  const spacingBottom = isWeb ? (isDesktop ? 2 : 20) : deviceHeight * 0.2;

  const scrollViewHeight = Platform.OS !== 'web' ? deviceHeight : '10vh';

  return {
    isDesktopWeb,
    isLandscape,
    isWeb,
    paddingTopTall,
    paddingTopShort,
    paddingHSlim,
    paddingHWide,
    spacingBottom,
    scrollViewHeight,
  };
}
