import React from 'react';
import { Box } from 'native-base';
import { useThemeBackgroundColor } from '@/hooks/useThemeColor';

const Card = ({ children }: { children: React.ReactNode }) => {
  const bgCardColor = useThemeBackgroundColor('card');
  return (
    <Box bg={bgCardColor} borderRadius={10} p={5} shadow={2}>
      {children}
    </Box>
  );
};
export default Card;
