import React from 'react';
import { Platform, Linking } from 'react-native';
import { SafeAreaView } from 'react-native';
import {
  Box,
  Button,
  HStack,
  Modal as RNModal,
  Pressable,
  Text,
} from 'native-base';
import { useLinkTo } from '@react-navigation/native';

import { BackIcon, PlusIcon } from '../../components/CustomIcons';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';
import { useCTSDimensions } from '@/hooks/useCTSDimensions';
import { useIPhoneModel } from '@/hooks/useIPhoneModel';

interface Props {
  title?: string;
  leftControl?: string;
  rightControl?: string;
  info?: boolean;
}

export function TopBar(props: Props) {
  const linkTo = useLinkTo();
  const iPhoneModel = useIPhoneModel();
  const { paddingHSlim } = useCTSDimensions();

  const [showModal, setShowModal] = React.useState(false);
  const isIphone = Platform.OS === 'ios' ? true : false;

  const bgColor = useThemeBackgroundColor('screen');
  const primaryColor = useThemeFontColor('primary');
  const invertedFontColor = useThemeFontColor('inverted');
  const marginTop = isIphone && iPhoneModel && iPhoneModel >= 10 ? 0 : 2;

  const iconSize = isIphone && iPhoneModel && iPhoneModel >= 10 ? 5 : 4;

  const Modal = () => {
    return (
      <RNModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        backgroundColor="rgba(0, 0, 0, 0.40)"
      >
        <RNModal.Content maxWidth="400px" bg={bgColor}>
          <RNModal.CloseButton />
          <RNModal.Body p={5}>
            <Text bold mb={5}>
              Information
            </Text>
            Follow these links to read more about our Terms of Service and
            Privacy Policy:
            <Box paddingY={5}>
              <Text
                onPress={() =>
                  Linking.openURL('https://cyberthreatsensor.io/terms/')
                }
                color="orange.500"
              >
                Terms of Service
              </Text>
              <Text
                onPress={() =>
                  Linking.openURL('https://cyberthreatsensor.io/privacy/')
                }
                color="orange.500"
              >
                Privacy Policy
              </Text>
            </Box>
          </RNModal.Body>
          <RNModal.Footer bg={bgColor} justifyContent="center">
            <Button
              onPress={() => {
                setShowModal(false);
              }}
              colorScheme="orange"
            >
              close
            </Button>
          </RNModal.Footer>
        </RNModal.Content>
      </RNModal>
    );
  };

  const Info = () => (
    <Box
      alignItems="center"
      justifyContent="center"
      backgroundColor={primaryColor}
      w={6}
      h={6}
      borderRadius={20}
    >
      <Text italic color={invertedFontColor} pb={[0, 1]} bold>
        i
      </Text>
    </Box>
  );

  const LeftAction = () => (
    <Pressable
      alignItems="flex-start"
      onPress={() => {
        props?.leftControl && linkTo(props.leftControl);
      }}
      width={8}
    >
      {props.leftControl && (
        <BackIcon color={primaryColor} iconSize={iconSize} />
      )}
    </Pressable>
  );

  const RightAction = () => {
    return (
      <Pressable
        alignItems="flex-end"
        onPress={() => {
          props?.rightControl
            ? linkTo(props.rightControl)
            : props?.info
            ? setShowModal(true)
            : null;
        }}
        width={8}
      >
        {props.rightControl && !props.info && (
          <PlusIcon color={primaryColor} iconSize={iconSize} />
        )}
        {props.info === true && <Info />}
      </Pressable>
    );
  };

  return (
    <SafeAreaView
      style={{
        backgroundColor: bgColor,
      }}
    >
      <HStack
        alignItems="center"
        justifyContent="space-between"
        mt={[marginTop, 5]}
        mb={[2, 5]}
        px={paddingHSlim}
      >
        <LeftAction />
        {/* {props.title && props.title.toLowerCase() !== 'dashboard' && (
          <Text fontSize={['lg', 'xl']}>{props.title.toLowerCase()}</Text>
        )} */}
        <Modal />
        <Box w="30%" />
        <RightAction />
      </HStack>
    </SafeAreaView>
  );
}
