import React from "react"
import { MetaEvent } from "@/graphql/components"
import { Card } from "@/components/Alerts/Card"
import {
  Text,
  Button,
  HStack,
  Image,
} from 'native-base'
import { useTranslation } from "react-i18next"
import { copyToClipboard } from "@/utils/Alert/utils"
import { AlertContext } from "@/context/AlertContext"

interface Props {
  alert: MetaEvent
}
export const AlertTitle = ({ alert }: Props) => {
  const { t } = useTranslation()
  const CallAlert = React.useContext(AlertContext);
  return (
    <Card>
      <Text bold fontSize="xl">
        {alert?.title}
      </Text>
      {alert?.event_id && (
        <>
          <HStack
            space={3}
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Text bold maxW="84%">
              {t('eventId')}
            </Text>
            <Text maxW="84%">{alert?.event_id}</Text>
            <Button
              backgroundColor={
                "orange.500"
              }
              borderWidth="1px"
              onPress={() =>
                alert?.event_id ? copyToClipboard(alert?.event_id, CallAlert, t) : null
              }
              padding={1}
            >
              <Image
                size="1rem"
                resizeMode="center"
                source={require('@/assets/clipboard.png')}
                alt="clipboard"
                height={5}
                width={5}
              />
            </Button>
          </HStack>
        </>
      )}
    </Card>
  )
}
