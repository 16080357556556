import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl } from 'react-native';
import { Box, Skeleton, ScrollView, HStack, Text, VStack } from 'native-base';
import { NetworkStatus } from '@apollo/client';
import { useLinkTo } from '@react-navigation/native';

import { DeviceListContainer } from '@/containers/Device/DeviceListContainer';
import { Error } from '@/components/Error';
import { DeviceListFilterModal } from '@/components/Device/DeviceListFilterModal';
import { registerPageView } from '@/analytics/navigation';
import { useListTenantDevicesQuery } from '@/graphql/components';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';

export function DeviceListScreen() {
  useEffect(() => {
    registerPageView('deviceList');
  }, []);

  const [statusOrder, setStatusOrder] = React.useState<string | null>(null);
  const { t } = useTranslation();

  const linkTo = useLinkTo();
  const primaryColor = useThemeFontColor('primary');
  const cardBG = useThemeBackgroundColor('card');

  const { data, error, fetchMore, refetch, networkStatus, loading } =
    useListTenantDevicesQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });

  const fetchMoreDevices = () => {
    const nextToken = data?.listTenantDevices?.nextToken;

    if (!nextToken) {
      return;
    }

    fetchMore({
      variables: {
        nextToken,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const prevItems = prev?.listTenantDevices?.items ?? [];
        const nextItems = fetchMoreResult?.listTenantDevices?.items ?? [];
        const nextToken = fetchMoreResult?.listTenantDevices?.nextToken ?? null;
        const items = prevItems.concat(nextItems);

        const next = {
          ...prev,
          listTenantDevices: {
            ...prev.listTenantDevices,
            items,
            nextToken,
          },
        };
        return next;
      },
    });
  };

  const handleSubmitFilters = (
    marketplace: string,
    statusFilter: string,
    statusOrder: string
  ) => {
    console.log('marketplace:', marketplace);
    console.log('status filter:', statusFilter);
    setStatusOrder(statusOrder);
  };

  const linkToAddDevice = () => {
    linkTo('/devices/enroll?screen=DeviceEnroll');
  };

  const devicesLoading =
    loading || data === undefined || networkStatus === NetworkStatus.loading;

  if (error) {
    return <Error text={t('errorDevices')} retry={() => refetch()} />;
  }

  if (data?.listTenantDevices?.items?.length === 0)
    return (
      <Box pt={2} px={4}>
        <Text fontSize={'2xl'} testID="no-devices">
          {t('deviceNone')}
        </Text>
        <Text onPress={linkToAddDevice} fontSize={'2xl'} color="orange.500">
          {t('deviceAdd')}
        </Text>
      </Box>
    );

  const Layout = () => (
    <VStack alignItems="center" w="100%" testID="layout-container">
      {devicesLoading ? (
        <VStack space={5} w="100%" maxW="1000px" testID="layout-container">
          <HStack justifyContent="space-between" width="100%" my={7}>
            <Skeleton h="6" w="5%" />
            <Skeleton h="6" w="5%" />
          </HStack>
        </VStack>
      ) : (
        <>
          {/* <Header devices={data?.listTenantDevices?.items ?? []} /> */}
          <HStack
            justifyContent="space-between"
            px={5}
            maxW="1000px"
            width="100%"
            mb={2}
            space={2}
          >
            <DeviceListFilterModal
              handleSubmitFilters={handleSubmitFilters}
              statusOrder={statusOrder}
            />

            <Text
              fontSize="xl"
              color={'orange.500'}
              bold
              onPress={linkToAddDevice}
            >
              {t('deviceAdd')}
            </Text>
          </HStack>
        </>
      )}
      {devicesLoading ? (
        <>
          {Array.from(Array(8)).map((_, index) => (
            <VStack
              borderColor={cardBG}
              borderRadius={10}
              borderWidth={1}
              justifyContent="space-between"
              key={index}
              marginBottom={2}
              p={5}
              space={2}
              w="100%"
              maxW="1000px"
            >
              <Skeleton h="5" w="50%" />
              <Skeleton h="4" w="20%" />
            </VStack>
          ))}
        </>
      ) : (
        <>
          <DeviceListContainer
            devices={data?.listTenantDevices?.items ?? []}
            fetchMore={fetchMoreDevices}
            fetchingMore={networkStatus === NetworkStatus.fetchMore}
            hasMore={!!data?.listTenantDevices?.nextToken}
            statusOrder={statusOrder}
          />
        </>
      )}
    </VStack>
  );

  return (
    <ScrollView
      testID={'device-card-scrollview'}
      flexGrow={1}
      refreshControl={
        <RefreshControl
          onRefresh={refetch}
          refreshing={loading}
          tintColor={primaryColor}
        />
      }
    >
      <Layout />
    </ScrollView>
  );
}
