import React from 'react';
import { Center, Spinner, ScrollView } from 'native-base';

import { useGetCurrentUserQuery } from '@/graphql/components';

import { AppSettingsContainer } from '@/containers/Settings/AppSettingsContainer';

export function AppSettingsScreen(): React.ReactElement {

  const { data, loading } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  });

  const currentUser = data?.getCurrentUser;

  if (loading) {
    return (
      <Center flex={1}>
        <Spinner color="orange.500" size="lg" />
      </Center>
    );
  }

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
        {currentUser && <AppSettingsContainer currentUser={currentUser} />}
    </ScrollView>
  );
}
