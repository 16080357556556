import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ListTenantMetaEventsQuery,
  MetaEventDetailsFragment,
} from '@/graphql/components';
import { Text } from 'native-base';
import * as Clipboard from 'expo-clipboard';

interface ParseProps {
  string: string;
}

export function ParseHTML({ string }: ParseProps) {
  const localString = string ?? '';

  const split = localString.replace(/<\/b>/g, '').split('<');
  return (
    <>
      {split.map((item, index) => {
        return item === 'br>' ? (
          <Text key={index} h={5} />
        ) : item.match('b>') ? (
          <Text key={index} bold>
            {item.replace(/\/b>/g, '').replace(/b>/g, '')}
          </Text>
        ) : (
          <Text key={index}>{item.replace(/br>/g, '')}</Text>
        );
      })}
    </>
  );
}

export interface UpdateAlertProps {
  alert: MetaEventDetailsFragment;
  seen: boolean;
  currentUserId: string;
  updateQuery: (prev: object) => object;
}
export const storedAlert = {
  get: async () => {
    try {
      return await AsyncStorage.getItem('storedAlert');
    } catch (e) {
      console.log(e);
      return '';
    }
  },
  set: async (value: string) => {
    try {
      await AsyncStorage.setItem('storedAlert', value);
    } catch (e) {
      console.log(e);
    }
  },
};

export const updateAlertList = (
  alert: MetaEventDetailsFragment,
  seen: boolean,
  currentUserId: string,
  updateQuery: (prev: object) => object
) => {
  updateQuery((prev: ListTenantMetaEventsQuery) => {
    const items = prev?.listTenantMetaEvents?.items?.map(
      (item: MetaEventDetailsFragment) => {
        if (item.SK !== alert.SK) {
          return item;
        }
        if (seen) {
          return {
            ...item,
            seen_by: [...(item?.seen_by || []), currentUserId],
          };
        }
        return {
          ...item,
          seen_by: item?.seen_by?.filter((id) => {
            return id !== currentUserId;
          }),
        };
      }
    );
    const next = {
      ...prev,
      listTenantMetaEvents: {
        ...prev.listTenantMetaEvents,
        items,
      },
    };
    return next;
  });
};

export const copyToClipboard = (string: string, CallAlert: any, t: any) => {
  try {
    Clipboard.setStringAsync(string);
    CallAlert && CallAlert(t('deviceEnrollClipboard'), 'success');
  } catch (error) {
    console.log(error);
    CallAlert && CallAlert(t('deviceError'), 'error');
  }
};
