import * as React from 'react';
import { Text, VStack } from 'native-base';

interface Props {
  error?: string;
  success?: string;
}

export default function UserForm({
  children,
  error,
  success,
}: React.PropsWithChildren<Props>) {
  return (
    <VStack space={3} width="100%">
      {success ? <Text color="green.500">{success}</Text> : null}
      {error ? (
        <Text testID="feedback-form-error" color="red.500">
          {error}
        </Text>
      ) : null}
      {children}
    </VStack>
  );
}
