import React from 'react';
import { Button, HStack, Pressable, Text, VStack } from 'native-base';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { CurrentUserFragment } from '@/graphql/components';

import { AlertContext } from '@/context/AlertContext';
import { ChangePasswordSchema } from '@/components/Settings/validationSchemas';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  NameIcon,
} from '@/components/CustomIcons';
import { DeleteAccount } from '@/components/Settings/DeleteAccount';
import { FormInput } from '@/components/Forms/form-input.component';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';
import UserForm from '@/components/Settings/UserForm';

interface Props {
  user?: CurrentUserFragment;
}

export function UserSettingsContainer({ user }: Props) {
  const { t } = useTranslation();
  const bgCardColor = useThemeBackgroundColor('card');
  const primaryColor = useThemeFontColor('primary');

  const CallAlert = React.useContext(AlertContext);

  const [selectedCard, setSelectedCard] = React.useState(0);
  const [passwordError, setPasswordError] = React.useState('');

  function handleSuccess() {
    CallAlert && CallAlert(t('passwordChangeSuccess'), 'success');
  }

  type SettingsProps = {
    title: string;
    cardNumber: number;
    icon?: React.ReactElement;
  };

  const SettingsCard = ({
    title,
    cardNumber,
    children,
    icon,
  }: React.PropsWithChildren<SettingsProps>) => {
    return (
      <VStack p={5} bg={bgCardColor} borderRadius="10px" space={5}>
        <Pressable
          onPress={() =>
            setSelectedCard(selectedCard === cardNumber ? 0 : cardNumber)
          }
        >
          <HStack justifyContent="space-between">
            <HStack space={5}>
              {icon && icon}
              <Text fontSize="2xl">{title}</Text>
            </HStack>

            {selectedCard === cardNumber ? (
              <ChevronDownIcon iconSize={5} color={primaryColor} />
            ) : (
              <ChevronRightIcon iconSize={5} color={primaryColor} />
            )}
          </HStack>
        </Pressable>
        {selectedCard === cardNumber && children}
      </VStack>
    );
  };

  return (
    <VStack
      space={3}
      width="100%"
      maxW="550px"
      height="100%"
      justifyContent="center"
      mx="auto"
    >
      {user && (
        <Text fontSize="4xl" bold mb={5}>
          {user?.firstName} {user?.lastName}
        </Text>
      )}
      <>
        {/* <SettingsCard title="change name" cardNumber={1}>
          <Formik
            initialValues={{
              changeName: '',
            }}
            // validationSchema={ChangePasswordSchema}
            validateOnBlur
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {(props) => (
              <FormControl isRequired>
                <VStack space={3}>
                  <FormInput
                    id="changeName"
                    label="name"
                    placeholder={`${user?.firstName} ${user?.lastName}`}
                  />
                  <HStack justifyContent="space-between">
                    <Button
                      _text={{
                        fontSize: 'lg',
                      }}
                      colorScheme="danger"
                      onPress={() => setSelectedCard(0)}
                      width="48%"
                    >
                      cancel
                    </Button>
                    <Button
                      _text={{
                        fontSize: 'lg',
                      }}
                      colorScheme="orange"
                      width="48%"
                      onPress={() => console.log(props.values.changeName)}
                      isDisabled={props.values.changeName === '' ? true : false}
                    >
                      confirm
                    </Button>
                  </HStack>
                </VStack>
              </FormControl>
            )}
          </Formik>
        </SettingsCard> */}
      </>

      <SettingsCard
        title={t('passwordChange')}
        cardNumber={2}
        icon={<NameIcon iconSize={8} color={primaryColor} />}
      >
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          }}
          validationSchema={ChangePasswordSchema}
          validateOnBlur
          onSubmit={async (values) => {
            setPasswordError('');
            Auth.currentAuthenticatedUser()
              .then((user) => {
                return Auth.changePassword(
                  user,
                  values.currentPassword,
                  values.newPassword
                );
              })
              .then((data) => {
                handleSuccess();
                console.log(data);
              })
              .catch((err) => {
                setPasswordError(err.message);
                console.log(err);
              });
          }}
        >
          {(props) => (
            <UserForm error={passwordError}>
              <VStack space={3}>
                <FormInput
                  id="currentPassword"
                  label="current password"
                  placeholder={t('passwordCurrent')}
                  type="password"
                  secureTextEntry
                />
                <FormInput
                  id="newPassword"
                  label="new password"
                  placeholder={t('passwordNew')}
                  type="password"
                  secureTextEntry
                  autoComplete="password-new"
                  autoCompleteType="password"
                  autoCapitalize="none"
                  textContentType="newPassword"
                />
                <FormInput
                  id="confirmNewPassword"
                  label="confirm new password"
                  placeholder={t('passwordConfirmNew')}
                  type="password"
                  secureTextEntry
                  autoComplete="password-new"
                  autoCompleteType="password"
                  autoCapitalize="none"
                  textContentType="newPassword"
                />
                <HStack justifyContent="space-between">
                  <Button
                    _text={{
                      fontSize: 'lg',
                    }}
                    colorScheme="danger"
                    onPress={() => setSelectedCard(0)}
                    width="48%"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    _text={{
                      fontSize: 'lg',
                    }}
                    colorScheme="orange"
                    width="48%"
                    onPress={props.submitForm}
                    // TODO: check for validation before enabling button
                    isDisabled={props.isSubmitting}
                    isLoading={props.isSubmitting}
                  >
                    {props.isSubmitting ? t('submitting') : t('confirm')}
                  </Button>
                </HStack>
              </VStack>
            </UserForm>
          )}
        </Formik>
      </SettingsCard>
      <DeleteAccount currentUser={user} />
    </VStack>
  );
}
