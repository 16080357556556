import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  HStack,
  Pressable,
  Skeleton,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from 'native-base';
import { useLinkTo } from '@react-navigation/native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { getLocalizedTime } from '@/utils/timezoneConvert';

import {
  MetaEventDetailsFragment,
  useUpdateSeenByMutation,
} from '@/graphql/components';

import {
  ChevronRightIcon,
  SeenIcon,
  UnseenIcon,
} from '@/components/CustomIcons';
import {
  useThemeFontColor,
  useThemeBackgroundColor,
} from '@/hooks/useThemeColor';
import { useCTSDimensions } from '@/hooks/useCTSDimensions';
import { storedAlert, updateAlertList } from '@/utils/Alert/utils';

interface Props {
  updateQuery: any;
  currentUserId: string;
  alert: MetaEventDetailsFragment;
  selectedAlert: string;
  showEye?: boolean;
  setSelectedAlert?: React.Dispatch<React.SetStateAction<string>>;
}

export function AlertCard({
  selectedAlert,
  updateQuery,
  currentUserId,
  alert,
  setSelectedAlert,
  showEye = true,
}: Props) {
  const linkTo = useLinkTo();
  const { t } = useTranslation();
  const { isDesktopWeb } = useCTSDimensions();
  const seen = alert?.seen_by?.includes(currentUserId);

  const [updateSeenBy] = useUpdateSeenByMutation({
    onCompleted: () => {
      // console.log('seen by updated');
      // TODO: add alert here
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const linkToAlert = (id: string) => {
    linkTo(`/alerts/${id}`);
  };
  const primary = useThemeFontColor('primary');
  const secondary = useThemeFontColor('secondary2');
  const cardColor = useThemeBackgroundColor('card');

  const selectedCardColor = useColorModeValue('white.200', 'black.600');
  const cardBG =
    alert.event_id === selectedAlert ? selectedCardColor : cardColor;

  const timestamp = alert?.timestamp

  const markAsSeen = () => {
    const seenBy = alert?.seen_by ?? [];
    const seenByCast = seenBy as string[];

    const seenByUpdated: string[] = seenByCast.includes(currentUserId)
      ? seenByCast
      : [...seenByCast, currentUserId];

    updateSeenBy({
      variables: {
        input: {
          SK: alert.SK,
          seen_by: seenByUpdated,
        },
      },
    });
    updateAlertList(alert, true, currentUserId, updateQuery);
  };

  const markAsNotSeen = () => {
    const seenBy = alert ? alert.seen_by : [];
    const seenByCast = seenBy as string[];

    const seenByUpdated: string[] = seenByCast.filter((id) => {
      return id !== currentUserId;
    });

    updateSeenBy({
      variables: {
        input: {
          SK: alert.SK,
          seen_by: seenByUpdated,
        },
      },
    });
    updateAlertList(alert, false, currentUserId, updateQuery);
  };

  const Status = () => (
    <HStack height="100%" alignItems="center">
      <Box
        width={1.5}
        height="95%"
        backgroundColor="orange.500"
        borderRadius="xl"
      />
    </HStack>
  );

  const RightAction = () => (
    <Pressable
      onPress={() => {
        seen ? markAsNotSeen() : markAsSeen();
      }}
      width={20}
      height="100%"
      p={2}
    >
      <VStack alignItems="center" justifyContent="center" flex={1}>
        {seen ? (
          <UnseenIcon color={primary} iconSize={5} />
        ) : (
          <SeenIcon color={primary} iconSize={5} />
        )}
        <Text textAlign="center">
          {seen ? t('alertMarkAsNotSeen') : t('alertMarkAsSeen')}
        </Text>
      </VStack>
    </Pressable>
  );

  const Card = () => (
    <Pressable
      onPress={() => {
        if (alert.event_id) {
          storedAlert.set(alert?.event_id);
          linkToAlert(alert.event_id);
          setSelectedAlert && setSelectedAlert(alert.event_id)
        }
        !seen && markAsSeen();
      }}
      testID="alert-card"
    >
      <HStack
        justifyContent="space-between"
        px={5}
        py={3}
        borderRadius={10}
        bg={cardBG}
      >
        <HStack space={2} width={isDesktopWeb ? '80%' : '90%'}>
          {alert ? seen === false && <Status /> : <Skeleton h="100%" w="2" />}

          <VStack w="100%">
            {alert ? (
              <Text
                bold={seen ? false : true}
                fontSize="lg"
                color={!seen ? primary : secondary}
              >
                {timestamp ? getLocalizedTime(timestamp) : null}
              </Text>
            ) : (
              <Skeleton h="4" w="95%" />
            )}

            {alert ? (
              alert.title &&
              alert.title !== '-' && (
                <>
                  <Text
                    fontSize="md"
                    flexWrap="wrap"
                    flex={1}
                    color={!seen ? primary : secondary}
                  >
                    {alert.title}
                  </Text>
                  <Text
                    fontSize="sm"
                    flex={1}
                    color={'orange.500'}
                    isTruncated
                    w="50%"
                  >
                    {alert.device_alias || alert.device_id}
                  </Text>
                </>
              )
            ) : (
              <Skeleton h="3" w="70%" marginTop="2" />
            )}
          </VStack>
        </HStack>

        <HStack space="10px" alignItems="center">
          {isDesktopWeb &&
            showEye &&
            (seen ? (
              <Tooltip label={t('alertClickNotSeen')} openDelay={500}>
                <Pressable onPress={markAsNotSeen}>
                  <UnseenIcon
                    color={!seen ? primary : secondary}
                    iconSize={5}
                  />
                </Pressable>
              </Tooltip>
            ) : (
              <Tooltip label={t('alertClickSeen')} openDelay={500}>
                <Pressable onPress={markAsSeen}>
                  <SeenIcon color={!seen ? primary : secondary} iconSize={5} />
                </Pressable>
              </Tooltip>
            ))}
          <ChevronRightIcon color={!seen ? primary : secondary} iconSize={5} />
        </HStack>
      </HStack>
    </Pressable>
  );

  if (isDesktopWeb) return <Card />;

  return (
    <Box backgroundColor="orange.500" borderRadius={10} overflow="hidden">
      <Swipeable renderRightActions={() => <RightAction />}>
        <Card />
      </Swipeable>
    </Box>
  );
}
