import React, { useEffect } from 'react';
import { ScrollView, Center } from 'native-base';
import { AlertListContainer } from '@/containers/Alerts/AlertListContainer';
import { registerPageView } from '@/analytics/navigation';
import { RefreshControl } from 'react-native';

export function AlertListScreen() {
  const refetchFunctions: (() => Promise<any>)[] = [];
  useEffect(() => {
    registerPageView('alertList');
  }, []);

  const refetch = () => {
    refetchFunctions.forEach((func) => {
      func()
    })
  }


  return (
    <>
      <ScrollView
        testID="alert-card-list"
        flexGrow={1}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={refetch}
          />
        }

      >
        <Center>
          <AlertListContainer refetchFunctions={refetchFunctions} />
        </Center>
      </ScrollView >
    </>
  )
}
