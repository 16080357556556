import React, { useEffect } from 'react';
import { Platform, Text } from 'react-native';
import { Box, KeyboardAvoidingView, ScrollView } from 'native-base';

import { useGetCurrentUserQuery } from '@/graphql/components';
import { registerPageView } from '@/analytics/navigation';

import { CustomBox } from '@/components/CustomBox';
import { UserSettingsContainer } from '@/containers/Settings/UserSettingsContainer';

import { useCTSDimensions } from '@/hooks/useCTSDimensions';

export function UserSettingsScreen(): React.ReactElement {
  useEffect(() => {
    registerPageView('userSettingsNotificaitons');
  }, []);

  const { paddingHWide, spacingBottom, scrollViewHeight } = useCTSDimensions();

  const { data, loading } = useGetCurrentUserQuery();

  const currentUser = data?.getCurrentUser;

  if (loading) {
    return (
      <CustomBox type="wide">
        <Text>Loading...</Text>
      </CustomBox>
    );
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <CustomBox>
        <ScrollView
          flexGrow={1}
          px={paddingHWide}
          h={scrollViewHeight}
          //scrollIndicatorInsets fixes weird scroll indicator behaviour in iOS
          scrollIndicatorInsets={{ right: 1 }}
          contentContainerStyle={{
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <UserSettingsContainer user={currentUser} />

          <Box pb={spacingBottom} />
        </ScrollView>
      </CustomBox>
    </KeyboardAvoidingView>
  );
}
