import React from 'react';
import { Box, Skeleton } from 'native-base';
import moment from 'moment';
import { useListTenantMetaEventsBasicQuery } from '@/graphql/components';

import { ChartData } from '@/types/Dashboard/types';
import { ThreatsChartsMobile as MobileChat } from '@/components/Dashboard/ThreatsChartMobile';
import { ThreatsChartsWeb as WebChart } from '@/components/Dashboard/ThreatsChartWeb';
import { Platform } from 'react-native';
import { useThemeBackgroundColor } from '@/hooks/useThemeColor';

interface ThreatChartContainerProps {
  refetchFunctions: (() => Promise<any>)[];
}

export const ThreatChartContainer: React.FC<ThreatChartContainerProps> = ({ refetchFunctions }) => {

  const eventsBasics = useListTenantMetaEventsBasicQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        event_name: { eq: 'bounceralert' },
      },
      limit: 200,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });
 
  React.useEffect(()=> {
   refetchFunctions.push(eventsBasics.refetch)
  },[])
  const events = eventsBasics?.data?.listTenantMetaEvents?.items || [];

  const bgColor = useThemeBackgroundColor('card');

  const lastSixMonths = moment().subtract(6, 'months').unix();

  const recentEvents = events?.filter((item) => {
    return moment(item.timestamp).isAfter(lastSixMonths);
  });

  const chartData: ChartData[] = [];
  const months: string[] = [];

  recentEvents?.map((item) => {
    if (!months.includes(moment(item.timestamp).format('MMM'))) {
      months.push(moment(item.timestamp).format('MMM'));
      chartData.push({
        month: moment(item.timestamp).format('MMM'),
        value: 0,
      });
    }
  });

  recentEvents?.map((item) => {
    switch (moment(item.timestamp).format('MMM')) {
      case months[0]:
        return (chartData[0].value += 1);
      case months[1]:
        return (chartData[1].value += 1);
      case months[2]:
        return (chartData[2].value += 1);
      case months[3]:
        return (chartData[3].value += 1);
      case months[4]:
        return (chartData[4].value += 1);
      case months[5]:
        return (chartData[5].value += 1);
      default:
        return;
    }
  });

  if (eventsBasics.loading) {
    return <Skeleton width="100%" minH="350px" borderRadius={10} />;
  }
  return (
    <Box display="flex" bg={bgColor} borderRadius={10} p={8}>
      {Platform.OS == "web" ? (
        <WebChart data={chartData.reverse()} />
      ) : (
        <MobileChat data={chartData.reverse()} />
      )}
    </Box>
  );
}
