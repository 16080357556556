import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import { Button, Box, Text } from 'native-base';

import { AlertContext } from '@/context/AlertContext';
import { AuthForm } from '@/components/Forms/AuthForm';
import { useTranslation } from 'react-i18next';

interface Props {
  code?: string;
  email: string;
  linkToSignIn: () => void;
}

export function VerifyEmail({ code, email, linkToSignIn }: Props) {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();

  const CallAlert = React.useContext(AlertContext);

  function handleSuccess() {
    CallAlert && CallAlert(t('emailVerified'), 'success');

    linkToSignIn();
  }

  async function confirmUser(verificationCode: string) {
    setError('');
    setSuccess('');
    try {
      await Auth.confirmSignUp(email, verificationCode);
      handleSuccess();
    } catch (e: any) {
      console.log(e);
      const defaultMessage = t('errorDefault');

      switch (e?.code) {
        case 'CodeMismatchException':
        case 'ExpiredCodeException':
          setError(e?.message || defaultMessage);
          break;
        default:
          setError(defaultMessage);
          break;
      }
    }
  }

  useEffect(() => {
    if (email && code) {
      confirmUser(code).then(() => setReady(true));
    } else {
      setReady(true);
    }
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <Formik
      initialValues={{ code: code || '' }}
      validateOnBlur
      // validationSchema={VerifyEmailSchema}
      onSubmit={async (_values, actions) => {
        setError('');
        setSuccess('');
        try {
          await Auth.resendSignUp(email);
          setSuccess(t('emailCodeSent'));
        } catch (e) {
          setError(t('errorDefault'));
        }
        actions.setSubmitting(false);
      }}
    >
      {(props) => (
        <AuthForm error={error} success={success}>
          <Box py={24}>
            <Text>
              {t('verifyEmailHeader')}&nbsp;
              <Text bold>{email}</Text>.
            </Text>
          </Box>
          <Button
            disabled={props.isSubmitting}
            onPress={props.submitForm}
            isLoading={props.isSubmitting}
            colorScheme="orange"
            _text={{
              color: 'white.50',
            }}
            testID="Resend Verification Email"
          >
            {t('verifyEmailResend')}
          </Button>
          <Button
            disabled={props.isSubmitting}
            variant="link"
            onPress={linkToSignIn}
            _text={{
              color: 'orange.500',
            }}
            testID="Sign In"
          >
            {t('signIn')}
          </Button>
        </AuthForm>
      )}
    </Formik>
  );
}
