import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentUserFragment, UserDetailsFragment } from '@/graphql/components';
import { Text, VStack } from 'native-base';
import { OrgMembersList } from '@/components/Settings/OrgMemebersList';

interface Props {
  user?: CurrentUserFragment;
  users: UserDetailsFragment[];
}

export function OrgSettingsContainer({ user, users }: Props) {
  const { t } = useTranslation();

  const orgName = user?.tenant?.name ?? '';

  return (
    <VStack
      space={10}
      width="100%"
      maxW="550px"
      px={4}
      pb={5}
    >
      {orgName && (
        <Text fontSize="4xl" bold>
          {t('organizationMessage', { orgName: orgName })}
        </Text>
      )}
      <OrgMembersList users={users} />
    </VStack>
  );
}
