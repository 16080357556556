import React from 'react';
import { Linking } from 'react-native';
import {
  HStack,
  Pressable,
  Switch,
  Text,
  useColorMode,
  VStack,
} from 'native-base';

import { AppVersion } from '@/components/Settings/AppVersionModal';
import { CurrentUserFragment } from '@/graphql/components';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  NotificationsEmptyIcon,
  PhoneIcon,
  SupportIcon,
  ThemeIcon,
} from '@/components/CustomIcons';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';
import { ToggleCard } from '@/components/Settings/ToggleCard';
import { useTranslation } from 'react-i18next';

interface Props {
  currentUser: CurrentUserFragment;
}

export function AppSettingsContainer({ currentUser }: Props) {
  const { t } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const [selectedCard, setSelectedCard] = React.useState(0);
  const [isDarkModeChecked, setIsDarkModeChecked] = React.useState(false);
  React.useEffect(() => {
    colorMode === 'dark'
      ? setIsDarkModeChecked(true)
      : setIsDarkModeChecked(false);
  }, []);

  const bgCardColor = useThemeBackgroundColor('card');
  const primaryColor = useThemeFontColor('primary');

  const handleDarkModeToggle = () => {
    setIsDarkModeChecked(!isDarkModeChecked);
    toggleColorMode();
  };

  type SettingsProps = {
    title: string;
    cardNumber: number;
    icon?: React.ReactElement;
  };

  const SettingsCard = ({
    title,
    cardNumber,
    children,
    icon,
  }: React.PropsWithChildren<SettingsProps>) => {
    if (selectedCard !== cardNumber)
      return (
        <Pressable
          onPress={() =>
            setSelectedCard(selectedCard === cardNumber ? 0 : cardNumber)
          }
        >
          <HStack
            p={5}
            bg={bgCardColor}
            borderRadius="10px"
            justifyContent="space-between"
          >
            <HStack space={5}>
              {icon && icon}
              <Text fontSize="2xl">{title}</Text>
            </HStack>

            {selectedCard === cardNumber ? (
              <ChevronDownIcon iconSize={5} color={primaryColor} />
            ) : (
              <ChevronRightIcon iconSize={5} color={primaryColor} />
            )}
          </HStack>
        </Pressable>
      );

    return (
      <VStack p={5} bg={bgCardColor} borderRadius="10px" space={5}>
        <Pressable onPress={() => setSelectedCard(0)}>
          <HStack justifyContent="space-between">
            <HStack space={5}>
              {icon && icon}
              <Text fontSize="2xl">{title}</Text>
            </HStack>

            {selectedCard === cardNumber ? (
              <ChevronDownIcon iconSize={5} color={primaryColor} />
            ) : (
              <ChevronRightIcon iconSize={5} color={primaryColor} />
            )}
          </HStack>
        </Pressable>
        {selectedCard === cardNumber && children}
      </VStack>
    );
  };

  type LinkProps = {
    title: string;
    onPress?: () => void;
  };

  const Link = ({ title, onPress }: LinkProps) => (
    <Text color="orange.500" onPress={onPress}>
      {title}
    </Text>
  );

  return (
    <VStack
      space={3}
      flex={1}
      px={4}
      width="100%"
      pb={5}
      maxW="550px"
    >
      <HStack
        p={5}
        bg={bgCardColor}
        borderRadius="10px"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack space={5}>
          <ThemeIcon iconSize={8} color={primaryColor} />
          <Text fontSize="2xl">{t('darkTheme')}</Text>
        </HStack>
        <Switch
          size="sm"
          offTrackColor="black.600"
          colorScheme="orange"
          onToggle={handleDarkModeToggle}
          isChecked={isDarkModeChecked}
        />
      </HStack>

      {currentUser && (
        <SettingsCard
          title={t('settingsNotifications')}
          cardNumber={2}
          icon={<NotificationsEmptyIcon iconSize={8} color={primaryColor} />}
        >
          <ToggleCard currentUser={currentUser} />
        </SettingsCard>
      )}

      <SettingsCard
        title={t('settingsAbout')}
        cardNumber={3}
        icon={<PhoneIcon iconSize={8} color={primaryColor} />}
      >
        <Link
          title={t('settingsPrivacy')}
          onPress={() =>
            Linking.openURL('https://jp.security.ntt/privacy_nsj')
          }
        />
        <Link
          title={t('settingsTos')}
          onPress={() => Linking.openURL('https://jp.security.ntt/products_and_services/SOC_in_Pocket/terms_and_conditions')}
        />
        <AppVersion />
      </SettingsCard>

      <SettingsCard
        title={t('settingsSupport')}
        cardNumber={4}
        icon={<SupportIcon iconSize={8} color={primaryColor} />}
      >
        <Link
          title={t('settingsFaq')}
          onPress={() =>
            Linking.openURL('https://jp.security.ntt/products_and_services/SOC_in_Pocket/about_CyberThreatSensor')
          }
        />
        {/* TODO: link to contact section of website */}
        {/* <Link title="Contact us" />
        <FeedbackModal /> */}
      </SettingsCard>

    </VStack>
  );
}
