import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  useGetCurrentUserQuery,
  useUpdateTenantUserSettingsMutation,
  CurrentUserFragment,
  TenantUserSettings,
} from '@/graphql/components';

import { AlertContext } from '@/context/AlertContext';
import { FormToggle } from '@/components/Forms/form-toggle.component';

interface AutoSubmitProps {
  values: TenantUserSettings;
  submitForm: () => void;
  dirty: boolean;
}

const AutoSubmit: React.FC<AutoSubmitProps> = ({
  values,
  submitForm,
  dirty,
}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    mounted ? submitForm() : setMounted(true);
  }, [values, submitForm, dirty]);

  return null;
};

const SettingsSchema = Yup.object().shape({
  emailNotifications: Yup.boolean().required(),
  mobileNotifications: Yup.boolean().required(),
  webNotifications: Yup.boolean().required(),
});

interface Props {
  currentUser: CurrentUserFragment;
}

export function ToggleCard({ currentUser }: Props) {
  const CallAlert = React.useContext(AlertContext);
  const { t } = useTranslation();

  const settings = currentUser.settings;

  const notify = () => {
    CallAlert && CallAlert('Settings updated successfully', 'success');
  };

  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  });

  const [updateTenantUserSettings] = useUpdateTenantUserSettingsMutation({
    onCompleted: notify,
    onError: (er) => {
      console.log(er);

      CallAlert && CallAlert('Failed to update settings', 'error');
    },
    update: (store, update) => {
      const settingsUpdate = update?.data?.updateTenantUserSettings;

      if (!settingsUpdate) {
        return;
      }

      store.writeFragment({
        id: `${currentUser.id}`,
        fragment: gql`
          fragment UserFragment on TenantUserSettings {
            emailNotifications
            mobileNotifications
            webNotifications
          }
        `,
        data: {
          ...data,
          settings: {
            ...updateTenantUserSettings,
          },
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        ...settings,
      }}
      validateOnBlur
      validationSchema={SettingsSchema}
      onSubmit={async (values) => {
        try {
          updateTenantUserSettings({
            variables: {
              input: (({ __typename, ...rest }) => rest)(values),
            },
          });
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(props) => (
        <>
          <FormToggle
            accessibilityLabel="Email notifications toggle"
            disabled={props.isSubmitting}
            id="emailNotifications"
            name={t('email')}
            testID="email-notifications-toggle"
          />

          <FormToggle
            accessibilityLabel="Web notifications toggle"
            disabled={props.isSubmitting}
            id="webNotifications"
            name={t('web')}
            testID="web-notifications-toggle"
          />

          <AutoSubmit
            values={props.values}
            submitForm={props.submitForm}
            dirty={props.dirty}
          />
        </>
      )}
    </Formik>
  );
}
