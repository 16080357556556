import { useColorMode, useTheme } from 'native-base';

//gets current theme, returns background color and card color
export function useThemeBackgroundColor(type: string) {
  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark' ? true : false;

  switch (type) {
    case 'card':
      return isDark ? colors.black['800'] : colors.white['100'];

    case 'input':
      return isDark ? colors.black['700'] : colors.white['200'];

    default:
      //'screen'
      return isDark ? colors.black['900'] : colors.white['50'];
  }
}

//gets current theme, returns different font colors
export function useThemeFontColor(type: string) {
  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark' ? true : false;

  switch (type) {
    case 'secondary':
      return isDark ? colors.white['300'] : colors.black['600'];

    case 'inverted':
      return isDark ? colors.black['900'] : colors.white['50'];

    default:
      //'primary'
      return isDark ? colors.white['50'] : colors.black['900'];
  }
}
