import React from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Box, Center, FormControl, Text, VStack } from 'native-base';

import { AuthLogo } from '@/components/Auth/Logo';

interface Props {
  error?: string;
  success?: string;
  hasLogo?: boolean;
}

export function AuthForm({
  children,
  error,
  success,
  hasLogo,
}: React.PropsWithChildren<Props>) {

  return (
    // TODO: add https://docs.nativebase.io/next/keyboard-avoiding-view#page-title
    <KeyboardAwareScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
    >
      <Center flex={1} w="100%">
        {hasLogo !== false && (
          <Box>
            <AuthLogo />
          </Box>
        )}
        <FormControl isRequired px={4}  maxW="600px" >
          <VStack space={3} pb={5}>
            {success ? <Text color="green.500">{success}</Text> : null}
            {error ? (
              <Text testID="auth-form-error" color="red.500">
                {error}
              </Text>
            ) : null}
            {children}
          </VStack>
        </FormControl>
      </Center>
    </KeyboardAwareScrollView>
  );
}
