import React from 'react';
import { Platform } from 'react-native';
import { KeyboardAvoidingView, ScrollView } from 'native-base';
// import { RouteProp, useRoute } from '@react-navigation/native';

import { CustomBox } from '@/components/CustomBox';
import { DeviceEnrollContainer } from '@/containers/Device/DeviceEnrollContainer';
// import { DeviceStackParamList } from '../Navigator';
import { useCTSDimensions } from '@/hooks/useCTSDimensions';
import { useListTenantDevicesQuery } from '@/graphql/components';

export function DeviceEnrollScreen() {
  // const { params } = useRoute<
  //   RouteProp<DeviceStackParamList, 'DeviceEnroll'>
  // >();

  const { scrollViewHeight, paddingHWide } = useCTSDimensions();

  const {
    data,
    // error,
    // refetch,
    // networkStatus,
    // loading,
  } = useListTenantDevicesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const deviceNames = data?.listTenantDevices?.items
    ? data?.listTenantDevices?.items?.map((item) => {
        return item.name;
      })
    : [];

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <CustomBox>
        <ScrollView
          flexGrow={1}
          px={paddingHWide}
          h={scrollViewHeight}
          //scrollIndicatorInsets fixes weird scroll indicator behaviour in iOS
          scrollIndicatorInsets={{ right: 1 }}
          contentContainerStyle={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <DeviceEnrollContainer
            // initKey={params?.initKey}
            deviceNames={deviceNames}
          />
        </ScrollView>
      </CustomBox>
    </KeyboardAvoidingView>
  );
}
