import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import { Button } from 'native-base';

import { AlertContext } from '@/context/AlertContext';
import { AuthForm } from '@/components/Forms/AuthForm';
import { CreateTenantSchema } from '@/components/Forms/validationSchemas';
import { FormInput } from '@/components/Forms/form-input.component';
import { registerTenantCreation } from '@/analytics/tenant';
import { useTranslation } from 'react-i18next';

interface Props {
  initKey: string;
  linkToSignIn: () => void;
  linkToVerifyEmail: (email: string) => void;
}

export function CreateTenant({
  initKey,
  linkToSignIn,
  linkToVerifyEmail,
}: Props) {
  const [formError, setFormError] = useState('');
  const { t } = useTranslation();

  const CallAlert = React.useContext(AlertContext);

  async function handleSuccess(email: string) {
    CallAlert && CallAlert(t('createOrganization'), 'success');

    await registerTenantCreation();
    linkToVerifyEmail(email.toLowerCase());
  }

  const errorMessage = (e: any) => {
    const defaultMessage = t('errorDefault');
    //Todo figure out what this message looks like and use localization
    const lambdaMessage = /PreSignUp failed with error (.+)/.exec(
      e?.message || ''
    );

    switch (e?.code) {
      case 'UsernameExistsException':
        return e.message;
      case 'UserLambdaValidationException':
        return lambdaMessage?.[1] || defaultMessage;
      default:
        return defaultMessage;
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        tenantName: '',
      }}
      validationSchema={CreateTenantSchema}
      validateOnBlur
      onSubmit={async (values: any) => {
        try {
          await Auth.signUp({
            username: values.email.toLowerCase(),
            password: values.password,
            attributes: {
              email: values.email,
              given_name: values.firstName,
              family_name: values.lastName,
            },
            clientMetadata: {
              init_key: initKey,
              tenant_name: values.tenantName,
            },
          });
          await handleSuccess(values.email);
        } catch (e) {
          setFormError(errorMessage(e));
        }
      }}
    >
      {(props: any) => {
        return (
          <AuthForm error={formError}>
            <FormInput id="tenantName" placeholder={t('organizationName')} />
            <FormInput
              id="firstName"
              placeholder={t('firstName')}
              autoComplete="name-given"
              autoCompleteType="name"
            />
            <FormInput
              id="lastName"
              placeholder={t('lastName')}
              autoComplete="name-family"
              autoCompleteType="name"
            />
            <FormInput
              id="email"
              placeholder={t('email')}
              keyboardType="email-address"
              autoComplete="username"
              autoCompleteType="email"
              autoCapitalize="none"
            />
            <FormInput
              id="password"
              placeholder={t('password')}
              secureTextEntry
              type="password"
              autoComplete="password-new"
              autoCompleteType="password"
              autoCapitalize="none"
              textContentType="newPassword"
            />
            <FormInput
              id="passwordConfirm"
              placeholder={t('passwordConfirm')}
              secureTextEntry
              type="password"
              autoComplete="password-new"
              autoCompleteType="password"
              autoCapitalize="none"
              textContentType="newPassword"
            />
            <Button
              disabled={props.isSubmitting}
              onPress={props.submitForm}
              isLoading={props.isSubmitting}
              colorScheme="orange"
              _text={{
                color: 'white.50',
              }}
              testID="Sign Up"
            >
              {props.isSubmitting ? t('signingUp') : t('signUp')}
            </Button>

            <Button
              disabled={props.isSubmitting}
              variant="link"
              onPress={linkToSignIn}
              _text={{
                color: 'orange.500',
              }}
              testID="Sign In"
            >
              sign in
            </Button>
          </AuthForm>
        );
      }}
    </Formik>
  );
}
