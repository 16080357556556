import React, { useEffect, useState } from 'react';
import {
  HStack,
  Pressable,
  Text,
  VStack,
  Image,
  useColorModeValue,
} from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  LanguageIcon,
} from '@/components/CustomIcons';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';
import { useTranslation } from 'react-i18next';

export const LanguageSelector = () => {
  const { i18n, t } = useTranslation();
  const cardColor = useColorModeValue('white.300', 'black.600');
  const bgCardColor = useThemeBackgroundColor('card');
  const primaryColor = useThemeFontColor('primary');

  const [language, setLanguage] = useState<string>();
  const [cardOpenStatus, setCardOpenStatus] = useState(false);

  const languageOptions = [
    {
      label: t('languageEnglish'),
      key: 'en-US',
      uri: require('@/public/images/icons8-usa-96.png'),
    },
    {
      label: t('languageJapanese'),
      key: 'ja-JP',
      uri: require('@/public/images/icons8-japan-96.png'),
    },
  ];

  if (process.env.CTS_ENV == 'local' || process.env.CTS_ENV == 'lab') {
    languageOptions.push({
      label: t('languageDebug'),
      key: 'debug',
      uri: require('@/public/images/icons8-developer-mode-96.png'),
    });
  }

  useEffect(() => {
    const getStoredLanguage = async () => {
      try {
        await AsyncStorage.getItem('settings.lang').then((storedLanguage) => {
          if (storedLanguage) {
            setLanguage(storedLanguage);
          } else {
            setLanguage(languageOptions[0].key);
          }
        });
      } catch (error) {
        setLanguage(languageOptions[0].key);
      }
    };
    getStoredLanguage();
  }, []);
  const matchedLanguage = languageOptions.find((obj) => {
    return obj.key === language;
  });

  return (
    <VStack bg={bgCardColor} borderRadius="10px" space={2}>
      <Pressable onPress={() => setCardOpenStatus(!cardOpenStatus)}>
        <HStack
          p={5}
          bg={bgCardColor}
          borderRadius="10px"
          alignItems="center"
          justifyContent="space-between"
        >
          <HStack space={5}>
            <LanguageIcon iconSize={8} color={primaryColor} />
            <VStack>
              <Text fontSize="2xl">{t('settingsLanguage')}</Text>
              <Text color="orange.500">{matchedLanguage?.label}</Text>
            </VStack>
          </HStack>
          {cardOpenStatus ? (
            <ChevronDownIcon iconSize={5} color={primaryColor} />
          ) : (
            <ChevronRightIcon iconSize={5} color={primaryColor} />
          )}
        </HStack>
      </Pressable>

      {cardOpenStatus ? (
        <VStack alignItems="center" space={2} pb={5}>
          {languageOptions.map((option, index) => {
            return (
              <HStack
                key={index}
                bg={cardColor}
                width="68%"
                borderRadius="10px"
              >
                <Pressable
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  onPress={() => {
                    i18n.changeLanguage(option.key);
                    setLanguage(option.key);
                    setCardOpenStatus(false);
                  }}
                >
                  <HStack
                    width="100%"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <HStack justifyContent="center" width="50%">
                      <Image
                        alt={option.label}
                        size="48px"
                        source={option.uri}
                      />
                    </HStack>
                    <HStack justifyContent="center" width="50%">
                      <Text fontSize="lg">{option.label}</Text>
                    </HStack>
                  </HStack>
                </Pressable>
              </HStack>
            );
          })}
        </VStack>
      ) : null}
    </VStack>
  );
};
