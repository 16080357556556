import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import { Button } from 'native-base';

import { AlertContext } from '@/context/AlertContext';
import { AuthForm } from '@/components/Forms/AuthForm';
import { FormInput } from '@/components/Forms/form-input.component';
import { SignUpSchema } from '@/components/Forms/validationSchemas';
import { submitOnEnter } from '@/utils/form';
import { useTranslation } from 'react-i18next';

interface Props {
  inviteCode: string;
  email: string;
  linkToSignIn: () => void;
}

export function SignUp({ inviteCode, email, linkToSignIn }: Props) {
  const [formError, setFormError] = useState('');
  const { t } = useTranslation();

  const CallAlert = React.useContext(AlertContext);

  function errorMessage(e: any) {
    const defaultMessage = t('errorDefault');
    const lambdaMessage = /PreSignUp failed with error (.+)/.exec(
      e?.message || ''
    );

    switch (e?.code) {
      case 'UsernameExistsException':
        return e.message;
      case 'UserLambdaValidationException':
        return lambdaMessage?.[1] || defaultMessage;
      default:
        return defaultMessage;
    }
  }

  function handleSuccess() {
    CallAlert && CallAlert(t('createAccountSuccess'), 'success');

    linkToSignIn();
  }

  return (
    <Formik
      initialValues={{
        email: email?.toLowerCase() || '',
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
      }}
      validationSchema={SignUpSchema}
      validateOnBlur
      onSubmit={async (values) => {
        try {
          const resp = await Auth.signUp({
            username: values.email,
            password: values.password,
            attributes: {
              email: values.email,
              given_name: values.firstName,
              family_name: values.lastName,
            },
            clientMetadata: {
              invite_code: inviteCode,
            },
          });

          console.log(resp);
          handleSuccess();
        } catch (e) {
          setFormError(errorMessage(e));
        }
      }}
    >
      {(props) => (
        <AuthForm error={formError}>
          <FormInput
            id="firstName"
            placeholder={t('firstName')}
            autoComplete="name-given"
            autoCompleteType="name"
          />
          <FormInput
            id="lastName"
            placeholder={t('lastName')}
            autoComplete="name-family"
            autoCompleteType="name"
          />
          <FormInput
            id="email"
            placeholder={t('email')}
            keyboardType="email-address"
            autoComplete="username"
            autoCompleteType="email"
            autoCapitalize="none"
            disabled={true}
          />
          <FormInput
            id="password"
            type="password"
            placeholder={t('password')}
            secureTextEntry
            autoComplete="password-new"
            autoCompleteType="password"
            autoCapitalize="none"
            textContentType="newPassword"
          />
          <FormInput
            id="passwordConfirm"
            type="password"
            placeholder={t('passwordConfirm')}
            secureTextEntry
            autoComplete="password-new"
            autoCompleteType="password"
            autoCapitalize="none"
            textContentType="newPassword"
            onKeyPress={submitOnEnter(props.submitForm)}
          />
          <Button
            disabled={props.isSubmitting}
            onPress={props.submitForm}
            isLoading={props.isSubmitting}
            colorScheme="orange"
            _text={{
              color: 'white.50',
            }}
            testID="Sign Up"
          >
            {t('signUp')}
          </Button>
          <Button
            disabled={props.isSubmitting}
            variant="link"
            onPress={linkToSignIn}
            _text={{
              color: 'orange.500',
            }}
            testID="Sign In"
          >
            {t('signIn')}
          </Button>
        </AuthForm>
      )}
    </Formik>
  );
}
