import React, { createContext, useEffect, useState } from 'react';
import { CtsSite } from '@/types/cts';
import { AUTH_TYPE } from 'aws-appsync';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  defaultDataIdFromObject,
} from '@apollo/client';
import { Auth } from 'aws-amplify';

import config from '../config';

type ObjectTypeKey = keyof typeof ObjectType;

enum ObjectType {
  DeviceStatus = "DeviceStatus",
  User = "User",
  TenantInvite = "TenantInvite",
  MetaEvent = "MetaEvent"

}
//TODO Need to look into caching outside of MetaEvent.  It hasn't been touched in a while 
const objectIdPrefixMapping: Record<ObjectTypeKey, (object: any) => string> = {
  [ObjectType.DeviceStatus]: (object: any) => `${ObjectType.DeviceStatus}:${object.id}`,
  [ObjectType.User]: (object: any) => `${ObjectType.User}:${object.id}`,
  [ObjectType.TenantInvite]: (object: any) => `${ObjectType.User}:${object.email}`,
  [ObjectType.MetaEvent]: (object: any) => `${ObjectType.MetaEvent}:${object.event_id}`
};

function createCache() {
  return new InMemoryCache({
    dataIdFromObject: (object) => {
      const objectIdGenerator = objectIdPrefixMapping[object.__typename as ObjectTypeKey];
      if (objectIdGenerator) {
        return objectIdGenerator(object);
      }
      return defaultDataIdFromObject(object);
    },
  });
}

const createClient = (
  url: string,
  region: string
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({ uri: url });

  const link = ApolloLink.from([
    createAuthLink({
      url,
      region,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async (): Promise<string> => {
          const session = await Auth.currentSession();
          const token = session.getAccessToken().getJwtToken();
          return token;
        },
      },
    }),
    // @ts-ignore
    createSubscriptionHandshakeLink(url, httpLink),
  ]);

  return new ApolloClient({
    // TODO: Check for when apollo client works bettter with
    // Appsync again
    // eslint-disable-next-line
    // @ts-ignore
    link,
    cache: createCache(),
  });
};

type AppSyncClients = Record<CtsSite, ApolloClient<NormalizedCacheObject>>;

interface AppSyncState {
  clients: AppSyncClients;
}

export const AppSyncContext = createContext<AppSyncState>({
  clients: {} as AppSyncClients,
});

export const AppSyncProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = useState<AppSyncState>({
    clients: {} as AppSyncClients,
  });

  useEffect(() => {
    const { endpoints } = config;
    const clients = Object.entries(endpoints).reduce(
      (prev, [key, value]): AppSyncClients => {
        const { url, region } = value;
        return {
          ...prev,
          [key]: createClient(url, region),
        };
      },
      {} as AppSyncClients
    );

    setState({ clients });
  }, []);

  return Object.keys(state.clients).length ? (
    <AppSyncContext.Provider value={state}>
      <ApolloProvider client={state.clients.eu1}>{children}</ApolloProvider>
    </AppSyncContext.Provider>
  ) : null;
};
