import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Modal, Text } from 'native-base';
import AppInfo from '@/app.json';
import Notes from '@/version-notes.json';

import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';

export function AppVersion() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const bgColor = useThemeBackgroundColor('screen');
  const fontColor = useThemeFontColor('primary');

  const versionNotes = Notes.versionNotes[Notes.versionNotes.length - 1].notes;
  const version = AppInfo.expo.version;

  return (
    <>
      <Text color="orange.500" onPress={() => setShow(true)}>
        {t('appVersion', { version: version })}
      </Text>

      <Modal
        isOpen={show}
        onClose={() => setShow(false)}
        backgroundColor="rgba(0, 0, 0, 0.40)"
      >
        <Modal.Content maxWidth="400px" bg={bgColor}>
          <Modal.CloseButton />
          <Modal.Body p={5}>
            <Text bold mb={5}>
              {t('settingsVersion', { version: version })}
            </Text>
            {versionNotes.map((note, index) => (
              <HStack key={index} alignItems="flex-start" space={2}>
                <Box w={1} h={1} borderRadius={20} bg={fontColor} mt={3} />
                <Text maxWidth="90%">{note}</Text>
              </HStack>
            ))}
          </Modal.Body>
          <Modal.Footer bg={bgColor} justifyContent="center">
            <Button
              onPress={() => {
                setShow(false);
              }}
              colorScheme="orange"
            >
              {t('close')}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
