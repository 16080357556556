import React, { useEffect } from 'react';
import { ScrollView, Spinner } from 'native-base';

import { registerPageView } from '@/analytics/navigation';

import {
  useGetCurrentUserQuery,
  useListOrgUsersQuery,
} from '@/graphql/components';

import { OrgSettingsContainer } from '@/containers/Settings/OrgSettingsContainer';

export function OrgSettingsScreen(): React.ReactElement {
  useEffect(() => {
    registerPageView('settingsNotificaitons');
  }, []);

  const user = useGetCurrentUserQuery();
  const currentUser = user.data?.getCurrentUser;
  const { data, loading } = useListOrgUsersQuery({
    variables: { orgId: user.data?.getCurrentUser.tenant.id ?? '' },
    notifyOnNetworkStatusChange: true,
  });

  const users = data?.listOrgUsers?.items ?? [];

  if (loading) {
    return <Spinner />;
  }

  return (
    <ScrollView
      contentContainerStyle={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <OrgSettingsContainer
        user={currentUser}
        users={users}
      />
    </ScrollView>
  );
}
