import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { useGetCurrentUserQuery } from '@/graphql/components';

import { AlertProvider } from '@/context/AlertContext';
import {
  AlertLinkingConfig,
  AlertNavigator,
} from '@/navigation/AlertNavigator';
import Container from '@/components/navigation/Container';
import {
  DashboardChartNavigator,
  DashboardLinkingConfig,
} from './DashboardNavigator';
import {
  DeviceLinkingConfig,
  DeviceNavigator,
} from '@/navigation/DeviceNavigator';
import { Error } from '@/components/Error';
import {
  SettingsNavigator,
  SettingsLinkingConfig,
} from '@/navigation/SettingsNavigator';
import { usePushNotifications } from '@/hooks/usePushNotifications';
import { useTranslation } from 'react-i18next';

const Stack = createStackNavigator();

export const HomeLinkingConfig = {
  screens: {
    Dashboard: DashboardLinkingConfig,
    Device: DeviceLinkingConfig,
    Alert: AlertLinkingConfig,
    Settings: SettingsLinkingConfig,
  },
};

export function HomeNavigator(): React.ReactElement {
  usePushNotifications();
  const { t } = useTranslation();
  const { error, refetch } = useGetCurrentUserQuery({
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    return <Error text={t('errorUser')} retry={() => refetch()} />;
  }

  return (
    <Container>
      <AlertProvider>
        <Stack.Navigator headerMode="none">
          <Stack.Screen name="Dashboard" component={DashboardChartNavigator} />
          <Stack.Screen name="Device" component={DeviceNavigator} />
          <Stack.Screen name="Alert" component={AlertNavigator} />
          <Stack.Screen name="Settings" component={SettingsNavigator} />
        </Stack.Navigator>
      </AlertProvider>
    </Container>
  );
}
