import React from 'react';
import { StatusBar as NBStatusBar, useColorMode } from 'native-base';

import { useThemeBackgroundColor } from '../hooks/useThemeColor';

export function StatusBar() {
  const { colorMode } = useColorMode();
  const bgColor = useThemeBackgroundColor('screen');

  const isDark = colorMode === 'dark' ? true : false;
  const style = isDark ? 'light-content' : 'dark-content';

  return <NBStatusBar backgroundColor={bgColor} barStyle={style} />;
}
