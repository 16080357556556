import React from 'react';
import { useLinkTo } from '@react-navigation/native';
import {
  Box,
  Button,
  HStack,
  Pressable,
  Text,
  useTheme,
  VStack,
} from 'native-base';
import { Auth } from 'aws-amplify';

import {
  ChevronRightIcon,
  SettingsIcon,
  SignOutIcon,
  UserIcon,
  UserGroupIcon,
} from '@/components/CustomIcons';
import { CurrentUserFragment } from '@/graphql/components';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '@/components/shared/LanguageSelector';

type Props = {
  currentUser?: CurrentUserFragment;
};

export function GeneralSettingsContainer({ currentUser }: Props) {
  const { t } = useTranslation();
  const linkTo = useLinkTo();
  const { colors } = useTheme();

  const bgColor = useThemeBackgroundColor('card');
  const primaryFontColor = useThemeFontColor('primary');
  const secondaryFontColor = useThemeFontColor('secondary2');

  const User = () => (
    <Box mb={2}>
      <Text fontSize="4xl" bold>
        {currentUser?.firstName}
      </Text>
      <Text fontSize="4xl" bold>
        {currentUser?.lastName}
      </Text>
      <Text fontSize="xl" color={secondaryFontColor}>
        {currentUser?.email}
      </Text>
    </Box>
  );

  type SettingsProps = {
    title?: string;
    subtitle?: string;
    link?: string;
    icon?: React.ReactElement;
  };

  const SettingsCard = ({ title, subtitle, link, icon }: SettingsProps) => {
    return (
      <Pressable onPress={() => (link ? linkTo(link) : null)}>
        <HStack
          p={5}
          bg={bgColor}
          borderRadius="10px"
          justifyContent="space-between"
        >
          <HStack space={5}>
            {icon && icon}
            <VStack>
              <Text fontSize="2xl">{title}</Text>
              {subtitle && <Text color={secondaryFontColor}>{subtitle}</Text>}
            </VStack>
          </HStack>

          <ChevronRightIcon iconSize={5} color={primaryFontColor} />
        </HStack>
      </Pressable>
    );
  };

  return (
    <VStack
      space={3}
      flex={1}
      px={4}
      width="100%"
      pb={5}
      maxW="550px"
    >
      {currentUser && <User />}
      {currentUser && (
        <SettingsCard
          title={`${currentUser.firstName} ${currentUser.lastName}`}
          subtitle={t('userSettings')}
          link="/settings/user"
          icon={<UserIcon iconSize={8} color={primaryFontColor} />}
        />
      )}
      {currentUser && (
        <SettingsCard
          title={`${currentUser.tenant.name}`}
          subtitle={t('orgSettings')}
          link="/settings/org"
          icon={<UserGroupIcon iconSize={8} color={primaryFontColor} />}
        />
      )}
      <SettingsCard
        title={t('appSettings')}
        link="/settings/app"
        icon={<SettingsIcon iconSize={8} color={primaryFontColor} />}
      />
      <LanguageSelector />
      <Button
        onPress={() => Auth.signOut()}
        colorScheme="orange"
        _text={{
          color: 'white.50',
          fontSize: 'xl',
        }}
        rightIcon={<SignOutIcon color={colors.white['50']} iconSize={5} />}
      >
        {t('signOut')}
      </Button>
    </VStack>
  );
}
