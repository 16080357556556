import React from 'react';
import { Box, Button, Center, Text, useTheme } from 'native-base';

import { useThemeBackgroundColor } from '../hooks/useThemeColor';
import { RefreshIcon } from './CustomIcons';
import { useTranslation } from 'react-i18next';
interface Props {
  text: string;
  retry: () => void;
}

export function Error({ retry, text }: Props) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const bgColor = useThemeBackgroundColor('screen');

  return (
    <Box flex={1} bg={bgColor} justifyContent="center">
      <Center>
        <Text mb={8} fontSize="xl" testID="retry-message">
          {text}
        </Text>
        <Button
          width={40}
          onPress={retry}
          colorScheme="orange"
          _text={{ color: colors.white['50'], fontSize: 'xl' }}
          startIcon={<RefreshIcon iconSize={6} color={colors.white['50']} />}
          size="lg"
          testID="retry-button"
        >
          {t('retry')}
        </Button>
      </Center>
    </Box>
  );
}
