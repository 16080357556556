import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Clipboard from 'expo-clipboard';
import convert from 'convert-units';
import moment from 'moment';
import { Center, Box, HStack, Pressable, Text, VStack } from 'native-base';

import { TenantDeviceDetailsFragment } from '@/graphql/components';

import { AlertContext } from '@/context/AlertContext';
import { ChartIcon } from '@/components/CustomIcons';
import InitKeyCard from '@/components/Device/InitKeyCard';
import DeviceInfoCard from '@/components/Device/DeviceInfoCard';
import DeviceDisksNicsCard from '@/components/Device/DeviceDisksNicsCard';
import Card from '@/components/Device/Card';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';
import { HardwareProps } from '@/types/Device/types';

interface Props {
  device: TenantDeviceDetailsFragment;
}

export function DeviceDetailsContainer({ device }: Props) {
  const { t } = useTranslation();
  const bgCardColor = useThemeBackgroundColor('card');
  const primary = useThemeFontColor('primary');
  const secondary = useThemeFontColor('secondary');

  const CallAlert = React.useContext(AlertContext);

  const [hasCopied, setHasCopied] = React.useState(false);

  const isOnline = device?.status?.state === 'online' ? true : false;
  const deviceBWPeak =
    device?.metrics?.bandwidth?.data?.result?.[0]?.value?.[0];
  const deviceName = device?.alias || device?.id;

  const convertToGB = (bytes: number) => {
    if (bytes) {
      return (bytes / 1073741824).toFixed(2).toString() + ' GB';
    }
  };
  const deviceInfo = new Object({
    cpuCoresP: device?.info?.cpuCoresP,
    cpuProcessor: device?.info?.cpuProcessor,
    machine: device?.info?.machine,
    memorySwap: convertToGB(Number(device?.info?.memorySwap)),
    memoryTotal: convertToGB(Number(device?.info?.memoryTotal)),
    os: device?.info?.os,
    disks: device?.info?.disks?.length,
    nics: device?.info?.nics?.length,
  });

  const disks = device?.info?.disks?.map((disk) => {
    return {
      ...disk,
      size: convertToGB(Number(disk.size)),
      usage: disk.usage + '%',
    };
  });

  const deviceDisksNics: HardwareProps = new Object({
    disks: disks,
    nics: device?.info?.nics,
  });

  const parsedPeak =
    deviceBWPeak?.metricValue &&
    convert(parseFloat(deviceBWPeak?.metricValue)).from('b').toBest();
  const initKey = device?.init_key;

  const copyToClipboard = (code: string) => {
    try {
      Clipboard.setStringAsync(code);

      CallAlert && CallAlert(t('deviceEnrollClipboard'), 'success');
    } catch (error) {
      console.log(error);
      CallAlert && CallAlert(t('deviceError'), 'error');
    }
  };

  const statusIndicator = () => {
    switch (true) {
      case device?.state === 'initiate':
        return 'orange.500';
      case isOnline:
        return 'onlineGreen.500';
      default:
        return 'offlineRed.500';
    }
  };

  const Status = () => (
    <HStack height="100%" alignItems="center">
      <Box
        width={1.5}
        height="95%"
        backgroundColor={statusIndicator()}
        borderRadius="xl"
      />
    </HStack>
  );

  const OnlineMessage = () => {
    const lastSeen = device?.status?.lastSeen
      ? moment(device?.status?.lastSeen * 1000).format(
        'MMMM DD YYYY @ h:mm:ss A'
      )
      : null;

    if (isOnline)
      return (
        <Text fontSize="md" color={secondary} testID="device-online">
          {t('deviceOnline')}
        </Text>
      );

    return (
      <>
        <Text fontSize="md" color={secondary} testID="device-offline">
          {t('deviceOffline')}
        </Text>
        {lastSeen && (
          <Text fontSize="md" color={secondary}>
            {t('deviceLastSeen', { lastSeenDate: lastSeen })}
          </Text>
        )}
      </>
    );
  };

  const InitKeyCardContainer = () => {
    const truncateLength = 14;
    return (
      <Pressable
        onPress={() => {
          setHasCopied(true);
          copyToClipboard(initKey!);
        }}
      >
        <VStack
          p={5}
          borderRadius={10}
          bg={bgCardColor}
          testID="layout-container"
          space={3}
        >
          <Text fontSize={['lg', '2xl']}>
            {t('deviceEnrollCode', { cloudProvider: 'AWS' })}
          </Text>
          <InitKeyCard
            initKey={
              initKey!.length > truncateLength
                ? initKey!.slice(0, truncateLength) + '...'
                : initKey!
            }
            hasCopied={hasCopied}
          />
        </VStack>
      </Pressable>
    );
  };

  const checkValues = (obj: object | undefined) => {
    if (obj) {
      return !!Object.values(obj).length;
    }
    return false;
  };

  return (
    <Center>
      <VStack space={5} w="100%" maxW="1000px" testID="layout-container">
        <Card>
          <VStack space={5} testID="layout-container">
            <HStack space={3}>
              <Status />
              <VStack>
                <Text bold fontSize="2xl" testID="device-name">
                  {deviceName}
                </Text>
                <OnlineMessage />
              </VStack>
            </HStack>
          </VStack>
        </Card>
        {!initKey || initKey === '<empty>' ? (
          <VStack space={5} pb={5}>
            {checkValues(deviceInfo) && (
              <DeviceInfoCard deviceInfo={deviceInfo} />
            )}
            {(checkValues(deviceDisksNics?.disks) ||
              checkValues(deviceDisksNics?.nics)) && (
                <DeviceDisksNicsCard deviceDisksNics={deviceDisksNics} />
              )}
            <Card>
              <HStack
                space={8}
                justifyContent="flex-start"
              >
                {parsedPeak && (
                  <ChartIcon color={primary} iconSize={16} />
                )}
                {parsedPeak ? (
                  <VStack space={2} alignItems="flex-start" flex={1}>
                    <Text fontSize={['4xl', '2xl']} testID="peak-bandwidth">
                      {parsedPeak.val.toFixed(3)}&nbsp;
                      {parsedPeak.unit}
                    </Text>
                    <Text fontSize="lg">{t('devicePeakBandwidth')}</Text>
                  </VStack>
                ) : (
                  <Text
                    fontSize={['lg', '2xl']}
                    color={secondary}
                    testID="no-peak-bandwidth"
                  >
                    {t('deviceNoBandwidth')}
                  </Text>
                )}
              </HStack>
            </Card>
          </VStack>
        ) : (
          <InitKeyCardContainer />
        )}
      </VStack>
    </Center>
  );
}
