import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import translationJP from './jp/translation.json';
import { languageDetectorPlugin } from '../utils/languageDetectorPlugin';

const resources = {
  'en-US': {
    translation: translationEN,
  },
  'ja-JP': {
    translation: translationJP,
  },
};

const devEnv = process.env.CTS_ENV === 'local' || process.env.CTS_ENV === 'lab';

i18next
  .use(initReactI18next)
  // @ts-ignore
  .use(languageDetectorPlugin)
  .init({
    compatibilityJSON: 'v3',
    debug: devEnv,
    resources,
    fallbackLng: devEnv ? 'dev' : 'en-US',
  });
