import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLinkTo } from '@react-navigation/native';
import { UserDetailsFragment } from '@/graphql/components';
import {
  Box,
  Button,
  Center,
  HStack,
  Pressable,
  ScrollView,
  Text,
  useTheme,
  VStack,
} from 'native-base';

import { AlertContext } from '@/context/AlertContext';
import {
  AddUserIcon,
  // SettingsIcon,
  // XIcon,
} from '@/components/CustomIcons';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';
import { useCTSDimensions } from '@/hooks/useCTSDimensions';

interface Props {
  users: UserDetailsFragment[];
}

interface SelectedProps {
  id: number;
  value: boolean;
}

export function OrgMembersList({ users }: Props) {
  const { t } = useTranslation();
  const bgCardColor = useThemeBackgroundColor('card');
  const { colors } = useTheme();
  const { isDesktopWeb } = useCTSDimensions();
  const primaryColor = useThemeFontColor('primary');
  const secondaryColor = useThemeFontColor('secondary');
  const linkTo = useLinkTo();

  const CallAlert = React.useContext(AlertContext);

  const [isEditing, setIsEditing] = React.useState(false);
  const [selected, setSelected] = React.useState<Array<SelectedProps>>([]);

  React.useEffect(() => {
    users.length > 0 && resetSelected();
  }, [users]);

  const resetSelected = () => {
    setSelected(
      users.map((user, index) => {
        console.log(typeof user);
        return { id: index, value: false };
      })
    );
  };

  const Circle = () => {
    const size = 2;

    return (
      <Box
        bg={primaryColor}
        borderRadius={size * 2}
        height={size}
        width={size}
      />
    );
  };

  const Selector = ({ index }: { index: number }) => (
    <Box
      borderRadius={50}
      borderWidth={2}
      width={4}
      height={4}
      borderColor={primaryColor}
      alignItems="center"
      justifyContent="center"
    >
      {selected[index].value === true && (
        <Box
          borderRadius={50}
          width={2}
          height={2}
          backgroundColor={primaryColor}
        />
      )}
    </Box>
  );

  const InviteUsers = () => (
    <HStack space={3} alignItems="center">
      <Text onPress={() => linkTo('/settings/org/invite')} color="orange.500">
        {t('orgInvite')}
      </Text>
      <AddUserIcon iconSize={5} color={colors.orange[500]} />
    </HStack>
  );

  const BottomButtons = () => {
    return (
      <HStack justifyContent="space-between" width="100%">
        <Button
          width="47%"
          size="lg"
          colorScheme="orange"
          onPress={() => {
            setIsEditing(false);
            resetSelected();
          }}
        >
          {t('cancel')}
        </Button>
        {/* TODO: make a modal here, deactivate button until anything is selected */}
        <Button
          width="47%"
          size="lg"
          colorScheme="danger"
          onPress={() => {
            CallAlert && CallAlert(t('orgRemoveUser'), 'success');

            setIsEditing(false);
            resetSelected();
          }}
        >
          {t('confirm')}
        </Button>
      </HStack>
    );
  };

  return (
    <Box borderRadius={20} bg={bgCardColor} px={isDesktopWeb ? 10 : 8} py={5}>
      <HStack justifyContent="space-between" mb={4} height={6}>
        <Box width={6} />
        <Text color={secondaryColor}>
          {isEditing ? t('orgSelectUserRemove') : t('orgMembers')}
        </Text>
        <Box width={6} />
        {/* <Pressable
          width={6}
          onPress={() => {
            setIsEditing(!isEditing);
            isEditing && resetSelected();
          }}
          alignItems="flex-end"
        >
          {isEditing ? (
            <XIcon color={primaryColor} iconSize={6} />
          ) : (
            <SettingsIcon color={primaryColor} iconSize={6} />
          )}
        </Pressable> */}
      </HStack>

      <ScrollView
        maxH={isDesktopWeb ? '700px' : 300}
        nestedScrollEnabled={true}
        //scrollIndicatorInsets fixes weird scroll indicator behaviour in iOS
        scrollIndicatorInsets={{ right: 1 }}
      >
        <VStack space={2} testID="user-list-item">
          {users &&
            users.map((item, index) => (
              <HStack
                key={index}
                justifyContent="space-between"
                testID="user-list-item"
              >
                <ScrollView horizontal width="80%">
                  <HStack space={2}>
                    <Box justifyContent="center">
                      <Circle />
                    </Box>
                    <VStack>
                      <Text bold>
                        {item.firstName}&nbsp;{item.lastName}
                      </Text>
                      <Text fontSize="sm" color={secondaryColor}>
                        {item.email}
                      </Text>
                    </VStack>
                  </HStack>
                </ScrollView>

                <Pressable
                  onPress={() => {
                    setSelected(
                      selected.map((item) => {
                        return item.id === index
                          ? { ...item, value: !item.value }
                          : item;
                      })
                    );
                  }}
                  alignItems="flex-start"
                  justifyContent="center"
                  width={7}
                >
                  {isEditing && <Selector index={index} />}
                </Pressable>
              </HStack>
            ))}
        </VStack>
      </ScrollView>

      <Center mt={5} height={10}>
        {isEditing ? <BottomButtons /> : <InviteUsers />}
      </Center>
    </Box>
  );
}
