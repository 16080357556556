import React, { useEffect } from 'react';
import { useLinkTo, useRoute, RouteProp } from '@react-navigation/native';

import { SignUp } from '@/components/Auth/SignUp';
import { AuthStackParamList } from '@/navigation/AuthNavigator';

export function SignUpScreen() {
  const linkTo = useLinkTo();
  const { params } = useRoute<RouteProp<AuthStackParamList, 'SignUp'>>();

  // Redirect to sign in if no invite code provided
  useEffect(() => {
    if (!params?.inviteCode || !params?.email) {
      linkTo('/auth/signIn');
    }
  }, [params?.inviteCode]);

  function linkToSignIn() {
    linkTo('/auth/signIn');
  }

  return <SignUp inviteCode={params?.inviteCode} email={params?.email?.toLowerCase()} linkToSignIn={linkToSignIn} />;
}
