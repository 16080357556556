import React, { useEffect } from 'react';
import { ScrollView } from 'native-base';
import { registerPageView } from '@/analytics/navigation';
import { DashboardContainer } from '@/containers/Dashboard/DashboardContainer';
import { RefreshControl } from 'react-native'

export function DashboardScreen() {
const refetchFunctions: (() => Promise<any>)[] = [];

  useEffect(() => {
    registerPageView('dashboard');
  }, []);

  const refetch = () => {
    refetchFunctions.forEach((func) => {
      func()
    })
  }

  return (
    <ScrollView
      flexGrow={1}
      px={4}
      refreshControl={
        <RefreshControl
          refreshing={false}
          onRefresh={refetch}
        />
      }
      contentContainerStyle={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <DashboardContainer refetchFunctions={refetchFunctions} />
    </ScrollView >
  );
}
