import { MetaEvent } from "@/graphql/components"
import { Card } from '@/components/Alerts/Card'
import {
  VStack,
  Pressable,
  HStack,
  Text
} from "native-base"
import { DevicesIcon } from '@/components/CustomIcons';
import { useLinkTo } from '@react-navigation/native';
import { useTranslation } from "react-i18next"

interface Props {
  alert: MetaEvent
}

export const AlertSummery = ({ alert }: Props) => {
  const { t } = useTranslation()
  const linkTo = useLinkTo();

  return (
    <Card>
      <VStack space={3}>
        <Text bold fontSize="xl" testID="summary">
          {t('alertSummary')}
        </Text>
        <Text>{alert?.summary}</Text>
        {alert?.device_name && (
          <Pressable
            onPress={() => linkTo(`/devices/${alert?.device_id}`)}
          >
            <HStack space={3}>
              <DevicesIcon color={"#EA942F"} iconSize={6} />
              <Text fontSize="xl" color="orange.500" maxW="84%">
                {alert?.device_alias || alert?.device_name}
              </Text>
            </HStack>
          </Pressable>
        )}
        {alert?.cloud_instanceid && alert?.cloud_instanceid !== 'N/A' && (
          <HStack space={3}>
            <Text bold maxW="84%">
              {t('cloudInstanceId')}
            </Text>
            <Text maxW="84%">{alert?.cloud_instanceid}</Text>
          </HStack>
        )}
        {alert?.cloud_region && alert?.cloud_region !== 'N/A' && (
          <HStack space={3}>
            <Text bold maxW="84%">
              {t('cloudRegion')}
            </Text>
            <Text maxW="84%">{alert?.cloud_region}</Text>
          </HStack>
        )}
        {alert?.cloud_vendor && alert?.cloud_vendor !== 'N/A' && (
          <HStack space={3}>
            <Text bold maxW="84%">
              {t('cloudVender')}
            </Text>
            <Text maxW="84%">{alert?.cloud_vendor}</Text>
          </HStack>
        )}
      </VStack>
    </Card>
  )
}
