import React from 'react';
import {  Text, Select, VStack } from 'native-base';
import { CheckSimpleIcon } from '@/components/CustomIcons';
import {
  useThemeFontColor,
  useThemeBackgroundColor,
} from '@/hooks/useThemeColor';
import { metricMap } from '@/utils/Device/utils';
import { DeviceProps, DiskProps, NicProps } from '@/types/Device/types';

interface MetricCardProps {
  title: string;
  metric: DiskProps[] | NicProps[];
  mapping: DeviceProps;
  label: string;
}

const MetricCard = ({ title, metric, mapping, label }: MetricCardProps) => {
  const [selectedItem, setSelectedItem] = React.useState('0');

  const bgColorInput = useThemeBackgroundColor('input');
  const primary = useThemeFontColor('primary');

  const SelectMetric = () => {
    return (
      <Select
        selectedValue={selectedItem}
        accessibilityLabel="Choose"
        placeholder="Choose"
        _selectedItem={{
          bg: 'orange.1000',
          endIcon: <CheckSimpleIcon iconSize={5} color={primary} />,
        }}
        bg={bgColorInput}
        fontSize="md"
        p={1.5}
        onValueChange={(itemValue) => setSelectedItem(itemValue)}
      >
        {metric?.map((element: DeviceProps, index: number) => {
          const selector = element[label as keyof DeviceProps];
          if (selector) {
            return (
              <Select.Item
                key={index}
                label={selector.toString()}
                value={index.toString()}
              />
            );
          }
        })}
      </Select>
    );
  };

  const column = metricMap({
    metricMap: mapping,
    columnSpacing: '100%',
    metricInfo: metric[parseInt(selectedItem)],
  });

  return (
    <>
      <VStack w="50%" space={3}>
        <VStack space={3}>
          <Text bold fontSize={'xl'}>
            {title}
          </Text>
          <SelectMetric />
        </VStack>
        {column}
      </VStack>
    </>
  );
};

export default MetricCard;
