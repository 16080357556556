import React, { useEffect, useState } from 'react';
import { Platform, TextInput, TextInputProps } from 'react-native';
import {
  Button,
  Center,
  HStack,
  Text,
  useTheme,
  useColorMode,
} from 'native-base';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useThemeFontColor } from '@/hooks/useThemeColor';

declare type InputElement = React.ReactElement<TextInputProps>;

type WebAutocomplete =
  | 'email'
  | 'name-family'
  | 'name-given'
  | 'password'
  | 'password-new'
  | 'username';

type MobileAutocomplete =
  | 'cc-csc'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-number'
  | 'email'
  | 'name'
  | 'off'
  | 'password'
  | 'postal-code'
  | 'street-address'
  | 'tel'
  | 'username';

interface AutocompleteState {
  autoComplete?: WebAutocomplete;
  autoCompleteType?: MobileAutocomplete;
}

interface FormInputProps extends TextInputProps {
  autoComplete?: WebAutocomplete;
  autoCompleteType?: MobileAutocomplete;
  disabled?: boolean;
  id: string;
  label?: string;
  multiline?: boolean;
  type?: string;
}

export const FormInput = (props: FormInputProps): InputElement => {
  const {
    autoComplete,
    autoCompleteType,
    id,
    multiline,
    placeholder,
    secureTextEntry,
    type,
  } = props;

  const [completion, setCompletion] = useState<AutocompleteState>({});
  const [show, setShow] = React.useState(false);
  const [field, meta, helpers] = useField(id);

  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  const primaryFontColor = useThemeFontColor('primary');
  const secondary = useThemeFontColor('secondary');
  const isDark = colorMode === 'dark' ? true : false;
  const { t } = useTranslation();

  useEffect(() => {
    setCompletion(
      Platform.select({
        web: {
          autoComplete,
        },
        default: {
          autoCompleteType,
        },
      })
    );
  }, []);

  const isVisible = secureTextEntry
    ? secureTextEntry && show === false
      ? true
      : false
    : false;
  const shouldBeInvalid: boolean = meta.touched && meta.error ? true : false;
  const borderColor = shouldBeInvalid ? colors.red[500] : 'transparent';

  return (
    <>
      <Center>
        <HStack width="100%" alignItems="center">
          <TextInput
            {...completion}
            {...props}
            testID={id}
            secureTextEntry={isVisible}
            style={{
              backgroundColor: isDark
                ? colors.black['700']
                : colors.white['200'],
              borderBottomColor: borderColor,
              borderLeftColor: borderColor,
              borderRightColor: borderColor,
              borderTopColor: borderColor,
              borderRadius: 6,
              borderWidth: 1,
              color: primaryFontColor,
              flex: 1,
              minHeight: multiline ? 80 : 40,
              maxHeight: multiline ? 200 : 40,
              paddingHorizontal: 10,
              width: '100%',
            }}
            placeholder={placeholder}
            placeholderTextColor={secondary}
            value={meta.value}
            onChangeText={helpers.setValue}
            onBlur={() => {
              helpers.setTouched(true);
              field.onBlur(props.id);
            }}
            multiline={multiline}
          />
          {type === 'password' && (
            <Button
              ml={2}
              size="sm"
              onPress={() => setShow(!show)}
              colorScheme="orange"
              _text={{
                color: 'white.50',
              }}
              width={20}
              height="100%"
              isDisabled={meta.value === '' ? true : false}
              // startIcon={<BackIcon color="white" iconSize={6} />}
            >
              {show ? t('hide') : t('show')}
            </Button>
          )}
        </HStack>
      </Center>
      {meta.touched && meta.error && (
        <Text fontSize="sm" color="red.500">
          {meta.error}
        </Text>
      )}
    </>
  );
};
