import React from 'react';
import {
  Center,
  VStack,
} from 'native-base';
import AttackCard from '@/components/Alerts/AttackCard';
import { ActionCard } from '@/components/Alerts/ActionCard';
import { TimeLine } from '@/components/Alerts/TimeLine';
import { MetaEvent } from '@/graphql/components';
import { AlertTitle } from '@/components/Alerts/AlertTitle'
import { AlertSummery } from '@/components/Alerts/AlertSummery';
import { AlertFaererec } from '@/components/Alerts/AlertFaererec';

interface Props {
  alert: MetaEvent
}

export const AlertDetailsContainer = ({ alert }: Props) => {
  const parseData = (json: string | undefined) => {
    return json ? JSON.parse(json) : null
  }

  const parsedReport = parseData(alert?.event_object?.report)
  const parsedMisp = parseData(alert?.event_object?.misp);

  return (
    <Center>
      <VStack space={5} px={4} pb={5} width="100%" maxW="1000px">
        {alert?.title && <AlertTitle alert={alert} />}
        {alert?.summary && <AlertSummery alert={alert} />}
        {parsedReport?.details?.signatures.length &&
          <TimeLine
            reportSignatures={parsedReport?.details?.signatures}
          />
        }
        {alert?.event_object &&
          <ActionCard
            bouncerAlert={alert?.event_object}
          />
        }
        {parsedReport && Object.keys(parsedReport).length &&
          <AttackCard
            alert={alert}
            reportData={parsedReport}
            mispData={parsedMisp}
          />
        }
        {alert?.event_object?.faererec && <AlertFaererec alert={alert} />}
      </VStack>
    </Center>
  )
}
