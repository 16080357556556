import { Analytics } from 'aws-amplify';

export async function registerTenantCreation() {
  try {
    Analytics.record({
      name: 'tenantCreation',
    });
  } catch (e) {
    console.log(e);
  }
}
