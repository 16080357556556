import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Text } from 'native-base';
import { getLocalizedTime } from '@/utils/timezoneConvert';
import { IPIcon, ChevronRightIcon } from '@/components/CustomIcons';
import { Signature } from '@/types/Alerts/types';
import { useThemeFontColor } from '@/hooks/useThemeColor';

interface Props {
  reportSignatures: Signature[];
}

export function TimeLine({ reportSignatures }: Props) {
  const { t } = useTranslation();
  const [truncate, setTruncate] = React.useState(3);
  const secondaryColor = useThemeFontColor('secondary2');
  const circleSize = 3.5;

  const getFirstSession = (signature: Signature) => {
    return signature?.sessions?.[0];
  };

  const getTimeStamp = (signature: Signature) => {
    const timestamp = getFirstSession(signature)?.datetime
    if (timestamp) {
      return getLocalizedTime(timestamp)
    }
    return null
  }

  const desSort = (a: Signature, b: Signature) => {
    const dateTimeA = String(getFirstSession(a)?.datetime);
    const dateTimeB = String(getFirstSession(b)?.datetime);
    return new Date(dateTimeB).getTime() - new Date(dateTimeA).getTime();
  };

  
  reportSignatures.sort(desSort);
  return (
    <>
      {reportSignatures
        .slice(0, truncate)
        .map((signature: Signature, index: number) => (
          <HStack key={index} w="100%">
            <Box borderLeftColor="orange.500" borderLeftWidth={2} ml={3} />
            <Box
              bg="orange.500"
              width={circleSize}
              height={circleSize}
              borderRadius={30}
              position="absolute"
              top={2.5}
              left={1.5}
            />
            <Box pb={5} ml={5} mt={1} maxW="90%">
              <Text bold>
                {getTimeStamp(signature)}
              </Text>

              {/* gets the killchain of the session is present from the signature */}
              <Text>
                {t('alertAttack')} -{' '}
                {signature?.killchain
                  ? signature.killchain.charAt(0) +
                    signature.killchain
                      .slice(1)
                      .toLowerCase()
                      .replace('/_/g', ' ')
                  : t('alertNA')}
              </Text>
              <Text color={secondaryColor}>{signature?.name}</Text>
              {getFirstSession(signature)?.src_ip && (
                <HStack space={1}>
                  <IPIcon iconSize={5} color={secondaryColor} />
                  <Text color={secondaryColor}>
                    {getFirstSession(signature)?.src_ip}
                  </Text>
                  <ChevronRightIcon iconSize={4} color={secondaryColor} />
                  <IPIcon iconSize={5} color={secondaryColor} />
                  <Text color={secondaryColor}>
                    {getFirstSession(signature)?.dest_ip}
                  </Text>
                </HStack>
              )}
            </Box>
          </HStack>
        ))}
      {reportSignatures?.length > 3 && (
        <HStack>
          <Box borderLeftColor="orange.500" borderLeftWidth={2} ml={3} />
          <Text
            ml={5}
            color="orange.500"
            onPress={() => setTruncate(truncate === 3 ? 1000 : 3)}
            fontSize="sm"
          >
            {truncate === 3 ? t('showMore') : t('showLess')}
          </Text>
        </HStack>
      )}
    </>
  );
}
