import React from 'react';
import { Text, VStack } from 'native-base';
import { DeviceProps } from '@/types/Device/types';

interface MetricMap {
  metricMap?: DeviceProps;
  metricInfo?: DeviceProps;
  columnSpacing?: string;
}

const Metric = ({
  title,
  value,
}: {
  title: string | number | boolean;
  value: string | number | boolean;
}) => {
  return (
    <VStack w="100%">
      <Text bold>
        {title}
      </Text>
      <VStack pt={2} pl={2}>
        <Text>{value}</Text>
      </VStack>
    </VStack>
  );
};

export const metricMap = ({
  metricMap,
  metricInfo,
  columnSpacing,
}: MetricMap) => {
  const mappedMetrics =
    metricMap &&
    metricInfo &&
    Object.keys(metricMap).reduce((memo, element, index) => {
      const mapping = metricInfo[element as keyof DeviceProps];
      if (mapping && memo) {
        memo.push(
          <Metric
            key={index}
            title={metricMap[element as keyof DeviceProps]!}
            value={mapping!.toString()}
          />
        );
      }
      return memo;
    }, [] as React.ReactNode[]);
  return mappedMetrics?.length ? (
    <>
      <VStack w={columnSpacing || '50%'} space={3}>
        {mappedMetrics}
      </VStack>
    </>
  ) : null;
};
