import React from 'react';
import {
  Center,
  HStack,
  Pressable,
  Select,
} from 'native-base';

import { CheckSimpleIcon,  XIcon } from '@/components/CustomIcons';
import {
  useThemeFontColor,
  useThemeBackgroundColor,
} from '@/hooks/useThemeColor';

export const SeenUnseenFilter = ({
  localSeenNotSeen,
  setLocalSeenNotSeen,
  t,
}: {
  localSeenNotSeen: string;
  setLocalSeenNotSeen: React.Dispatch<React.SetStateAction<string>>;
  t: any;
}) => {
  return (
    <HStack space={5}>
      <Select
        selectedValue={localSeenNotSeen}
        flex={1}
        accessibilityLabel="seen not seen"
        placeholder={t('alertModalSeen')}
        _selectedItem={{
          bg: 'orange.500',
          endIcon: <CheckSimpleIcon iconSize={5} color={useThemeFontColor('primary')} />,
        }}
        fontSize="md"
        onValueChange={(itemValue) => setLocalSeenNotSeen(itemValue)}
        bg={useThemeBackgroundColor('input')}
        borderWidth={0}
        p={1.5}
      >
        <Select.Item label="seen" value="seen" />
        <Select.Item label="not seen" value="notSeen" />
        <Select.Item label="none" value="none" />
      </Select>
      {localSeenNotSeen !== '' && localSeenNotSeen !== 'none' && (
        <Center w={4}>
          <Pressable onPress={() => setLocalSeenNotSeen('none')}>
            <XIcon iconSize={3} color={useThemeFontColor('primary')} />
          </Pressable>
        </Center>
      )}
    </HStack>
  );
};
