import React from 'react'
import { useTranslation } from "react-i18next"
import { MetaEvent } from "@/graphql/components"
import { Card } from "@/components/Alerts/Card"
import {
  HStack,
  Text,
  Button,
} from "native-base"
import { ParseHTML, copyToClipboard } from "@/utils/Alert/utils"
import { AlertContext } from "@/context/AlertContext"
import { Faererec, Section } from '@/components/Alerts/Faererec'

interface Props {
  alert: MetaEvent
}

export const AlertFaererec = ({ alert }: Props) => {
  const CallAlert = React.useContext(AlertContext);

  let sortedFaererec: Section[] | null = null;

  const faererecString = alert?.event_object?.faererec_en;
  if (faererecString) {
    try {
      const parsedFaererec = JSON.parse(faererecString);
      if (typeof parsedFaererec === 'object' && parsedFaererec !== null) {
        const sectionsArray: Section[] = Object.values(parsedFaererec).map(section => section as Section);
        sortedFaererec = sectionsArray.sort((a, b) => a.display_order - b.display_order);
      }
    } catch (error) {
      console.error("Error parsing faererec JSON: ", error);
    }
  }

  const { t } = useTranslation();
  return (
    <Card>
      <HStack
        justifyContent="space-between"
        w="100%"
        flexWrap="wrap"
        mb={5}
      >
        <Text bold fontSize="xl">
          {t('alertRecommendation')}
        </Text>
        <Button
          size="sm"
          colorScheme="orange"
          _text={{ color: "white.50" }}
          onPress={() => copyToClipboard(alert?.event_object?.faererec || '', CallAlert, t)}
        >
          {t('alertCopy')}
        </Button>
      </HStack>
      {sortedFaererec ?
        <Faererec faererec={sortedFaererec} /> :
        <ParseHTML string={alert?.event_object?.faererec || ""} />
      }
    </Card>
  );
};
