import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Center,
  Pressable,
  PresenceTransition,
  Text,
  VStack,
} from 'native-base';
import { Formik } from 'formik';
import { useLinkTo } from '@react-navigation/native';
import * as Yup from 'yup';
import * as Clipboard from 'expo-clipboard';

import {
  CtsSite,
  useCreateDeviceMutation,
  useGetCurrentUserQuery,
} from '@/graphql/components';

import InitKeyCard from '@/components/Device/InitKeyCard';
import { AlertContext } from '@/context/AlertContext';
import { DeviceEnrollInput } from '@/components/Forms/DeviceEnrollInput';
import { submitOnEnter } from '@/utils/form';
import { useThemeBackgroundColor } from '@/hooks/useThemeColor';

interface Props {
  deviceNames: string[];
}

export function DeviceEnrollContainer({ deviceNames }: Props) {
  const { t } = useTranslation();
  const linkTo = useLinkTo();
  const bgCardColor = useThemeBackgroundColor('card');

  const CallAlert = React.useContext(AlertContext);

  const [showStepTwo, setShowStepTwo] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [uniqueId, setUniqueID] = useState('');
  const [newDeviceId, setNewDeviceId] = useState('');

  const EnrollmentSchema = Yup.object().shape({
    deviceName: Yup.string()
      .required(t('required'))
      .min(3, t('deviceEnrollMin'))
      .max(128, t('deviceEnrollMax'))
      .notOneOf(deviceNames, t('deviceEnrollDuplicate')),
  });

  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  });

  const currentTenantId = data?.getCurrentUser?.tenant?.id ?? '';

  const [enrollTenantDevice] = useCreateDeviceMutation({
    onCompleted: () => {
      CallAlert && CallAlert(t('deviceEnrollSuccess'), 'success');
    },
    onError: () => {
      CallAlert && CallAlert(t('deviceEnrollError'), 'error');
    },
  });

  const copyToClipboard = (code: string) => {
    try {
      Clipboard.setStringAsync(code);

      CallAlert && CallAlert(t('deviceEnrollClipboard'), 'success');
    } catch (error) {
      console.log(error);
      CallAlert && CallAlert(t('deviceEnrollError'), 'error');
    }
  };

  const animDuration = 300;

  return (
    <Formik
      initialValues={{
        deviceName: '',
      }}
      validationSchema={EnrollmentSchema}
      validateOnBlur
      onSubmit={async ({ deviceName }) => {
        const newDevice = await enrollTenantDevice({
          variables: {
            input: {
              alias: deviceName,
              enabled: true,
              name: deviceName,
              site: CtsSite.Eu1,
              templateId: '7fb1fed8-e1dd-11ea-80a9-00155d824b81',
              tenantId: currentTenantId,
              // enrollmentId: uniqueID,
            },
          },
        });

        setNewDeviceId(newDevice?.data?.createDeviceFromApp?.id ?? '');
        setUniqueID(newDevice?.data?.createDeviceFromApp?.init_key ?? '');
      }}
    >
      {(props) => {
        return (
          <>
            <VStack maxW={'1000px'} alignItems="center">
              <VStack
                borderRadius={10}
                bg={bgCardColor}
                p={6}
                mb={4}
                space={3}
              >
                <Center mb={2.5}>
                  <Text bold fontSize="xl">
                    {t('deviceEnrollHeader')}
                  </Text>
                </Center>

                {/* step 1 */}
                {!showStepTwo && (
                  <PresenceTransition
                    visible={!showStepTwo}
                    initial={{
                      opacity: 0,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: {
                        duration: animDuration,
                      },
                    }}
                  >
                    <Text>
                      {t('deviceEnrollMessage', { cloudProvider: 'AWS' })}
                    </Text>
                  </PresenceTransition>
                )}

                {!showStepTwo && (
                  <PresenceTransition
                    visible={!showStepTwo}
                    initial={{
                      opacity: 0,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: {
                        duration: animDuration,
                      },
                    }}
                  >
                    <DeviceEnrollInput
                      id="deviceName"
                      label="device name"
                      placeholder={t('deviceEnrollName')}
                      onKeyPress={submitOnEnter(() => {
                        // setUniqueID(UUID.generate().toUpperCase());
                        setShowStepTwo(true);
                        props.submitForm();
                      })}
                    />
                  </PresenceTransition>
                )}

                {!showStepTwo && (
                  <PresenceTransition
                    visible={!showStepTwo}
                    initial={{
                      opacity: 0,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: {
                        duration: animDuration,
                      },
                    }}
                  >
                    <Button
                      testID="next"
                      isDisabled={
                        props.isSubmitting ||
                        props.values.deviceName === '' ||
                        !props.isValid
                      }
                      isLoading={props.isSubmitting}
                      // onPress={() => props.submitForm()}
                      onPress={() => {
                        // setUniqueID(UUID.generate().toUpperCase());
                        setShowStepTwo(true);
                        props.submitForm();
                      }}
                      colorScheme="orange"
                      _text={{
                        color: 'white.50',
                        fontSize: 'xl',
                      }}
                    >
                      {t('deviceEnrollDevice')}
                    </Button>
                  </PresenceTransition>
                )}

                {/* step 2 */}
                {showStepTwo && (
                  <PresenceTransition
                    visible={showStepTwo}
                    initial={{
                      opacity: 0,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: {
                        duration: animDuration,
                      },
                    }}
                  >
                    <Text>
                      {t('deviceEnrollPaste', { cloudProvider: 'AWS' })}
                    </Text>
                  </PresenceTransition>
                )}

                {/* {uniqueId !== '' && ( */}
                {true && (
                  <PresenceTransition
                    visible={uniqueId !== ''}
                    initial={{
                      opacity: 0,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      transition: {
                        duration: animDuration,
                      },
                    }}
                  >
                    <Pressable
                      onPress={() => {
                        setHasCopied(true);
                        copyToClipboard(uniqueId);
                      }}
                    >
                      <InitKeyCard initKey={uniqueId} hasCopied={hasCopied} />
                    </Pressable>
                  </PresenceTransition>
                )}
              </VStack>
            </VStack>

            {showStepTwo && (
              <Box w="100%" alignItems="center">
                <PresenceTransition
                  visible={showStepTwo}
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      duration: animDuration,
                    },
                  }}
                  style={{
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    // testID="next"
                    isDisabled={!hasCopied}
                    isLoading={props.isSubmitting}
                    onPress={() => {
                      newDeviceId !== '' && linkTo(`/devices/${newDeviceId}`);
                    }}
                    colorScheme="orange"
                    _text={{
                      color: 'white.50',
                      fontSize: 'xl',
                    }}
                    width="100%"
                  >
                    {t('deviceEnrollView')}
                  </Button>
                </PresenceTransition>
              </Box>
            )}
          </>
        );
      }}
    </Formik>
  );
}
