import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { KeyboardAvoidingView, Text } from 'native-base';

import { NetworkStatus } from '@apollo/client';
import {
  useListOrgInvitesQuery,
  useGetCurrentUserQuery,
  useSendOrgInviteEmailMutation,
  ListOrgInvitesDocument,
  ListOrgInvitesQuery,
} from '@/graphql/components';

import { AlertContext } from '@/context/AlertContext';
import { CustomBox } from '@/components/CustomBox';
import { Error } from '@/components/Error';
import { OrgInvitesContainer } from '@/containers/Settings/OrgInvitesContainer';
import { registerPageView } from '@/analytics/navigation';
import { useThemeBackgroundColor } from '@/hooks/useThemeColor';
import { useTranslation } from 'react-i18next';

export function OrgInvitesScreen(): React.ReactElement {
  const { t } = useTranslation();
  const bgColor = useThemeBackgroundColor('screen');
  const user = useGetCurrentUserQuery();

  const CallAlert = React.useContext(AlertContext);

  const [fetchingMore, setFetchingMore] = React.useState<boolean>(false);
  useEffect(() => {
    registerPageView('orgInvites');
  }, []);

  const orgId = user?.data?.getCurrentUser?.tenant?.id || '';

  const { data, error, fetchMore, networkStatus, refetch } =
    useListOrgInvitesQuery({
      variables: { orgId },
      notifyOnNetworkStatusChange: true,
    });

  const [sendOrgInviteEmail, { loading: sending }] =
    useSendOrgInviteEmailMutation({
      onCompleted: () => {
        CallAlert && CallAlert('Invite sent successfully', 'success');
      },
      onError: () => {
        CallAlert && CallAlert('Failed to send invite', 'error');
      },
      update(cache, { data }) {
        if (!data?.sendOrgInviteEmail) {
          return;
        }
        const prev =
          cache.readQuery<ListOrgInvitesQuery>({
            query: ListOrgInvitesDocument,
            variables: { orgId },
          })?.listOrgInvites ?? {};

        const items = new Set();
        const emails = [data.sendOrgInviteEmail, ...(prev.items ?? [])].filter(
          (entry) => {
            if (items.has(entry.email)) {
              return false;
            }
            items.add(entry.email);
            return true;
          }
        );
        cache.writeQuery({
          query: ListOrgInvitesDocument,
          data: {
            listOrgInvites: {
              ...prev,
              items: emails,
            },
          },
          variables: { orgId },
        });
      },
    });

  const fetchMoreInvites = () => {
    setFetchingMore(true);
    const nextToken = data?.listOrgInvites?.nextToken;

    if (!nextToken) {
      setFetchingMore(false);
      return;
    }

    fetchMore({
      variables: {
        nextToken,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        const prevItems = prev?.listOrgInvites?.items ?? [];
        const nextItems = fetchMoreResult?.listOrgInvites?.items ?? [];
        const nextToken = fetchMoreResult?.listOrgInvites?.nextToken ?? null;
        const items = prevItems.concat(nextItems);

        return {
          ...prev,
          listOrgInvites: {
            ...prev.listOrgInvites,
            items,
            nextToken,
          },
        };
      },
    });
    setFetchingMore(false);
  };

  const sendInvite = (email: string) => {
    sendOrgInviteEmail({
      variables: {
        orgId,
        email,
      },
    });
  };

  if (
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.refetch
  ) {
    return (
      <CustomBox type="slim">
        <Text>Loading...</Text>
      </CustomBox>
    );
  }

  if (error) {
    return <Error text={t('errorInvites')} retry={() => refetch()} />;
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1, backgroundColor: bgColor }}
    >
      <OrgInvitesContainer
        hasMore={!!data?.listOrgInvites?.nextToken}
        fetchMore={fetchMoreInvites}
        // refetch={refetch}
        // fetchingMore={networkStatus === NetworkStatus.fetchMore}
        fetchingMore={fetchingMore}
        invites={data?.listOrgInvites?.items ?? []}
        sending={sending}
        sendInvite={sendInvite}
      />
    </KeyboardAvoidingView>
  );
}
