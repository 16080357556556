import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl } from 'react-native';
import { ScrollView, Center, Spinner } from 'native-base';
import { AlertDetailsContainer } from '@/containers/Alerts/AlertDetailsContainer';
import { Error } from '@/components/Error';
import { registerPageView } from '@/analytics/navigation';
import { gql, useQuery } from '@apollo/client'
import { useRoute, RouteProp } from '@react-navigation/native';
import { AlertsStackParamList } from '@/navigation/AlertNavigator';

const GET_TENANT_METAEVENT_QUERY = gql`
  query GetTenantMetaEvent($getTenantMetaEventId: ID!) {
    getTenantMetaEvent(id: $getTenantMetaEventId) {
      PK
      title
      event_id
      device_name
      summary
      device_id
      device_alias
      cloud_vendor
      cloud_region
      cloud_instanceid
      event_object {
        action
        src_ip_uniq_enriched
        src_port_uniq
        action
        dest_port_uniq
        dest_host_uniq_enriched
        report
        mitreCategory
        misp
        faererec
        faererec_en
      }
    }
  }
`
export function AlertDetailsScreen() {

  const { t } = useTranslation();
  const { params } =
    useRoute<RouteProp<AlertsStackParamList, 'AlertDetails'>>();

  const { data, loading, error, refetch } = useQuery(GET_TENANT_METAEVENT_QUERY, {
    variables: {
      getTenantMetaEventId: params.id
    }
  })

  useEffect(() => {
    registerPageView('alertDetails');
  }, []);

  if (loading) {
    return (
      <Center flex={1}>
        <Spinner color="orange.500" size="lg" />
      </Center>
    );
  }

  if (error) {
    return <Error text={t('alertDetailError')} retry={() => refetch()} />;
  }

  return (
    data?.getTenantMetaEvent ?
      <ScrollView
        flexGrow={1}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={refetch}
          />
        }
      >
        <AlertDetailsContainer alert={data?.getTenantMetaEvent} />
      </ScrollView >
      : <Error text={t('dashboardNoData')} retry={() => refetch()} />
  );
}
