import React from 'react';
import { useLinkTo, useRoute, RouteProp } from '@react-navigation/native';

import { ForgotPassword } from '@/components/Auth/ForgotPassword';

import { AuthStackParamList } from '@/navigation/AuthNavigator';

export function ForgotPasswordScreen() {
  const linkTo = useLinkTo();

  const { params } =
    useRoute<RouteProp<AuthStackParamList, 'ForgotPassword'>>();

  function linkToSignIn() {
    linkTo('/auth/signIn');
  }

  return <ForgotPassword email={params?.email} linkToSignIn={linkToSignIn} />;
}
