import React from 'react';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { HomeNavigator, HomeLinkingConfig } from './HomeNavigator';
import { AuthNavigator, AuthLinkingConfig } from '@/navigation/AuthNavigator';
import { InitialParamsProvider } from '@/hooks/useInitialParams';
import { RouterProps } from '@/types/navigation/types';
import { useThemeBackgroundColor } from '@/hooks/useThemeColor';

const linking = {
  prefixes: ['https://localhost:19006', 'expo://'],
  config: {
    screens: {
      Auth: AuthLinkingConfig,
      Home: HomeLinkingConfig,
    },
  },
};

//TODO: figure out the correct type here
interface Props extends React.PropsWithChildren<RouterProps> {
  ref: React.Ref<any>;
}

const Stack = createStackNavigator();

function NavContainer({ user }: Props) {
  const bgColor = useThemeBackgroundColor('screen');

  const navigatorTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: bgColor,
    },
  };

  return  (
    <InitialParamsProvider>
      <NavigationContainer linking={linking} theme={navigatorTheme}>
        <Stack.Navigator headerMode="none">
          {user ? (
            <Stack.Screen name="Home" component={HomeNavigator} />
          ) : (
            <Stack.Screen name="Auth" component={AuthNavigator} />
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </InitialParamsProvider>
  );
}

export default React.forwardRef(function ContainerWrapper(p: RouterProps, r) {
  return <NavContainer {...p} ref={r} />;
});
