import React from 'react';
import {
  Button,
  HStack,
  Modal,
  Pressable,
  useTheme,
  VStack,
} from 'native-base';

import { FiltersIcon, } from '@/components/CustomIcons';
import { TableCtsMetaEventsFilterInput } from '@/graphql/components';
import {
  useThemeFontColor,
  useThemeBackgroundColor,
} from '@/hooks/useThemeColor';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { DeviceFilter } from '@/components/Alerts/DeviceFilter'
import { SeenUnseenFilter } from '@/components/Alerts/SeenUnseenFilter'

const DEVICEFILTERLIST = gql`
  query DeviceFilterList {
    listTenantDevices {
      items {
        id
        name
        alias
      }
    }
  }
`;

type FilterVariables = {
  filter?: TableCtsMetaEventsFilterInput | null;
  nextToken?: string | null;
  limit?: number | null;
};

type Props = {
  userId: string;
  currentFilterVariables?: FilterVariables;
  setFilters: React.Dispatch<React.SetStateAction<TableCtsMetaEventsFilterInput>>;
};

export function AlertListFiltersContainer({
  setFilters,
  userId,
  currentFilterVariables,
}: Props) {
  const { data, loading } = useQuery(DEVICEFILTERLIST);
  const [showModal, setShowModal] = React.useState(false);

  const [localDevices, setLocalDevices] = React.useState<string[]>([]);
  const [localSeenNotSeen, setLocalSeenNotSeen] = React.useState<string>('none');

  const { t } = useTranslation();

  React.useEffect(() => {
    const filterDevices = currentFilterVariables?.filter?.device_id?.in ?? [];
    const filterSeen = currentFilterVariables?.filter?.seen_by?.contains
      ? 'seen'
      : currentFilterVariables?.filter?.seen_by?.notContains
        ? 'notSeen'
        : 'none';

    const typedFilterDevices: string[] = filterDevices as string[];

    setLocalDevices(typedFilterDevices);
    setLocalSeenNotSeen(filterSeen);
  }, [currentFilterVariables]);

  const submitFilters = () => {
    const newFilter = { ...currentFilterVariables?.filter } || {};
    handleSeenFilters(newFilter);
    handleDeviceFilters(newFilter);
    setFilters(newFilter);
    setShowModal(false);
  };

  const handleDeviceFilters = (filter: TableCtsMetaEventsFilterInput) => {
    if (localDevices.length > 0) {
      filter.device_id = { in: localDevices };
    } else {
      delete filter.device_id;
    }
  };

  const handleSeenFilters = (filter: TableCtsMetaEventsFilterInput) => {
    switch (localSeenNotSeen) {
      case 'seen':
        filter.seen_by = { contains: userId };
        break;
      case 'notSeen':
        filter.seen_by = { notContains: userId };
        break;
      default:
        delete filter.seen_by;
    }
  };

  const resetAll = () => {
    setLocalDevices([]);
    setLocalSeenNotSeen('none');
  };

  return (
    <>
      <Pressable onPress={() => setShowModal(true)}>
        <FiltersIcon iconSize={7} color={useTheme().colors.orange[500]} />
      </Pressable>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        backgroundColor="rgba(0, 0, 0, 0.40)"
      >
        <Modal.Content maxWidth="400px" bg={useThemeBackgroundColor('card')}>
          <Modal.CloseButton />
          <Modal.Body p={5} mt={8}>
            {!loading ? (
              <VStack space={2}>
                <DeviceFilter
                  localDevices={localDevices}
                  setLocalDevices={setLocalDevices}
                  data={data}
                  t={t}
                />
                <SeenUnseenFilter
                  localSeenNotSeen={localSeenNotSeen}
                  setLocalSeenNotSeen={setLocalSeenNotSeen}
                  t={t}
                />
              </VStack>
            ) : null}
          </Modal.Body>
          <Modal.Footer bg={useThemeBackgroundColor('card')} justifyContent="center">
            <HStack space={5}>
              <Button variant="ghost" colorScheme="orange" onPress={resetAll}>
                {t('alertModalClear')}
              </Button>
              <Button
                color={useThemeFontColor('primary')}
                colorScheme="orange"
                onPress={submitFilters}
                px={6}
              >
                {t('alertModalApply')}
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
