import { gql } from '@apollo/client'

export const CURRENT_USER_ID_QUERY = gql`
  query CurrentUserQuery {
    getCurrentUser {
      id
    }
  }
`

export const ALERT_LIST_QUERY = gql`
  query AlertListQuery(
    $limit: Int
    $filter: TableCtsMetaEventsFilterInput 
    $nextToken: String
    ) {
    listTenantMetaEvents(limit: $limit, filter: $filter, nextToken: $nextToken) {
      items {
        event_id
        SK
        seen_by
        timestamp
        title
        device_alias
        device_id
      }
      nextToken
    } 
  }
`
