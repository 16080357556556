import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

const REQUIRED_TEXT = 'required';

const emailField = () =>
  Yup.string().email('invalid email').required(REQUIRED_TEXT);
const requiredField = () => Yup.string().required(REQUIRED_TEXT);
const passwordField = () =>
  requiredField()
    .min(
      8,
      'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
    )
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minNumbers(1, 'password must contain at least 1 number')
    .minSymbols(1, 'password must contain at least 1 special character');

const passwordConfirmField = () =>
  requiredField().test(
    'password-match',
    'passwords do not match',
    function (value) {
      return this.parent.password === value;
    }
  );

export const ForgotPasswordSchema = Yup.object().shape({
  email: emailField(),
});

export const ResetPasswordSchema = Yup.object().shape({
  password: passwordField(),
  passwordConfirm: passwordConfirmField(),
});

export const SignInSchema = Yup.object().shape({
  email: emailField(),
  password: requiredField(),
});

export const SignUpSchema = Yup.object().shape({
  firstName: requiredField(),
  lastName: requiredField(),
  email: emailField(),
  password: passwordField(),
  passwordConfirm: passwordConfirmField(),
});

export const CreateTenantSchema = Yup.object().shape({
  firstName: requiredField(),
  lastName: requiredField(),
  email: emailField(),
  password: passwordField(),
  passwordConfirm: passwordConfirmField(),
  tenantName: requiredField(),
});

export const VerifyEmailSchema = Yup.object().shape({
  code: requiredField(),
});
