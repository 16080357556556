import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Text, VStack } from 'native-base';
import { Card } from '@/components/Alerts/Card'

import { ParseHTML } from '@/utils/Alert/utils';
import { useCTSDimensions } from '@/hooks/useCTSDimensions';
import { BouncerAlert } from '@/graphql/components';

interface Props {
  bouncerAlert: BouncerAlert
}

export function ActionCard({ bouncerAlert }: Props) {
  const { isDesktopWeb } = useCTSDimensions();
  const { t } = useTranslation();
  const titleWidth = '40%';
  const descWidth = '60%';

  const capitalize = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const renderActionCard = () => {
    if (isDesktopWeb) {
      return (
        <HStack space={3}>
          <VStack w="50%" space={3}>
            {bouncerAlert?.action && bouncerAlert?.action !== 'N/A' && (
              <HStack w="100%">
                <Text bold w={titleWidth}>
                  {t('alertAction')}
                </Text>
                <VStack w={descWidth}>
                  <Text>{capitalize(bouncerAlert?.action)}</Text>
                </VStack>
              </HStack>
            )}

            {bouncerAlert?.src_port_uniq && bouncerAlert?.src_port_uniq !== 'N/A' && (
              <HStack w="100%">
                <Text bold w={titleWidth}>
                  {t('alertSourcePort')}
                </Text>
                <Text w={descWidth}>
                  {bouncerAlert?.src_port_uniq?.replace(new RegExp(',', 'g'), ', ') ?? ''}
                </Text>
              </HStack>
            )}

            {bouncerAlert?.dest_port_uniq && bouncerAlert?.dest_port_uniq !== 'N/A' && (
              <HStack w="100%">
                <Text bold w={titleWidth}>
                  {t('alertDestinationPort')}
                </Text>
                <Text w={descWidth}>
                  {bouncerAlert?.dest_port_uniq.replace(new RegExp(',', 'g'), ', ') ?? ''}
                </Text>
              </HStack>
            )}
          </VStack>

          <VStack w="100%" space={3}>
            {bouncerAlert?.src_ip_uniq_enriched && bouncerAlert?.src_ip_uniq_enriched !== 'N/A' && (
              <HStack w="50%">
                <Text bold w={titleWidth}>
                  {t('alertSource')}
                </Text>
                <Text w={descWidth}>{bouncerAlert?.src_ip_uniq_enriched ?? ''}</Text>
              </HStack>
            )}

            {bouncerAlert?.dest_host_uniq_enriched && bouncerAlert?.dest_host_uniq_enriched !== 'N/A' && (
              <HStack w="50%">
                <Text bold w={titleWidth}>
                  {t('alertDestination')}
                </Text>
                <VStack w={descWidth} space={3}>
                  <ParseHTML string={bouncerAlert?.dest_host_uniq_enriched ?? ''} />
                </VStack>
              </HStack>
            )}
          </VStack>
        </HStack>
      );
    } else {
      return (
        <VStack space={2}>
          {bouncerAlert?.action && bouncerAlert?.action !== 'N/A' && (
            <HStack alignItems="flex-start" w="95%">
              <Text bold w={titleWidth}>
                {t('alertAction')}
              </Text>
              <Text maxW={descWidth}>{capitalize(bouncerAlert?.action)}</Text>
            </HStack>
          )}

          {bouncerAlert?.mitreCategory &&
            bouncerAlert?.mitreCategory !== 'N/A' &&
            bouncerAlert?.mitreCategory !== 'Unspecified' && (
              <HStack alignItems="flex-start" w="95%">
                <Text bold w={titleWidth}>
                  {t('alertAttack')}
                </Text>
                <Text maxW={descWidth}>{capitalize(bouncerAlert?. mitreCategory)}</Text>
              </HStack>
            )}

          {bouncerAlert?.src_ip_uniq_enriched && bouncerAlert?.src_ip_uniq_enriched !== 'N/A' && (
            <HStack alignItems="flex-start" w="95%">
              <Text bold w={titleWidth}>
                {t('alertSource')}
              </Text>
              <Text maxW={descWidth}>{bouncerAlert?.src_ip_uniq_enriched ?? ''}</Text>
            </HStack>
          )}

          {bouncerAlert?.src_port_uniq && bouncerAlert?.src_port_uniq !== 'N/A' && (
            <HStack alignItems="flex-start" w="95%">
              <Text bold w={titleWidth}>
                {t('alertSourcePort')}
              </Text>
              <Text maxW={descWidth}>
                {bouncerAlert?.src_port_uniq.replace(new RegExp(',', 'g'), ', ') ?? ''}
              </Text>
            </HStack>
          )}

          {bouncerAlert?.dest_host_uniq_enriched &&
            bouncerAlert?.dest_host_uniq_enriched !== 'N/A' &&
            (bouncerAlert?.dest_host_uniq_enriched.length > 25 ? (
              <VStack space={2}>
                <Text bold w={titleWidth}>
                  {t('alertDestination')}
                </Text>
                <ParseHTML string={bouncerAlert?.dest_host_uniq_enriched} />
              </VStack>
            ) : (
              <HStack alignItems="flex-start" w="95%">
                <Text bold w={titleWidth}>
                  {t('alertDestination')}
                </Text>
                <ParseHTML string={bouncerAlert?.dest_host_uniq_enriched} />
              </HStack>
            ))}

          {bouncerAlert?.dest_port_uniq && bouncerAlert?.dest_port_uniq !== 'N/A' && (
            <HStack alignItems="flex-start" w="95%">
              <Text bold w={titleWidth}>
                {t('alertDestinationPort')}
              </Text>
              <Text maxW={descWidth}>
                {bouncerAlert?.dest_port_uniq.replace(new RegExp(',', 'g'), ', ') ?? ''}
              </Text>
            </HStack>
          )}
        </VStack>
      );
    }
  }

  return (
    <Card>
      {renderActionCard()}
    </Card>
  )
}
