import React from 'react';
import {
  Alert as NBAlert,
  VStack,
  HStack,
  IconButton,
  CloseIcon,
  Text,
  Center,
} from 'native-base';

import { useCTSDimensions } from '../hooks/useCTSDimensions';

export type AlertStatusProps = 'success' | 'error' | 'warning' | 'info' | null;
interface AlertProps {
  title: string;
  description?: string;
  status: AlertStatusProps;
}

interface Props {
  alert: AlertProps;
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Alert({ alert, showAlert, setShowAlert }: Props) {
  const { isDesktopWeb } = useCTSDimensions();

  if (showAlert === true) {
    return (
      <Center
        alignItems="center"
        bgColor="transparent"
        justifyContent="space-between"
        left={0}
        p={1}
        position="absolute"
        top={0}
        zIndex={100}
        w="100%"
      >
        <NBAlert
          colorScheme={alert.status ? alert.status : 'success'}
          flex={1}
          margin={0}
          maxW={isDesktopWeb ? '1000px' : '100%'}
          padding={1}
          status={alert.status ? alert.status : 'success'}
        >
          <VStack
            alignSelf="center"
            alignItems="baseline"
            justifyContent="space-between"
            w="100%"
          >
            <HStack
              alignItems="baseline"
              justifyContent="space-between"
              space={2}
            >
              <Text fontSize="md" fontWeight="medium" color="black">
                {alert.title}
              </Text>
              <IconButton
                variant="unstyled"
                _focus={{
                  borderWidth: 0,
                }}
                icon={<CloseIcon size="2" />}
                _icon={{
                  color: 'black',
                }}
                onPress={() => setShowAlert(false)}
              />
            </HStack>
            {alert.description && (
              <Text color="black" mt={2}>
                {alert.description}
              </Text>
            )}
          </VStack>
        </NBAlert>
      </Center>
    );
  }
  return null;
}
