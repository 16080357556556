import { Platform } from 'react-native';
import * as Device from 'expo-device';

//gets iphone model id, parses out different iphone 10 models, returns a number

export function useIPhoneModel() {
  if (Platform.OS === 'ios') {
    const device = Device.modelName;
    const splitDevice = device?.split(' ')[1] || '';
    const isTen =
      splitDevice === 'X' || splitDevice === 'XS' || splitDevice === 'XR'
        ? true
        : false;
    return isTen ? 10 : parseInt(splitDevice);
  }
  return null;
}
