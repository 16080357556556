// import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: 'AIzaSyBbvEE7BC6hcHw7-dKV1IUpMgk_yBBLV4k',
  authDomain: 'cts-tenant-app-local.firebaseapp.com',
  databaseURL: 'https://cts-tenant-app-local.firebaseio.com',
  projectId: 'cts-tenant-app-local',
  storageBucket: 'cts-tenant-app-local.appspot.com',
  messagingSenderId: '321933729040',
  appId: '1:321933729040:web:fd2cb3d0cf08866e3a1995',
};

const firebase = initializeApp(firebaseConfig);

export default async function initFirebase(): Promise<
  string | null | undefined
> {
  try {
    await import('firebase/messaging'); // lazy load firebase/messaging import to address the idbindex error in native apps
    //@ts-ignore
    const messaging = firebase.messaging();

    const token = await messaging.getToken({
      vapidKey:
        'BFuVnnB4Ml_LKK5giGfcyVsL0Grmf7NxGkHGRQWvJYnaIDgkruaSivkMcNmJdHKWN9R183dTTA5At5aStdANROo',
    });

    if (token) {
      messaging.onMessage((payload: any) => {
        console.log('Message received. ', payload);
        // ...
      });

      console.log('FCM TOKEN: ', token);

      return token;
    } else {
      console.log('No token');
      return null;
    }
  } catch (e) {
    console.log(e);
  }
}
