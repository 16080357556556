import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Center,
  HStack,
  Modal,
  Pressable,
  Select,
  Text,
  useTheme,
  VStack,
} from 'native-base';

import { CheckSimpleIcon, FiltersIcon, XIcon } from '@/components/CustomIcons';
import {
  useThemeFontColor,
  useThemeBackgroundColor,
} from '@/hooks/useThemeColor';

type Props = {
  handleSubmitFilters: (
    marketplace: string,
    localStatusFilter: string,
    statusLocalOrder: string
  ) => void;
  statusOrder: string | null;
};

export function DeviceListFilterModal({
  handleSubmitFilters,
  statusOrder,
}: Props) {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();
  const [localMarketplace, setLocalMarketplace] = React.useState<string>('');
  const [localStatusFilter, setlocalStatusFilter] = React.useState<string>('');
  const [statusLocalOrder, setLocalStatusLocalOrder] =
    React.useState<string>('');

  const bgColor = useThemeBackgroundColor('card');
  const bgColorInput = useThemeBackgroundColor('input');
  const primary = useThemeFontColor('primary');
  const { colors } = useTheme();

  React.useEffect(() => {
    statusOrder && setLocalStatusLocalOrder(statusOrder);
  }, []);

  const resetAll = () => {
    setLocalMarketplace('');
    setlocalStatusFilter('');
    setLocalStatusLocalOrder('');
  };

  const resetField = (field: string) => {
    switch (field) {
      case 'localStatusFilter':
        return setlocalStatusFilter('');
      case 'statusLocalOrder':
        return setLocalStatusLocalOrder('');
      default:
        //marketplace
        setLocalMarketplace('');
    }
  };

  const submitFilters = () => {
    handleSubmitFilters(localMarketplace, localStatusFilter, statusLocalOrder);
    setShowModal(false);
  };

  return (
    <>
      <Pressable onPress={() => setShowModal(true)}>
        <FiltersIcon iconSize={7} color={colors.orange[500]} />
      </Pressable>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        backgroundColor="rgba(0, 0, 0, 0.40)"
      >
        <Modal.Content maxWidth="400px" bg={bgColor}>
          <Modal.CloseButton />
          <Modal.Body p={5} mt={8}>
            <VStack space={2}>
              {/* <Text fontSize="lg">filter by:</Text>

              <HStack space={5}>
                <Select
                  selectedValue={localMarketplace}
                  flex={1}
                  accessibilityLabel="filter by marketplace"
                  placeholder="marketplace"
                  _selectedItem={{
                    bg: 'orange.500',
                    endIcon: <CheckSimpleIcon iconSize={5} color={primary} />,
                  }}
                  fontSize="md"
                  // placeholderTextColor=''
                  onValueChange={(itemValue) => setLocalMarketplace(itemValue)}
                  bg={bgColorInput}
                  borderWidth={0}
                  p={1.5}
                >
                  <Select.Item label="AWS" value="aws" />
                  <Select.Item label="Azure" value="azure" />
                </Select>
                {localMarketplace !== '' && (
                  <Center w={4}>
                    <Pressable onPress={() => resetField('marketplace')}>
                      <XIcon iconSize={3} color={primary} />
                    </Pressable>
                  </Center>
                )}
              </HStack>

              <HStack space={5}>
                <Select
                  selectedValue={localStatusFilter}
                  flex={1}
                  accessibilityLabel="filter by status"
                  placeholder="status"
                  _selectedItem={{
                    bg: 'orange.500',
                    endIcon: <CheckSimpleIcon iconSize={5} color={primary} />,
                  }}
                  fontSize="md"
                  // placeholderTextColor=''
                  onValueChange={(itemValue) => setlocalStatusFilter(itemValue)}
                  bg={bgColorInput}
                  borderWidth={0}
                  p={1.5}
                >
                  <Select.Item label="online" value="online" />
                  <Select.Item label="offline" value="offline" />
                </Select>
                {localStatusFilter !== '' && (
                  <Center w={4}>
                    <Pressable onPress={() => resetField('localStatusFilter')}>
                      <XIcon iconSize={3} color={primary} />
                    </Pressable>
                  </Center>
                )}
              </HStack> */}

              <Text fontSize="lg">{t('orderBy')}</Text>

              <HStack space={5}>
                <Select
                  selectedValue={statusLocalOrder}
                  flex={1}
                  accessibilityLabel="order by status"
                  placeholder={t('online') + ' / ' + t('offline')}
                  _selectedItem={{
                    bg: 'orange.500',
                    endIcon: <CheckSimpleIcon iconSize={5} color={primary} />,
                  }}
                  fontSize="md"
                  // placeholderTextColor=''
                  onValueChange={(itemValue) =>
                    setLocalStatusLocalOrder(itemValue)
                  }
                  bg={bgColorInput}
                  borderWidth={0}
                  p={1.5}
                >
                  <Select.Item label={t('online')} value="online" />
                  <Select.Item label={t('offline')} value="offline" />
                </Select>
                {statusLocalOrder !== '' && (
                  <Center w={4}>
                    <Pressable onPress={() => resetField('statusLocalOrder')}>
                      <XIcon iconSize={3} color={primary} />
                    </Pressable>
                  </Center>
                )}
              </HStack>
            </VStack>
          </Modal.Body>

          <Modal.Footer bg={bgColor} justifyContent="center">
            <HStack space={5}>
              <Button
                disabled={
                  localMarketplace !== '' ||
                  localStatusFilter !== '' ||
                  statusLocalOrder !== ''
                    ? false
                    : true
                }
                variant="ghost"
                colorScheme="orange"
                onPress={resetAll}
              >
                clear all filters
              </Button>

              <Button
                color={primary}
                colorScheme="orange"
                onPress={submitFilters}
              >
                apply filters
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
