import React from 'react';
import { Platform } from 'react-native'
import { Box, HStack, VStack } from 'native-base';
import { useDimensions } from '@react-native-community/hooks';
import { ThreatChartContainer } from '@/containers/Dashboard/ThreatChartContainer';
import { AlertContainer } from '@/containers/Dashboard/AlertContainer';
import { EventsFunnelContainer } from '@/containers/Dashboard/EventsFunnelContainer';
import { DeviceMessage } from '@/components/Dashboard/DeviceMessage';
import { WelcomeMessage } from '@/components/Dashboard/WelcomeMessage';


interface DashboardContainerProps {
  refetchFunctions: (() => Promise<any>)[];
}

export const DashboardContainer: React.FC<DashboardContainerProps> = ({ refetchFunctions }) => {
  const dimensions = useDimensions();

  const renderMobileLayout = () => (
    <VStack space={5} width="100%" pb={5}>
      <WelcomeMessage />
      <AlertContainer refetchFunctions={refetchFunctions} />
      <DeviceMessage refetchFunctions={refetchFunctions} />
      <EventsFunnelContainer refetchFunctions={refetchFunctions} />
      <ThreatChartContainer refetchFunctions={refetchFunctions} />
    </VStack>
  );

  const renderDesktopLayout = () => (
    <VStack space={8} width="100%" maxW="1000px">
      <WelcomeMessage />
      <HStack space={8}>
        <AlertContainer refetchFunctions={refetchFunctions} />
        <DeviceMessage refetchFunctions={refetchFunctions} />
      </HStack>
      <HStack space={8}>
        <Box flex={2}>
          <EventsFunnelContainer refetchFunctions={refetchFunctions} />
        </Box>
        <Box flex={1}>
          <ThreatChartContainer refetchFunctions={refetchFunctions} />
        </Box>
      </HStack>
    </VStack>
  );

  return Platform.OS !== "web" || dimensions.window.width < 900
    ? renderMobileLayout()
    : renderDesktopLayout();
}
