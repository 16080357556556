import moment from 'moment-timezone'

export const getLocalizedTime = (timestamp: string) => {
  let deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!deviceTimeZone || !moment.tz.zone(deviceTimeZone)) {
    console.warn("Invalid or unsupported timezone detected. Falling back to UTC.");
    deviceTimeZone = 'UTC';
  }
  const localTime = moment.utc(timestamp).tz(deviceTimeZone)
  return localTime.format('MMMM DD YYYY @ HH:mm:ss z')
}

