import React, { useEffect } from 'react';
import { Center, Spinner, ScrollView } from 'native-base';

import { registerPageView } from '@/analytics/navigation';

import { useGetCurrentUserQuery } from '@/graphql/components';

import { GeneralSettingsContainer } from '@/containers/Settings/GeneralSettingsContainer';

export function GeneralSettingsScreen(): React.ReactElement {
  useEffect(() => {
    registerPageView('settingsNotificaitons');
  }, []);


  const { data, loading } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  const currentUser = data?.getCurrentUser;

  if (loading) {
    return (
      <Center flex={1}>
        <Spinner color="orange.500" size="lg" />
      </Center>
    );
  }

  return (
    <ScrollView
      flexGrow={1}
      contentContainerStyle={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <GeneralSettingsContainer currentUser={currentUser} />
    </ScrollView>
  );
}
