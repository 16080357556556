import { extendTheme } from 'native-base';

const customTheme = {
  colors: {
    white: {
      50: '#fff',
      100: '#F3F4F4',
      200: '#E8E9E9',
      300: '#D6D7D7',
    },
    black: {
      900: '#000',
      800: '#252525',
      700: '#393939',
      600: '#474747',
    },
    successGreen: '#6FDC2D',
    orange: {
      50: '#FBE8D2',
      100: '#F8D8B4',
      200: '#F3C188',
      300: '#F0B26A',
      400: '#EDA24C',
      500: '#EA942F',
      600: '#D47D19',
      700: '#C6610E',
      800: '#B25407',
      900: '#964502',
    },
    danger: {
      400: '#EB4D33',
      500: '#DB3D23',
      600: '#CF3117',
    },
    onlineGreen: {
      500: '#84C15D',
      700: '#4E693E',
    },
    offlineRed: {
      500: '#DB6B6B',
      700: '#7E3C3C',
    },
  },
  config: {
    initialColorMode: 'dark',
  },
  components: {},
  fontConfig: {
    Sarabun: {
      100: {
        normal: 'Sarabun_100Thin',
        italic: 'Sarabun_100Thin_Italic',
      },
      200: {
        normal: 'Sarabun_200ExtraLight',
        italic: 'Sarabun_200ExtraLight_Italic',
      },
      300: {
        normal: 'Sarabun_300Light',
        italic: 'Sarabun_300Light_Italic',
      },
      400: {
        normal: 'Sarabun_400Regular',
        italic: 'Sarabun_400Regular_Italic',
      },
      500: {
        normal: 'Sarabun_500Medium',
        italic: 'Sarabun_500Medium_Italic',
      },
      600: {
        normal: 'Sarabun_600SemiBold',
        italic: 'Sarabun_600SemiBold_Italic',
      },
      700: {
        normal: 'Sarabun_700Bold',
        italic: 'Sarabun_700Bold_Italic',
      },
      800: {
        normal: 'Sarabun_800ExtraBold',
        italic: 'Sarabun_800ExtraBold_Italic',
      },
    },
  },
  fonts: {
    heading: 'Sarabun',
    body: 'Sarabun',
    mono: 'Sarabun',
  },
};

export const theme = extendTheme(customTheme);
