import React from 'react';
import { Keyboard, Platform } from 'react-native';
import { Box, HStack, VStack } from 'native-base';

import {
  useListTenantMetaEventsQuery,
  useGetCurrentUserQuery,
} from '@/graphql/components';

import { BottomTab } from '@/components/navigation/BottomTab';
import { LeftTab } from '@/components/navigation/LeftTab';

export default function Container({ children }: { children: React.ReactNode }) {

  const { data } = useListTenantMetaEventsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        event_name: { eq: 'bounceralert' },
      },
      limit: 30,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const { data: userData } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  });

  const alerts = data?.listTenantMetaEvents?.items ?? [];
  const currentUser = userData?.getCurrentUser;
  const userId = currentUser?.id ?? '';
  const username = currentUser?.firstName ?? '';

  React.useEffect(() => {
    Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
    Keyboard.addListener('keyboardDidHide', _keyboardDidHide);

    // cleanup function
    return () => {
      Keyboard.removeAllListeners('keyboardDidShow');
      Keyboard.removeAllListeners('keyboardDidHide');
    };
  }, [Keyboard]);

  const [isKeyboardOpen, setIsKeyboardOpen] = React.useState<boolean>(false);

  const _keyboardDidShow = () => setIsKeyboardOpen(true);
  const _keyboardDidHide = () => setIsKeyboardOpen(false);

  const calculateUnseen = () => {
    let count = 0;

    alerts?.map((item) => {
      return item?.seen_by && !item.seen_by.includes(userId)
        ? (count += 1)
        : null;
    });
    return count;
  };

  const unseen = calculateUnseen();

  const navigation = () => {
    if (Platform.OS == "web") {
      return (
        <HStack >
          <LeftTab unseen={unseen} username={username} />
          <Box flex={1}>{children}</Box>
        </HStack>
      )
    } else {
      return (
        <VStack safeArea flex={1}>
          <Box flex={1}>{children}</Box>
          {!isKeyboardOpen && <BottomTab unseen={unseen} />
          }
        </VStack>
      )
    }
  }
  return navigation()
}
