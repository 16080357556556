import React from 'react';
import { useTranslation } from 'react-i18next';
import { VStack, HStack } from 'native-base';
import Card from '@/components/Device/Card';
import { HardwareProps, DiskProps, NicProps } from '@/types/Device/types';
import MetricCard from './MetricCard';

interface DeviceHardware {
  deviceDisksNics: HardwareProps;
}

const DeviceDisksNicsCard = ({ deviceDisksNics }: DeviceHardware) => {
  const { t } = useTranslation();

  const diskMap: DiskProps = {
    fileSystem: t('deviceFileSystem'),
    mountPoint: t('deviceMountPoint'),
    size: t('deviceSize'),
    usage: t('deviceUsage'),
  };

  const nicMap: NicProps = {
    isUp: t('deviceIsUp'),
    name: t('deviceName'),
    sniffer: t('deviceSniffer'),
    speed: t('deviceSpeed'),
  };

  return (
    <>
      <Card>
        <VStack space={3}>
          <HStack space={3}>
            {deviceDisksNics?.disks && (
              <MetricCard
                title={t('deviceDisks')}
                metric={deviceDisksNics?.disks}
                mapping={diskMap}
                label="mountPoint"
              />
            )}
            {deviceDisksNics?.nics && (
              <MetricCard
                title={t('deviceNics')}
                metric={deviceDisksNics?.nics}
                mapping={nicMap}
                label="name"
              />
            )}
          </HStack>
        </VStack>
      </Card>
    </>
  );
};

export default DeviceDisksNicsCard;
