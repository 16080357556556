import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import {
  Box,
  Button,
  HStack,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  VStack,
} from 'native-base';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { TenantInviteDetailsFragment } from '@/graphql/components';

import { FormInput } from '@/components/Forms/form-input.component';
import { RefreshIcon } from '@/components/CustomIcons';
import { submitOnEnter } from '@/utils/form';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '@/hooks/useThemeColor';

interface Props {
  invites: TenantInviteDetailsFragment[];
  hasMore: boolean;
  fetchingMore?: boolean;
  fetchMore: () => void;
  // refetch: () => void;
  sending: boolean;
  sendInvite: (email: string) => void;
}

export function OrgInvitesContainer({
  invites,
  hasMore,
  fetchingMore,
  fetchMore,
  // refetch,
  sending,
  sendInvite,
}: Props) {
  const [pendingItem, setPendingItem] = React.useState<number | string | null>(
    null
  );
  const isWeb = Platform.OS === 'web' ? true : false;
  const bgCardColor = useThemeBackgroundColor('card');
  const secondary = useThemeFontColor('secondary');
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    email: Yup.string().email(t('emailInvalid')).required(t('required')),
  });

  return (
    <VStack
      space={3}
      px={4}
      width="100%"
      maxW="550px"
      height="100%"
      justifyContent="center"
      mx="auto"
    >
      <Box borderRadius={20} bg={bgCardColor} p={5}>
        <VStack space={4}>
          <HStack justifyContent="space-between">
            <Box width={10} />
            {invites.length === 0 ? (
              <Text color={secondary}>{t('orgNoPending')}</Text>
            ) : (
              <Text color={secondary}>{t('orgPending')}</Text>
            )}
            {fetchingMore ? (
              <Box width={10}>
                <Spinner color="orange.500" size="sm" />
              </Box>
            ) : hasMore ? (
              <Pressable
                onPress={() => {
                  // refetch();
                  fetchMore();
                }}
                testID="load more"
              >
                <Box width={10}>
                  <RefreshIcon iconSize={5} color="white.50" />
                </Box>
              </Pressable>
            ) : (
              <Box width={10} />
            )}
          </HStack>

          {invites.length > 0 && (
            <ScrollView
              px={2}
              maxHeight={180}
              nestedScrollEnabled={true}
              //scrollIndicatorInsets fixes weird scroll indicator behaviour in iOS
              scrollIndicatorInsets={{ right: 1 }}
            >
              <VStack space={3}>
                {invites.map((item, index) => {
                  const truncateLength = !isWeb ? 25 : 150;
                  const parsedEmail =
                    item.email.length > truncateLength
                      ? item.email.slice(0, truncateLength) + '...'
                      : item.email;
                  return (
                    <HStack
                      key={index}
                      justifyContent="space-between"
                      testID="invite-list-item"
                    >
                      <Text flexWrap="wrap">{parsedEmail}</Text>
                      {sending && pendingItem === index ? (
                        <Text color={secondary}>sending</Text>
                      ) : (
                        <Text
                          onPress={() => {
                            setPendingItem(index);
                            sendInvite(item.email);
                          }}
                          color="orange.500"
                        >
                          {t('resend')}
                        </Text>
                      )}
                    </HStack>
                  );
                })}
              </VStack>
            </ScrollView>
          )}
        </VStack>
      </Box>

      <Formik
        initialValues={{ email: '' }}
        validationSchema={schema}
        onSubmit={async ({ email }) => {
          sendInvite(email.toLowerCase());
        }}
      >
        {(props) => (
          <Box borderRadius={20} bg={bgCardColor} p={5}>
            <VStack space={5}>
              <FormInput
                id="email"
                placeholder={t('orgInviteEmail')}
                keyboardType="email-address"
                autoComplete="username"
                autoCompleteType="email"
                autoCapitalize="none"
                onKeyPress={submitOnEnter(props.submitForm)}
                disabled={false}
              />
              <Button
                colorScheme="orange"
                isDisabled={sending && pendingItem === 'button'}
                onPress={() => {
                  setPendingItem('button');
                  props.submitForm();
                }}
                testID="send invite"
                _text={{
                  color: 'white.50',
                }}
              >
                {sending && pendingItem === 'button'
                  ? t('sending')
                  : t('sendInvite')}
              </Button>
            </VStack>
          </Box>
        )}
      </Formik>
    </VStack>
  );
}
