export type TimeUnit = 'hour' | 'day' | 'week' | 'month';

interface TimeFrameOption {
  label: string;
  range: string;
  step: string;
  offset: number;
  timeUnit: string;
}

export const timeFrameOptions: TimeFrameOption[] = [
  {
    label: '1h',
    range: '1h',
    offset: 3600,
    //1h - fetches for every 2sec
    step: '120',
    timeUnit: 'hour',
  },
  {
    label: '1d',
    range: '1d',
    offset: 86400,
    //1d - fetches for every 30min
    step: '1800',
    timeUnit: 'day',
  },
  {
    label: '1w',
    range: '7d',
    offset: 604800,
    //1w - fetches for every 3h
    step: '10800',
    timeUnit: 'week',
  },
  {
    label: '1m',
    range: '30d',
    offset: 2629743,
    //1m - fetches for every half day
    step: '43200',
    timeUnit: 'month',
  },
  // {
  //   label: '6m',
  //   range: '181d',
  //   offset: 15778463,
  //   //6m - fetches for every 2 days
  //   step: '172800',
  //   timeUnit: 'month',
  // },
];

export const getMetaEventCountDates = (offset: number = 2629743) => {
  // Get the current date and time
  const now = new Date();

  // Add one day's worth of milliseconds to "now" 
  now.setTime(now.getTime() + 24 * 60 * 60 * 1000);

  // Set the time to UTC midnight
  now.setUTCHours(0, 0, 0, 0);

  // Get the date for "30 days ago"
  const thirtyDaysAgo = new Date(now.getTime() - offset * 1000);
 
  // Convert both dates to ISO format
  const awsDateNow = now.toISOString();
  const awsDateThirtyDaysAgo = thirtyDaysAgo.toISOString();

  return { dateTo: awsDateNow, dateFrom: awsDateThirtyDaysAgo };
};
