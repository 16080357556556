import React from 'react';
import { Box, Skeleton, VStack, Text } from 'native-base';
import { useLinkTo } from '@react-navigation/native';
import { useDimensions } from '@react-native-community/hooks';

import { DeviceDetailsFragment } from '@/graphql/components';

import { useCTSDimensions } from '@/hooks/useCTSDimensions';
import { useThemeBackgroundColor } from '@/hooks/useThemeColor';
import { useTranslation } from 'react-i18next';
import { DeviceCard } from '@/components/shared/DeviceCard';
import { useListTenantDevicesBasicsQuery } from '@/graphql/components';

interface DeviceMessageProps {
  refetchFunctions: (() => Promise<any>)[];
}

export const DeviceMessage: React.FC<DeviceMessageProps> = ({ refetchFunctions }) => {
  const { data, loading, refetch } = useListTenantDevicesBasicsQuery({
    // fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    refetchFunctions.push(refetch) 
  },[])

  const devices = data?.listTenantDevices?.items || [];

  const linkTo = useLinkTo();
  const { t } = useTranslation();

  const { isDesktopWeb } = useCTSDimensions();
  const dimensions = useDimensions();

  const bgColor = useThemeBackgroundColor('card');

  const offline = devices?.reduce((memo: any, element) => {
    if (element.status?.state === 'offline') {
      memo.push(element);
    }
    return memo;
  }, []);

  const offlineDevices = offline.length ? offline.length : 0;
  const useOfflineDeviceIfPresent = offlineDevices ? offline : devices;

  const textSize = ['xl', '2xl'];

  const Message = () => {
    return (
      <Text
        flex={1}
        flexWrap="wrap"
        fontSize={textSize}
        maxW="100%"
        // overflowWrap="normal"
      >
        {offlineDevices
          ? t('dashboardOfflineDevices')
          : t('dashboardAllOnline')}
        &nbsp;
      </Text>
    );
  };

  const Content = () => {
    if (devices && devices.length === 0)
      return (
        <VStack justifyContent="space-between" height="100%" width="100%">
          <Text fontSize={['lg', '2xl']}>{t('dashboardNoDevice')}</Text>

          <Text
            fontSize={['lg', '2xl']}
            color="orange.500"
            onPress={() => linkTo('/devices/enroll?screen=DeviceEnroll')}
          >
            {t('dashboardAddDevice')}
          </Text>
        </VStack>
      );

    return (
      <VStack space={7} width="100%">
        <Message />
        {useOfflineDeviceIfPresent
          ?.slice(0, 2)
          .map((device: DeviceDetailsFragment, index: number) => (
            <DeviceCard
              key={index}
              item={device}
              selectedDevice={device.id} //Passing in the device id to style the dashboard
            />
          ))}
      </VStack>
    );
  };

  if (loading) {
    return <Skeleton flex={1} minH="300px" borderRadius={10} />;
  }

  if (!isDesktopWeb || dimensions.window.width < 900)
    return (
      <Box
        alignItems="flex-start"
        bg={bgColor}
        borderRadius={10}
        justifyContent="flex-start"
        p={5}
      >
        <Content />
      </Box>
    );

  return (
    <Box
      alignItems="flex-start"
      bg={bgColor}
      borderRadius={10}
      flex={1}
      justifyContent="flex-start"
      p={5}
    >
      <Content />
    </Box>
  );
}
