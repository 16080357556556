import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import { Button } from 'native-base';

import { AlertContext } from '@/context/AlertContext';
import { AuthForm } from '@/components/Forms/AuthForm';
import { FormInput } from '@/components/Forms/form-input.component';
import { ResetPasswordSchema } from '@/components/Forms/validationSchemas';
import { submitOnEnter } from '@/utils/form';
import { useTranslation } from 'react-i18next';

interface Props {
  email: string;
  resetCode: string;
  linkToSignIn: () => void;
  linkToForgotPassword: () => void;
}

export function ResetPassword({
  email,
  resetCode,
  linkToSignIn,
  linkToForgotPassword,
}: Props) {
  const [formError, setFormError] = useState('');
  const [showRequest, setShowRequest] = useState(false);
  const { t } = useTranslation();

  const CallAlert = React.useContext(AlertContext);

  function handleSuccess() {
    CallAlert && CallAlert(t('passwordResetSuccess'), 'success');

    linkToSignIn();
  }

  function handleError(e: any) {
    switch (e.code) { 
      case 'ExpiredCodeException':
        setFormError(t('resetCodeExpired'));
        setShowRequest(true);
        break;
      default:
        setFormError(t('errorDefault'));
    }
  }

  return (
    <Formik
      initialValues={{ password: '', passwordConfirm: '' }}
      validationSchema={ResetPasswordSchema}
      validateOnBlur
      onSubmit={async (values, actions) => {
        try {
          await Auth.forgotPasswordSubmit(email.toLowerCase(), resetCode, values.password);

          handleSuccess();
        } catch (e) {
          handleError(e);
          actions.setSubmitting(false);
        }
      }}
    >
      {(props) => (
        <AuthForm error={formError}>
          {!showRequest && (
            <FormInput
              id="password"
              placeholder={t('passwordNew')}
              type="password"
              secureTextEntry
              autoCapitalize="none"
              autoComplete="password-new"
              autoCompleteType="password"
              textContentType="newPassword"
            />
          )}
          {!showRequest && (
            <FormInput
              id="passwordConfirm"
              placeholder={t('passwordConfirm')}
              type="password"
              secureTextEntry
              autoCapitalize="none"
              autoComplete="password-new"
              autoCompleteType="password"
              textContentType="newPassword"
              onKeyPress={submitOnEnter(props.submitForm)}
            />
          )}
          {showRequest ? (
            <Button
              onPress={linkToForgotPassword}
              colorScheme="orange"
              _text={{
                color: 'white.50',
              }}
              testID="Resend Reset Link"
            >
              {t('resetCodeResend')}
            </Button>
          ) : (
            <Button
              onPress={props.submitForm}
              colorScheme="orange"
              _text={{
                color: 'white.50',
              }}
              testID="Reset Password"
            >
              {t('passwordReset')}
            </Button>
          )}
          <Button
            disabled={props.isSubmitting}
            style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
            variant="link"
            // onPress={linkToSignIn}
            _text={{
              color: 'orange.500',
            }}
            testID="Sign In"
          >
            {t('signIn')}
          </Button>
        </AuthForm>
      )}
    </Formik>
  );
}
