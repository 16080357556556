import React, { createContext } from 'react';

import Alert, { AlertStatusProps } from '@/components/Alert';

interface AlertProps {
  title: string;
  description?: string;
  status: AlertStatusProps;
}

type CallAlertProps = (
  title: string,
  status: AlertStatusProps,
  description?: string
) => void;

export const AlertContext = createContext<CallAlertProps | null>(null);

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alert, setAlert] = React.useState<AlertProps>({
    title: '',
    description: '',
    status: null,
  });

  const [showAlert, setShowAlert] = React.useState(false);

  const CallAlert: CallAlertProps = (
    title: string,
    status: AlertStatusProps,
    description?: string
  ) => {
    setAlert({
      title: title,
      description: description ? description : '',
      status: status,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  return (
    <AlertContext.Provider value={CallAlert}>
      <Alert alert={alert} showAlert={showAlert} setShowAlert={setShowAlert} />
      {children}
    </AlertContext.Provider>
  );
};
