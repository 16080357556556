import React from 'react';
import { useLinkTo, useRoute, RouteProp } from '@react-navigation/native';

import { VerifyEmail } from '@/components/Auth/VerifyEmail';
import { AuthStackParamList } from '@/navigation/AuthNavigator';

export function VerifyEmailScreen() {
  const linkTo = useLinkTo();

  const { params } = useRoute<RouteProp<AuthStackParamList, 'VerifyEmail'>>();

  function linkToSignIn() {
    linkTo('/auth/signIn');
  }

  if (!params?.email) {
    linkToSignIn();
    return null;
  }

  return (
    <VerifyEmail
      code={params.verificationCode}
      email={params.email}
      linkToSignIn={linkToSignIn}
    />
  );
}
