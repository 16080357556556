import React from 'react';
import { Image, Dimensions } from 'react-native';
import { Box, Center, Text, useColorMode } from 'native-base';

export function AuthLogo() {
  const { height, width } = Dimensions.get('window');
  const { colorMode } = useColorMode();

  const isWeb = width > height ? true : false;
  const isDark = colorMode === 'dark';

  const logo = isDark ? require('@/assets/cts-logo-dark.png') : require('@/assets/cyber-logo.png')
  const logoWidth = isWeb ? 400 : 230;

  return (
    <Center>
      <Box
        alignItems="center"
        justifyContent="center"
        maxHeight={200}
        h={200}
        w={logoWidth}
      >
        <Image
          onError={({ nativeEvent: { error } }) => console.log(error)}
          style={{ width: '100%', height: '100%' }}
          resizeMethod="scale"
          resizeMode="contain"
          source={logo}
        />
      </Box>
      <Text paddingBottom={3} fontSize="2xl">Cyber Threat Sensor</Text>
    </Center>
  );
}
