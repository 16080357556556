import React, { useEffect } from 'react';
import { Platform, StatusBar } from 'react-native';
import { NativeBaseProvider, ColorMode } from 'native-base';
import type { StorageManager } from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { theme } from './custom-theme';

export function StylesProvider({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  const isiPhone = Platform.OS === 'ios' ? true : false;

  const colorModeManager: StorageManager = {
    get: async () => {
      try {
        const val = await AsyncStorage.getItem('@color-mode');
        return val === 'dark' ? 'dark' : 'light';
      } catch (e) {
        return 'light';
      }
    },
    set: async (value: ColorMode) => {
      const stringValue: string = value ?? '';

      try {
        await AsyncStorage.setItem('@color-mode', stringValue);
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    colorModeManager.get().then((current) => {
      if (isiPhone) {
        StatusBar.setBarStyle(
          current === 'dark' ? 'light-content' : 'dark-content',
          false
        );
      }

      if (current === null) {
        colorModeManager.set('dark');
      }
    });
  }, []);

  return (
    <NativeBaseProvider theme={theme} colorModeManager={colorModeManager}>
      {/* Your App Goes Here */}
      {children}
    </NativeBaseProvider>
  );
}
