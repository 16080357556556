import React from 'react';
import { HStack, Switch, Text } from 'native-base';

import { useField } from 'formik';

interface Props {
  accessibilityLabel?: string;
  disabled?: boolean;
  id: string;
  name: string;
  testID?: string;
}

export const FormToggle = ({ id, disabled, name, ...inputProps }: Props) => {
  const [field, meta, helpers] = useField(id);

  return (
    <HStack
      {...inputProps}
      alignSelf="center"
      width="100%"
      my={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Text bold>{name}</Text>

      <Switch
        size="sm"
        offTrackColor="black.600"
        colorScheme="orange"
        name={field.name}
        onToggle={() => helpers.setValue(!meta.value)}
        isDisabled={disabled}
        isChecked={meta.value}
      />
    </HStack>
  );
};
