import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { Button, Modal, useTheme } from 'native-base';

import {
  useDeleteTenantUserMutation,
  CurrentUserFragment,
} from '@/graphql/components';

import { AlertContext } from '@/context/AlertContext';
import { AlertIcon } from '@/components/CustomIcons';
import { useAppSync } from '@/hooks/useAppSync';

interface Props {
  currentUser?: CurrentUserFragment;
}

export function DeleteAccount({ currentUser }: Props) {
  const { t } = useTranslation();
  const { clients } = useAppSync();
  const [showModal, setShowModal] = useState(false);
  const { colors } = useTheme();

  const CallAlert = React.useContext(AlertContext);

  const userId = currentUser?.id || '';

  const [deleteTenantUser] = useDeleteTenantUserMutation({
    onCompleted: async () => {
      Object.values(clients).forEach((client) => {
        client.resetStore();
      });
      CallAlert && CallAlert(t('deleteAccountSuccess'), 'success');
      Auth.signOut();
    },
    onError: () => {
      CallAlert && CallAlert(t('deleteAccountError'), 'error');
    },
  });

  async function confirmDeleteUser() {
    deleteTenantUser({
      variables: {
        userId: userId,
      },
    });
  }

  return (
    <>
      <Button
        colorScheme="danger"
        _text={{
          fontSize: 'xl',
        }}
        endIcon={<AlertIcon iconSize={5} color={colors.white['50']} />}
        onPress={() => setShowModal(true)}
      >
        {t('deleteAccount')}
      </Button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Body>{t('deleteAreYouSure')}</Modal.Body>
          <Modal.Footer>
            <Button.Group variant="ghost" space={2}>
              <Button onPress={() => setShowModal(false)}>{t('cancel')}</Button>
              <Button
                onPress={() => {
                  setShowModal(false);
                  confirmDeleteUser();
                }}
              >
                {t('deleteAccount')}
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
