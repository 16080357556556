import 'regenerator-runtime/runtime';
import React from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
// import { LogBox } from 'react-native';
import 'whatwg-fetch';
import { NotifierWrapper } from 'react-native-notifier';
import * as SplashScreen from 'expo-splash-screen';

import { withOAuth } from 'aws-amplify-react-native';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { enableScreens } from 'react-native-screens';

import { AppSyncProvider } from './context/appsync';
import Navigation from './navigation/Navigator';
import { StatusBar } from './components/status-bar.component';
import { StylesProvider } from './app/SylesProvider';
import { useAppFonts as useFonts } from './hooks/useFonts';
import initApp from './init';
import './i18n/config';

interface WithOAuthProps {
  oAuthUser?: CognitoUser;
  loading: boolean;
}

initApp();

function AppContainer(props: WithOAuthProps) {
  const { oAuthUser: user, loading } = props;

  const fontsLoaded = useFonts();

  enableScreens();

  const [appIsReady, setAppIsReady] = React.useState(false);

  React.useEffect(() => {
    // LogBox.ignoreLogs(['EventEmitter.removeListener', 'AsyncStorage']); // Ignore log notification by message
    // LogBox.ignoreLogs(['Animated', `useNativeDriver`]);

    if (!fontsLoaded) {
      setAppIsReady(true);
    }
  }, [fontsLoaded]);

  const onLayoutRootView = React.useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <StylesProvider>
        <NotifierWrapper>
          <StatusBar />
          {loading ? null : (
            <AppSyncProvider>
              <Navigation user={user} />
            </AppSyncProvider>
          )}
        </NotifierWrapper>
      </StylesProvider>
    </GestureHandlerRootView>
  );
}

// export default AppContainer
export default withOAuth((props: WithOAuthProps) => {
  return <AppContainer {...props} />;
});
