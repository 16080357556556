import React from 'react';
import { TextInput, TextInputProps } from 'react-native';
import { Center, HStack, Text, useTheme, useColorMode } from 'native-base';
import { useField } from 'formik';

import { useThemeFontColor } from '@/hooks/useThemeColor';

declare type InputElement = React.ReactElement<TextInputProps>;

interface FormInputProps extends TextInputProps {
  id: string;
  label?: string;
  disabled?: boolean;
}

export const DeviceEnrollInput = (props: FormInputProps): InputElement => {
  const { id, placeholder } = props;

  const [field, meta, helpers] = useField(id);

  const { colors } = useTheme();
  const { colorMode } = useColorMode();
  const primaryFontColor = useThemeFontColor('primary');
  const secondary = useThemeFontColor('secondary');
  const isDark = colorMode === 'dark' ? true : false;

  const shouldBeInvalid: boolean = meta.error ? true : false;
  const borderColor = shouldBeInvalid ? colors.red[500] : 'transparent';

  return (
    <>
      <Center>
        <HStack width="100%" alignItems="center">
          <TextInput
            {...props}
            testID={id}
            style={{
              backgroundColor: isDark
                ? colors.black['700']
                : colors.white['200'],
              borderBottomColor: borderColor,
              borderLeftColor: borderColor,
              borderRightColor: borderColor,
              borderTopColor: borderColor,
              borderRadius: 6,
              borderWidth: 1,
              color: primaryFontColor,
              flex: 1,
              height: 40,
              paddingHorizontal: 10,
              width: '100%',
            }}
            placeholder={placeholder}
            placeholderTextColor={secondary}
            value={meta.value}
            onChangeText={helpers.setValue}
            onBlur={() => {
              helpers.setTouched(true);
              field.onBlur(props.id);
            }}
          />
        </HStack>
      </Center>
      {meta.error && (
        <Text fontSize="sm" color="red.500">
          {meta.error}
        </Text>
      )}
    </>
  );
};
