import AsyncStorage from '@react-native-async-storage/async-storage';
export const localStorage = {
  get: async (key: string) => {
    try {
      return await AsyncStorage.getItem(key);
    } catch (e) {
      console.log(e);
      return '';
    }
  },
  set: async (key: string, value: string) => {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  },
};
