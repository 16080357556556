import React from 'react';
import { Box, Skeleton, Text, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';

export function WelcomeMessage() {
  const WELCOMEMESSAGE = gql`
    query WelcomeMessage {
      getCurrentUser {
        firstName
      }
    }
  `;
  const { data, loading } = useQuery(WELCOMEMESSAGE);
  const { t } = useTranslation();

  if (loading)
    return (
      <VStack mt={[10, 25]} overflow="hidden" rounded="lg" space={8}>
        <Skeleton h={6} w={64} />
        <Skeleton h={6} w={56} />
      </VStack>
    );

  return (
      <Box p={5}>
        <Text bold fontSize={['3xl', '5xl']} mb="3">
          {t('dashboardWelcomeUser', { username: data.getCurrentUser.firstName })}{' '}
        </Text>
        <Text bold fontSize={['3xl', '5xl']} mb="3">
          {t('dashboardWelcome')}
        </Text>
      </Box>
  );
}
