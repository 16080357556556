import React from 'react';
import {
  Box,
  Center,
  HStack,
  Pressable,
  Text,
  useTheme,
  VStack,
} from 'native-base';
import { BarChart, Grid, YAxis } from 'react-native-svg-charts';

import { ChartData } from '@/types/Dashboard/types';
import {
  useThemeBackgroundColor,
  useThemeFontColor,
} from '../../hooks/useThemeColor';

import { useTranslation } from 'react-i18next';

interface Props {
  data: ChartData[];
}

export function ThreatsChartsMobile({ data }: Props) {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const secondaryColor = useThemeFontColor('secondary2');
  const bgColorScreen = useThemeBackgroundColor('screen');

  const [visible, setVisible] = React.useState(false);
  const [currentInputPosition, setCurrentInputPosition] = React.useState(0);
  const [pressableLayout, setPressableLayout] = React.useState(0);

  const parsedData = data.map((item: any) => {
    return item.value > 0 && item.value;
  });

  const CustomXAxis = () => (
    <HStack w="100%" justifyContent="space-evenly">
      {data?.map((item: any, index: number) => {
        return (
          <Text color={secondaryColor} fontSize="sm" key={index}>
            {item.month}
          </Text>
        );
      })}
    </HStack>
  );

  const Tooltip = () => {
    //divides the chart canvas width by the number of data items
    const XResolution = pressableLayout / parsedData.length;
    //returns a position in the x axis that we can relate to the array of data items
    const parsedPosition =
      currentInputPosition >= 0 ? currentInputPosition / XResolution : 0;

    //rounds the number down to the nearest integer
    const currentPosition = Math.floor(parsedPosition);

    const selectedItem =
      currentPosition >= 0 ? parsedData[currentPosition] : null;

    //for some reason, some pair numbers were making the box jump around when rendered
    const removePairNumbers = (item: number) => {
      return item > 0 && item % 2 === 0 ? item - 1 : item;
    };

    const boxPosition =
      currentInputPosition < pressableLayout / 2
        ? removePairNumbers(Math.floor(currentInputPosition))
        : removePairNumbers(Math.floor(currentInputPosition / 2.5));

    return visible === true &&
      currentPosition >= 0 &&
      currentPosition <= parsedData.length - 1 ? (
      <>
        <Box
          position="absolute"
          left={boxPosition}
          bg={bgColorScreen}
          p={5}
          borderRadius={10}
        >
          <Text>{selectedItem}</Text>
        </Box>
      </>
    ) : null;
  };

  return (
    <Center>
      <Text fontSize="lg" mb={5}>
        {t('dashboardAlertsTime')}
      </Text>
      <HStack>
        <YAxis
          data={parsedData}
          style={{ marginBottom: 30, flex: 0.5 }}
          contentInset={{ top: 10, bottom: 10 }}
          svg={{ fontSize: 10, fill: secondaryColor }}
        />
        <VStack flex={6}>
          <Pressable
            onPressIn={() => {
              setVisible(true);
            }}
            onPressOut={() => {
              setVisible(false);
            }}
            onTouchMove={(e) =>
              setCurrentInputPosition(e.nativeEvent.locationX)
            }
            onLayout={(e) => setPressableLayout(e.nativeEvent.layout.width)}
          >
            <BarChart
              animate
              animationDuration={300}
              style={{ height: 200 }}
              data={parsedData}
              svg={{ fill: colors.orange[500] }}
              contentInset={{ top: 30, bottom: 30 }}
            >
              <Grid />
            </BarChart>
            <Tooltip />
          </Pressable>
          <CustomXAxis />
        </VStack>
      </HStack>
    </Center>
  );
}
