import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any;
  /** The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted string**" will throw GraphQL validation errors. */
  AWSJSON: any;
  /** The `AWSTimestamp` scalar type provided by AWS AppSync, represents the number of seconds that have elapsed since `1970-01-01T00:00Z`. Negative values are also accepted and these represent the number of seconds till `1970-01-01T00:00Z`.  Timestamps are serialized and deserialized as integers. The minimum supported timestamp value is **`-31557014167219200`** which corresponds to `-1000000000-01-01T00:00Z`. The maximum supported timestamp value is **`31556889864403199`** which corresponds to `1000000000-12-31T23:59:59.999999999Z`. */
  AWSTimestamp: any;
};













export enum AdminCommand {
  ClearError = 'clear_error',
  FactoryReset = 'factory_reset',
  GetError = 'get_error',
  Ping = 'ping',
  Reboot = 'reboot',
  Reconfigure = 'reconfigure',
  RecordPcap = 'record_pcap',
  Register = 'register',
  RemoveOrphans = 'remove_orphans',
  ResetContainer = 'reset_container',
  RestartContainer = 'restart_container',
  RevshellDisable = 'revshell_disable',
  RevshellEnable = 'revshell_enable',
  RunTcpprof = 'run_tcpprof',
  RecordInterface = 'record_interface',
  SendFakeAlert = 'send_fake_alert',
  SendLogs = 'send_logs',
  UpdateOs = 'update_os'
}

export type AllCommandsForDeviceInput = {
  deviceId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
};

export type BouncerAlert = {
  __typename?: 'BouncerAlert';
  action?: Maybe<Scalars['String']>;
  alert_type?: Maybe<Scalars['String']>;
  ara?: Maybe<Array<Maybe<BouncerAlertAra>>>;
  ara_id?: Maybe<Scalars['String']>;
  blobs?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cloud_instanceid?: Maybe<Scalars['String']>;
  cloud_region?: Maybe<Scalars['String']>;
  cloud_vendor?: Maybe<Scalars['String']>;
  cname?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['String']>;
  cts_cloud_received_timestamp?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  cve?: Maybe<Scalars['String']>;
  cve_descr?: Maybe<Scalars['String']>;
  deleteTempFile?: Maybe<Scalars['Boolean']>;
  dest_host_uniq?: Maybe<Scalars['String']>;
  dest_host_uniq_enriched?: Maybe<Scalars['String']>;
  dest_host_uniq_malicious?: Maybe<Scalars['String']>;
  dest_ip_countries?: Maybe<Scalars['String']>;
  dest_ip_uniq?: Maybe<Scalars['String']>;
  dest_ip_uniq_enriched?: Maybe<Scalars['String']>;
  dest_ip_uniq_malicious?: Maybe<Scalars['String']>;
  dest_port_uniq?: Maybe<Scalars['String']>;
  destinationIpv6?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  device_record?: Maybe<Scalars['String']>;
  devicephysical?: Maybe<Scalars['String']>;
  devicevirtual?: Maybe<Scalars['String']>;
  digestIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  dst?: Maybe<Scalars['String']>;
  dst_ip?: Maybe<Scalars['String']>;
  dstport?: Maybe<Scalars['String']>;
  enqueue_date?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  faereconfidence?: Maybe<Scalars['String']>;
  faeredesc?: Maybe<Scalars['String']>;
  faererec?: Maybe<Scalars['String']>;
  faererec_en?: Maybe<Scalars['String']>;
  faereseverity?: Maybe<Scalars['String']>;
  filehash_md5?: Maybe<Scalars['String']>;
  filehash_sha1?: Maybe<Scalars['String']>;
  filehash_sha256?: Maybe<Scalars['String']>;
  filename_uniq?: Maybe<Scalars['String']>;
  from_heaven?: Maybe<Scalars['String']>;
  graphPlotImage?: Maybe<Scalars['String']>;
  graphPlotImage1?: Maybe<Scalars['String']>;
  graphPlotImage2?: Maybe<Scalars['String']>;
  graphPlotImage3?: Maybe<Scalars['String']>;
  hitcount?: Maybe<Scalars['Int']>;
  http_content_type_uniq?: Maybe<Scalars['String']>;
  http_method_uniq?: Maybe<Scalars['String']>;
  http_user_agent_uniq?: Maybe<Scalars['String']>;
  iface?: Maybe<Scalars['String']>;
  inbound?: Maybe<Scalars['String']>;
  interface?: Maybe<Scalars['String']>;
  internal?: Maybe<Scalars['String']>;
  ips_rule_nr?: Maybe<Scalars['String']>;
  killchainCategory?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['String']>;
  longdesc?: Maybe<Scalars['String']>;
  misp?: Maybe<Scalars['AWSJSON']>;
  mitreCategory?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originFile?: Maybe<Scalars['String']>;
  origins?: Maybe<Scalars['String']>;
  outbound?: Maybe<Scalars['Boolean']>;
  pcap_id?: Maybe<Scalars['String']>;
  PK?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  platform_immutable?: Maybe<Scalars['String']>;
  prefilter?: Maybe<Scalars['Boolean']>;
  producer_id?: Maybe<Scalars['String']>;
  protocol?: Maybe<Scalars['String']>;
  protocol_uniq?: Maybe<Scalars['String']>;
  reliable?: Maybe<Scalars['Boolean']>;
  report?: Maybe<Scalars['AWSJSON']>;
  rulecategory?: Maybe<Scalars['String']>;
  rulename?: Maybe<Scalars['String']>;
  safe_html_click_desc?: Maybe<Scalars['Boolean']>;
  safe_html_click_evid?: Maybe<Scalars['Boolean']>;
  safe_html_render_desc?: Maybe<Scalars['Boolean']>;
  safe_html_render_evid?: Maybe<Scalars['Boolean']>;
  sandboxexec?: Maybe<Scalars['String']>;
  servicelevel?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  severityraw?: Maybe<Scalars['String']>;
  sha?: Maybe<Scalars['String']>;
  shortdesc?: Maybe<Scalars['String']>;
  sigid?: Maybe<Scalars['String']>;
  signature_uniq?: Maybe<Scalars['String']>;
  signatureCategory?: Maybe<Scalars['String']>;
  silenced?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  sourceIpv6?: Maybe<Scalars['String']>;
  sourcetypes_uniq_formatted?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  src_host_uniq?: Maybe<Scalars['String']>;
  src_host_uniq_enriched?: Maybe<Scalars['String']>;
  src_host_uniq_malicious?: Maybe<Scalars['String']>;
  src_ip?: Maybe<Scalars['String']>;
  src_ip_traits_formatted?: Maybe<Scalars['String']>;
  src_ip_uniq?: Maybe<Scalars['String']>;
  src_ip_uniq_enriched?: Maybe<Scalars['String']>;
  src_ip_uniq_malicious?: Maybe<Scalars['String']>;
  src_port_uniq?: Maybe<Scalars['String']>;
  srcport?: Maybe<Scalars['String']>;
  srvc_lvl?: Maybe<Scalars['String']>;
  startdate?: Maybe<Scalars['String']>;
  status_uniq?: Maybe<Scalars['String']>;
  stopdate?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['String']>;
  system_message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  trigger_date?: Maybe<Scalars['String']>;
  tsconv?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  u_start_reporting_time?: Maybe<Scalars['String']>;
  url_host_uniq?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  user_uniq?: Maybe<Scalars['String']>;
  validation?: Maybe<Scalars['String']>;
  vlan?: Maybe<Scalars['String']>;
};

export type BouncerAlertAra = {
  __typename?: 'BouncerAlertAra';
  araType?: Maybe<Scalars['String']>;
  araObservableAddress?: Maybe<Scalars['String']>;
};

export type ChangeDeviceTemplateInput = {
  deviceId: Scalars['ID'];
  templateId: Scalars['ID'];
};

export type CloudInfo = {
  __typename?: 'CloudInfo';
  instanceId?: Maybe<Scalars['String']>;
  instanceType?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type Container = {
  __typename?: 'Container';
  id: Scalars['ID'];
  environments?: Maybe<Array<ContainerEnvironment>>;
  dependencies?: Maybe<Array<Scalars['String']>>;
  image: Scalars['String'];
  name: Scalars['String'];
  tag: Scalars['String'];
  tags: Scalars['AWSJSON'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
  volumes?: Maybe<Array<Scalars['String']>>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ContainerConnection = {
  __typename?: 'ContainerConnection';
  items?: Maybe<Array<Container>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ContainerEnvironment = {
  __typename?: 'ContainerEnvironment';
  default?: Maybe<Scalars['AWSJSON']>;
  enum?: Maybe<Array<Scalars['String']>>;
  iterable: Scalars['Boolean'];
  iterations?: Maybe<Scalars['Int']>;
  iterationKey?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  variable?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type ContainerEnvironmentInput = {
  default?: Maybe<Scalars['AWSJSON']>;
  enum?: Maybe<Array<Scalars['String']>>;
  iterable: Scalars['Boolean'];
  iterations?: Maybe<Scalars['Int']>;
  iterationKey?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  variable?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type ContainerInput = {
  id: Scalars['ID'];
  environments?: Maybe<Array<ContainerEnvironmentInput>>;
  dependencies?: Maybe<Array<Scalars['String']>>;
  image: Scalars['String'];
  name: Scalars['String'];
  tag: Scalars['String'];
  tags: Scalars['AWSJSON'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
  volumes?: Maybe<Array<Scalars['String']>>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CreateBulkDeviceCommandInput = {
  deviceIds?: Maybe<Array<Scalars['ID']>>;
  command: SuperAdminCommand;
  param?: Maybe<Scalars['String']>;
};

export type CreateCtsBouncerAlertsInput = {
  PK: Scalars['String'];
  SK: Scalars['String'];
  device_id?: Maybe<Scalars['String']>;
  faeredesc?: Maybe<Scalars['String']>;
  longdesc?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortdesc?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  devicephysical?: Maybe<Scalars['String']>;
  devicevirtual?: Maybe<Scalars['String']>;
  dst?: Maybe<Scalars['String']>;
  dst_ip?: Maybe<Scalars['String']>;
  dstport?: Maybe<Scalars['String']>;
  faereconfidence?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  origins?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  src_ip?: Maybe<Scalars['String']>;
  srcport?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
};

export type CreateCtsMetaEventsInput = {
  PK: Scalars['String'];
  SK: Scalars['String'];
  device_id?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type CreateCustomerInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['Int'];
};

export type CreateDeviceInput = {
  alias?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  httpProxy?: Maybe<Scalars['String']>;
  httpsProxy?: Maybe<Scalars['String']>;
  site: CtsSite;
  templateId: Scalars['ID'];
  tenantId: Scalars['ID'];
};

export type CreateTemplateInput = {
  containers?: Maybe<Scalars['AWSJSON']>;
  name: Scalars['String'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
};

export type CtsBouncerAlerts = {
  __typename?: 'CtsBouncerAlerts';
  PK: Scalars['String'];
  SK: Scalars['String'];
  deviceId?: Maybe<Scalars['String']>;
  faereDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  devicePhysical?: Maybe<Scalars['String']>;
  deviceVirtual?: Maybe<Scalars['String']>;
  dst?: Maybe<Scalars['String']>;
  dstIp?: Maybe<Scalars['String']>;
  dstPort?: Maybe<Scalars['String']>;
  faereConfidence?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  origins?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  srcIp?: Maybe<Scalars['String']>;
  srcPort?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CtsBouncerAlertsConnection = {
  __typename?: 'CtsBouncerAlertsConnection';
  items?: Maybe<Array<Maybe<CtsBouncerAlerts>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CtsMetaEvents = {
  __typename?: 'CtsMetaEvents';
  PK: Scalars['String'];
  SK: Scalars['String'];
  deviceId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  device?: Maybe<Device>;
  bouncerAlert?: Maybe<CtsBouncerAlerts>;
  seen_by?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CtsMetaEventsConnection = {
  __typename?: 'CtsMetaEventsConnection';
  items?: Maybe<Array<CtsMetaEvents>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum CtsService {
  SelfService = 'self_service',
  Gmssp = 'gmssp'
}

export enum CtsSite {
  Jp1 = 'jp1',
  Eu1 = 'eu1',
  Us1 = 'us1'
}

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['Int'];
};

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  items?: Maybe<Array<Customer>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DeleteCtsBouncerAlertsInput = {
  PK: Scalars['String'];
  SK: Scalars['String'];
};

export type DeleteCtsMetaEventsInput = {
  PK: Scalars['String'];
  SK: Scalars['String'];
};

export type DeleteCustomerInput = {
  id: Scalars['ID'];
};

export type DeleteDeviceInput = {
  deviceId: Scalars['ID'];
};

export type DeleteTemplateInput = {
  id: Scalars['ID'];
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['ID'];
  alias?: Maybe<Scalars['String']>;
  authGroup?: Maybe<Scalars['String']>;
  composeFile?: Maybe<Scalars['String']>;
  contract?: Maybe<DeviceContract>;
  customer?: Maybe<Customer>;
  enabled?: Maybe<Scalars['Boolean']>;
  info?: Maybe<DeviceInfo>;
  name: Scalars['String'];
  httpProxy?: Maybe<Scalars['String']>;
  httpsProxy?: Maybe<Scalars['String']>;
  metrics?: Maybe<DeviceMetrics>;
  site?: Maybe<CtsSite>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<DeviceStatus>;
  init_key?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['ID']>;
  templateId?: Maybe<Scalars['ID']>;
  template_override?: Maybe<Scalars['AWSJSON']>;
  tenant?: Maybe<Tenant>;
};

export type DeviceCommand = {
  __typename?: 'DeviceCommand';
  added: Scalars['String'];
  command?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  device?: Maybe<Device>;
  param?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Int']>;
  log?: Maybe<Scalars['String']>;
};

export type DeviceCommandConnection = {
  __typename?: 'DeviceCommandConnection';
  items?: Maybe<Array<DeviceCommand>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DeviceCommandUpdate = {
  __typename?: 'DeviceCommandUpdate';
  added: Scalars['String'];
  command?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  deviceId: Scalars['ID'];
  param?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Int']>;
};

export type DeviceConnection = {
  __typename?: 'DeviceConnection';
  items?: Maybe<Array<Device>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DeviceContract = {
  __typename?: 'DeviceContract';
  end?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  snowRef?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  service_tier?: Maybe<Scalars['String']>;
};

export type DeviceContractInput = {
  end?: Maybe<Scalars['AWSDateTime']>;
  service?: Maybe<Scalars['String']>;
  snowRef?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['AWSDateTime']>;
  service_tier?: Maybe<Scalars['String']>;
};

export type DeviceDisk = {
  __typename?: 'DeviceDisk';
  mountPoint: Scalars['String'];
  usage?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['String']>;
  fileSystem?: Maybe<Scalars['String']>;
};

export type DeviceDiskInput = {
  mountPoint: Scalars['String'];
  usage?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['String']>;
  fileSystem?: Maybe<Scalars['String']>;
};

export type DeviceHealth = {
  __typename?: 'DeviceHealth';
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};

export type DeviceImageHealth = {
  __typename?: 'DeviceImageHealth';
  status?: Maybe<Scalars['String']>;
  failingStreak?: Maybe<Scalars['Int']>;
  logs?: Maybe<Array<DeviceImageLog>>;
};

export type DeviceImageLog = {
  __typename?: 'DeviceImageLog';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  exitCode?: Maybe<Scalars['Int']>;
  output?: Maybe<Scalars['String']>;
};

export type DeviceImageState = {
  __typename?: 'DeviceImageState';
  status?: Maybe<Scalars['String']>;
  running?: Maybe<Scalars['Boolean']>;
  paused?: Maybe<Scalars['Boolean']>;
  restarting?: Maybe<Scalars['Boolean']>;
  oomKilled?: Maybe<Scalars['Boolean']>;
  dead?: Maybe<Scalars['Boolean']>;
  pid?: Maybe<Scalars['Int']>;
  exitCode?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  health?: Maybe<DeviceImageHealth>;
};

export type DeviceImageStatus = {
  __typename?: 'DeviceImageStatus';
  name: Scalars['String'];
  image: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
  state?: Maybe<DeviceImageState>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  id: Scalars['ID'];
  cloud?: Maybe<CloudInfo>;
  cpuCoresH?: Maybe<Scalars['Int']>;
  cpuCoresP?: Maybe<Scalars['Int']>;
  cpuProcessor?: Maybe<Scalars['String']>;
  disks?: Maybe<Array<DeviceDisk>>;
  machine?: Maybe<Scalars['String']>;
  memorySwap?: Maybe<Scalars['String']>;
  memoryTotal?: Maybe<Scalars['String']>;
  nics?: Maybe<Array<DeviceNic>>;
  os?: Maybe<Scalars['String']>;
};

export type DeviceInfoInput = {
  id: Scalars['ID'];
  cpuCoresH?: Maybe<Scalars['Int']>;
  cpuCoresP?: Maybe<Scalars['Int']>;
  cpuProcessor?: Maybe<Scalars['String']>;
  disks?: Maybe<Array<DeviceDiskInput>>;
  machine?: Maybe<Scalars['String']>;
  memorySwap?: Maybe<Scalars['String']>;
  memoryTotal?: Maybe<Scalars['String']>;
  nics?: Maybe<Array<DeviceNicInput>>;
  os?: Maybe<Scalars['String']>;
};

export type DeviceMetrics = {
  __typename?: 'DeviceMetrics';
  cpuUsed?: Maybe<PrometheusInstantResponse>;
  memoryUsed?: Maybe<PrometheusInstantResponse>;
  diskUsed?: Maybe<PrometheusInstantResponse>;
  bandwidth?: Maybe<PrometheusInstantResponse>;
};

export type DeviceNic = {
  __typename?: 'DeviceNIC';
  name: Scalars['String'];
  isUp?: Maybe<Scalars['Boolean']>;
  sniffer?: Maybe<Scalars['Boolean']>;
  speed?: Maybe<Scalars['String']>;
};

export type DeviceNicInput = {
  name: Scalars['String'];
  isUp?: Maybe<Scalars['Boolean']>;
  sniffer?: Maybe<Scalars['Boolean']>;
  speed?: Maybe<Scalars['String']>;
};

export enum DeviceState {
  Initiate = 'initiate',
  Enrol = 'Enrol'
}

export type DeviceStatus = {
  __typename?: 'DeviceStatus';
  id: Scalars['ID'];
  e2e?: Maybe<Scalars['String']>;
  imageStatus?: Maybe<Array<DeviceImageStatus>>;
  lastSeen?: Maybe<Scalars['Int']>;
  localIP?: Maybe<DeviceStatusIp>;
  publicIP?: Maybe<DeviceStatusIp>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  uptime?: Maybe<Scalars['Int']>;
};

export type DeviceStatusConnection = {
  __typename?: 'DeviceStatusConnection';
  items?: Maybe<Array<DeviceStatus>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DeviceStatusIp = {
  __typename?: 'DeviceStatusIP';
  country?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type DeviceStatusIpInput = {
  country?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type DisableDeviceInput = {
  deviceId: Scalars['ID'];
};

export type EnableDeviceInput = {
  deviceId: Scalars['ID'];
};

export type EnrollTenantDeviceInput = {
  initKey: Scalars['String'];
  deviceName: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
};

export type GetDeviceGraphInput = {
  to: Scalars['String'];
  from: Scalars['String'];
  environment: Scalars['String'];
  device: Scalars['String'];
  panelId: Scalars['String'];
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type MetaEvent = {
  __typename?: 'MetaEvent';
  cloud_instanceid?: Maybe<Scalars['String']>;
  cloud_region?: Maybe<Scalars['String']>;
  cloud_vendor?: Maybe<Scalars['String']>;
  data_objects?: Maybe<Array<Maybe<MetaEventBlobDataSource>>>;
  device?: Maybe<Device>;
  device_alias?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  /**   Turn this into a union once more types */
  event_details?: Maybe<MetaEventBouncerAlertDetails>;
  /**   Turn this into a union once more types */
  event_id?: Maybe<Scalars['String']>;
  event_object?: Maybe<BouncerAlert>;
  event_name?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['String']>;
  PK: Scalars['String'];
  seen_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  service_level?: Maybe<Scalars['String']>;
  service_family?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  shortdesc?: Maybe<Scalars['String']>;
  SK: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MetaEventBlobDataPath = {
  __typename?: 'MetaEventBlobDataPath';
  bucket: Scalars['String'];
  key: Scalars['String'];
};

export type MetaEventBlobDataSource = MetaEventDataSource & {
  __typename?: 'MetaEventBlobDataSource';
  data_path?: Maybe<MetaEventBlobDataPath>;
  data_source?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  object_type?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type MetaEventBouncerAlertDetails = {
  __typename?: 'MetaEventBouncerAlertDetails';
  active_response?: Maybe<Scalars['Boolean']>;
  confidence?: Maybe<Scalars['Int']>;
  dst_ip?: Maybe<Scalars['String']>;
  dst_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['String']>;
  killchain_state?: Maybe<Scalars['String']>;
  mitre_category?: Maybe<Scalars['String']>;
  src_ip?: Maybe<Scalars['String']>;
  src_port?: Maybe<Scalars['Int']>;
};

export type MetaEventConnection = {
  __typename?: 'MetaEventConnection';
  items?: Maybe<Array<MetaEvent>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type MetaEventDataSource = {
  data_source?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  object_type?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Mutation = {
  __typename?: 'Mutation';
  createCustomer?: Maybe<Customer>;
  updateCustomer?: Maybe<Customer>;
  deleteCustomer?: Maybe<Customer>;
  createDevice?: Maybe<Device>;
  createDeviceFromApp?: Maybe<Device>;
  updateDevice?: Maybe<Device>;
  overrideDeviceTemplate?: Maybe<Device>;
  changeDeviceTemplate?: Maybe<Device>;
  deleteDevice?: Maybe<Device>;
  disableDevice?: Maybe<Device>;
  enableDevice?: Maybe<Device>;
  pingDevice: DeviceCommand;
  updateDeviceStatus?: Maybe<DeviceStatus>;
  createAdminDeviceCommand: DeviceCommand;
  createSuperAdminDeviceCommand: DeviceCommand;
  createUserDeviceCommand: DeviceCommand;
  createBulkDeviceCommand: DeviceCommandConnection;
  updateDeviceCommand: DeviceCommandUpdate;
  updateDeviceHealth?: Maybe<DeviceHealth>;
  updateDeviceInfo: DeviceInfo;
  updateUserGroups?: Maybe<UserGroupsUpdate>;
  createContainer?: Maybe<Container>;
  deleteContainer?: Maybe<Container>;
  updateContainer?: Maybe<Container>;
  updateContainerEnvironments?: Maybe<Container>;
  createTemplate?: Maybe<Template>;
  deleteTemplate?: Maybe<Template>;
  updateTemplate?: Maybe<Template>;
  updateUserPushToken: Scalars['String'];
  createTenant: Tenant;
  sendInviteEmail: TenantInvite;
  validateInviteCode: Scalars['Boolean'];
  enrollTenantDevice: Device;
  subscribeToFCMTopic?: Maybe<Scalars['String']>;
  updateTenantUserSettings: TenantUserSettings;
  devUpdateTenantUser: TenantUser;
  adminDeleteTenant: Scalars['String'];
  deleteTenant: Tenant;
  deleteTenantUser: Scalars['String'];
  adminDisableTenant: Tenant;
  disableTenant: Tenant;
  adminEnableTenant: Tenant;
  enableTenant: Tenant;
  updateUserAlert: TenantUserAlert;
  sendOrgInviteEmail: TenantInvite;
  createCtsMetaEvents?: Maybe<CtsMetaEvents>;
  updateTenantMetaEvents?: Maybe<CtsMetaEvents>;
  updateTenantMetaEvent?: Maybe<CtsMetaEvents>;
  deleteCtsMetaEvents?: Maybe<CtsMetaEvents>;
  createCtsBouncerAlerts?: Maybe<CtsBouncerAlerts>;
  updateCtsBouncerAlerts?: Maybe<CtsBouncerAlerts>;
  deleteCtsBouncerAlerts?: Maybe<CtsBouncerAlerts>;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};


export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};


export type MutationCreateDeviceFromAppArgs = {
  input: CreateDeviceInput;
};


export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};


export type MutationOverrideDeviceTemplateArgs = {
  input: OverrideDeviceTemplateInput;
};


export type MutationChangeDeviceTemplateArgs = {
  input: ChangeDeviceTemplateInput;
};


export type MutationDeleteDeviceArgs = {
  input: DeleteDeviceInput;
};


export type MutationDisableDeviceArgs = {
  input: DisableDeviceInput;
};


export type MutationEnableDeviceArgs = {
  input: EnableDeviceInput;
};


export type MutationPingDeviceArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDeviceStatusArgs = {
  input: UpdateDeviceStatusInput;
};


export type MutationCreateAdminDeviceCommandArgs = {
  deviceId: Scalars['ID'];
  command: AdminCommand;
  param?: Maybe<Scalars['String']>;
};


export type MutationCreateSuperAdminDeviceCommandArgs = {
  deviceId: Scalars['ID'];
  command: SuperAdminCommand;
  param?: Maybe<Scalars['String']>;
};


export type MutationCreateUserDeviceCommandArgs = {
  deviceId: Scalars['ID'];
  command: UserCommand;
  param?: Maybe<Scalars['String']>;
};


export type MutationCreateBulkDeviceCommandArgs = {
  input: CreateBulkDeviceCommandInput;
};


export type MutationUpdateDeviceCommandArgs = {
  input: UpdateDeviceCommandInput;
};


export type MutationUpdateDeviceHealthArgs = {
  input: UpdateDeviceHealthInput;
};


export type MutationUpdateDeviceInfoArgs = {
  input: DeviceInfoInput;
};


export type MutationUpdateUserGroupsArgs = {
  input: UpdateUserGroupsInput;
};


export type MutationCreateContainerArgs = {
  input: ContainerInput;
};


export type MutationDeleteContainerArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateContainerArgs = {
  input: ContainerInput;
};


export type MutationUpdateContainerEnvironmentsArgs = {
  input: UpdateContainerEnvironmentsInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationDeleteTemplateArgs = {
  input: DeleteTemplateInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateUserPushTokenArgs = {
  token: Scalars['String'];
};


export type MutationCreateTenantArgs = {
  name: Scalars['String'];
  gmsspId?: Maybe<Scalars['ID']>;
  external_billing?: Maybe<Scalars['Boolean']>;
  billing_partner?: Maybe<Scalars['String']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_first_name?: Maybe<Scalars['String']>;
  registration_last_name?: Maybe<Scalars['String']>;
};


export type MutationSendInviteEmailArgs = {
  tenantId: Scalars['ID'];
  email: Scalars['String'];
};


export type MutationValidateInviteCodeArgs = {
  code: Scalars['String'];
};


export type MutationEnrollTenantDeviceArgs = {
  input: EnrollTenantDeviceInput;
};


export type MutationSubscribeToFcmTopicArgs = {
  token: Scalars['String'];
};


export type MutationUpdateTenantUserSettingsArgs = {
  input: UpdateTenantUserSettingsInput;
};


export type MutationDevUpdateTenantUserArgs = {
  input: UpdateTenantUserInput;
};


export type MutationAdminDeleteTenantArgs = {
  tenantId: Scalars['ID'];
};


export type MutationDeleteTenantArgs = {
  tenantId: Scalars['ID'];
};


export type MutationDeleteTenantUserArgs = {
  userId: Scalars['ID'];
};


export type MutationAdminDisableTenantArgs = {
  tenantId: Scalars['ID'];
};


export type MutationDisableTenantArgs = {
  tenantId: Scalars['ID'];
};


export type MutationAdminEnableTenantArgs = {
  tenantId: Scalars['ID'];
};


export type MutationEnableTenantArgs = {
  tenantId: Scalars['ID'];
};


export type MutationUpdateUserAlertArgs = {
  alert: TenantUserAlertInput;
};


export type MutationSendOrgInviteEmailArgs = {
  orgId: Scalars['ID'];
  email: Scalars['String'];
};


export type MutationCreateCtsMetaEventsArgs = {
  input: CreateCtsMetaEventsInput;
};


export type MutationUpdateTenantMetaEventsArgs = {
  input: UpdateTenantMetaEventsInput;
};


export type MutationUpdateTenantMetaEventArgs = {
  input: UpdateTenantMetaEventInput;
};


export type MutationDeleteCtsMetaEventsArgs = {
  input: DeleteCtsMetaEventsInput;
};


export type MutationCreateCtsBouncerAlertsArgs = {
  input: CreateCtsBouncerAlertsInput;
};


export type MutationUpdateCtsBouncerAlertsArgs = {
  input: UpdateCtsBouncerAlertsInput;
};


export type MutationDeleteCtsBouncerAlertsArgs = {
  input: DeleteCtsBouncerAlertsInput;
};

export type OverrideDeviceTemplateInput = {
  deviceId: Scalars['ID'];
  templateOverride: Scalars['AWSJSON'];
};

export type PrometheusInput = {
  type: Scalars['String'];
  query: Scalars['String'];
  start?: Maybe<Scalars['AWSTimestamp']>;
  end?: Maybe<Scalars['AWSTimestamp']>;
  step?: Maybe<Scalars['String']>;
};

export type PrometheusInstant = {
  __typename?: 'PrometheusInstant';
  metric: Scalars['AWSJSON'];
  value?: Maybe<Array<PrometheusValue>>;
};

export type PrometheusInstantData = {
  __typename?: 'PrometheusInstantData';
  resultType: PrometheusResultType;
  result?: Maybe<Array<PrometheusInstant>>;
};

export type PrometheusInstantResponse = {
  __typename?: 'PrometheusInstantResponse';
  data?: Maybe<PrometheusInstantData>;
  status: PrometheusStatus;
};

export type PrometheusRange = {
  __typename?: 'PrometheusRange';
  metric: Scalars['AWSJSON'];
  values?: Maybe<Array<PrometheusValue>>;
};

export type PrometheusRangeData = {
  __typename?: 'PrometheusRangeData';
  resultType: PrometheusResultType;
  result?: Maybe<Array<PrometheusRange>>;
};

export type PrometheusRangeResponse = {
  __typename?: 'PrometheusRangeResponse';
  data?: Maybe<PrometheusRangeData>;
  status: PrometheusStatus;
};

export type PrometheusResponse = PrometheusInstantResponse | PrometheusRangeResponse;

export enum PrometheusResultType {
  Matrix = 'matrix',
  Vector = 'vector',
  Scalar = 'scalar',
  String = 'string'
}

export type PrometheusScalarData = {
  __typename?: 'PrometheusScalarData';
  resultType: PrometheusResultType;
  result?: Maybe<Array<PrometheusValue>>;
};

export type PrometheusScalarResponse = {
  __typename?: 'PrometheusScalarResponse';
  data?: Maybe<PrometheusScalarData>;
  status: PrometheusStatus;
};

export enum PrometheusStatus {
  Success = 'success',
  Error = 'error'
}

export type PrometheusValue = {
  __typename?: 'PrometheusValue';
  timestamp: Scalars['AWSTimestamp'];
  metricValue: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allCommandsForDevice?: Maybe<DeviceCommandConnection>;
  getCustomer?: Maybe<Customer>;
  listCustomers?: Maybe<CustomerConnection>;
  getDevice?: Maybe<Device>;
  listDevices?: Maybe<DeviceConnection>;
  getDeviceStatus?: Maybe<DeviceStatus>;
  listDeviceStatuses?: Maybe<DeviceStatusConnection>;
  getDeviceCommand?: Maybe<DeviceCommand>;
  listDeviceCommands?: Maybe<DeviceCommandConnection>;
  getDeviceGraph?: Maybe<Scalars['String']>;
  getDeviceComposeFile?: Maybe<Scalars['String']>;
  getDeviceInitCommand?: Maybe<Scalars['String']>;
  listUsers?: Maybe<Array<Maybe<User>>>;
  listContainers?: Maybe<ContainerConnection>;
  getContainer?: Maybe<Container>;
  listTemplates?: Maybe<TemplateConnection>;
  getTemplate?: Maybe<Template>;
  prometheusQuery: PrometheusResponse;
  getTenantDevice?: Maybe<Device>;
  tenantMetrics?: Maybe<TenantMetrics>;
  listTenantDevices?: Maybe<DeviceConnection>;
  getCurrentUser: TenantUser;
  listOrgUsers?: Maybe<TenantUserConnection>;
  listOrgInvites?: Maybe<TenantInviteConnection>;
  listTenants?: Maybe<TenantConnection>;
  getTenant?: Maybe<Tenant>;
  listTenantInvites?: Maybe<TenantInviteConnection>;
  listTenantUsers?: Maybe<TenantUserConnection>;
  listTenantUserAlerts?: Maybe<TenantUserAlert>;
  getTenantMetaEvent?: Maybe<MetaEvent>;
  getTenantMetaEventCount: Scalars['Int'];
  listTenantMetaEvents?: Maybe<MetaEventConnection>;
  queryCtsMetaEventsByDeviceIdIndex?: Maybe<CtsMetaEventsConnection>;
};


export type QueryAllCommandsForDeviceArgs = {
  input: AllCommandsForDeviceInput;
};


export type QueryGetCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryListCustomersArgs = {
  filter?: Maybe<TableCustomerFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryListDevicesArgs = {
  filter?: Maybe<TableDeviceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetDeviceStatusArgs = {
  id: Scalars['ID'];
};


export type QueryListDeviceStatusesArgs = {
  filter?: Maybe<TableDeviceStatusFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetDeviceCommandArgs = {
  id: Scalars['ID'];
};


export type QueryListDeviceCommandsArgs = {
  deviceId: Scalars['ID'];
  filter?: Maybe<TableDeviceCommandsFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetDeviceGraphArgs = {
  input: GetDeviceGraphInput;
};


export type QueryGetDeviceComposeFileArgs = {
  id: Scalars['ID'];
  param?: Maybe<Scalars['String']>;
};


export type QueryGetDeviceInitCommandArgs = {
  id: Scalars['ID'];
};


export type QueryGetContainerArgs = {
  id: Scalars['ID'];
};


export type QueryGetTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryPrometheusQueryArgs = {
  type: Scalars['String'];
  query: Scalars['String'];
  start?: Maybe<Scalars['AWSTimestamp']>;
  end?: Maybe<Scalars['AWSTimestamp']>;
  step?: Maybe<Scalars['String']>;
};


export type QueryGetTenantDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryTenantMetricsArgs = {
  range: Scalars['String'];
  start?: Maybe<Scalars['AWSTimestamp']>;
  end?: Maybe<Scalars['AWSTimestamp']>;
  step?: Maybe<Scalars['String']>;
};


export type QueryListTenantDevicesArgs = {
  filter?: Maybe<TableDeviceFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListOrgUsersArgs = {
  orgId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListOrgInvitesArgs = {
  orgId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTenantsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  filter?: Maybe<TableTenantFilterInput>;
};


export type QueryGetTenantArgs = {
  tenantId: Scalars['ID'];
};


export type QueryListTenantInvitesArgs = {
  tenantId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTenantUsersArgs = {
  tenantId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryListTenantUserAlertsArgs = {
  userId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetTenantMetaEventArgs = {
  id: Scalars['ID'];
};


export type QueryGetTenantMetaEventCountArgs = {
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
  type?: Maybe<Scalars['String']>;
};


export type QueryListTenantMetaEventsArgs = {
  filter?: Maybe<TableCtsMetaEventsFilterInput>;
  sortDirection?: Maybe<ModelSortDirection>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryQueryCtsMetaEventsByDeviceIdIndexArgs = {
  device_id: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateCustomer?: Maybe<Customer>;
  onUpdateCustomer?: Maybe<Customer>;
  onDeleteCustomer?: Maybe<Customer>;
  onCreateDevice?: Maybe<Device>;
  onCreateDeviceFromApp?: Maybe<Device>;
  onUpdateDevice?: Maybe<Device>;
  onDeleteDevice?: Maybe<Device>;
  onUpdateDeviceStatus?: Maybe<DeviceStatus>;
  onUpdateDeviceCommand?: Maybe<DeviceCommandUpdate>;
  onUpdateDeviceHealth?: Maybe<DeviceHealth>;
  onUpdateTenantDevice?: Maybe<Device>;
  onUpdateTenantDeviceHealth?: Maybe<DeviceHealth>;
  onCreateTenant?: Maybe<Tenant>;
  onEmailInviteSent?: Maybe<TenantInvite>;
  onCreateCtsMetaEvents?: Maybe<CtsMetaEvents>;
  onUpdateTenantMetaEvents?: Maybe<CtsMetaEvents>;
  onUpdateTenantMetaEvent?: Maybe<CtsMetaEvents>;
  onDeleteCtsMetaEvents?: Maybe<CtsMetaEvents>;
  onCreateCtsBouncerAlerts?: Maybe<CtsBouncerAlerts>;
  onUpdateCtsBouncerAlerts?: Maybe<CtsBouncerAlerts>;
  onDeleteCtsBouncerAlerts?: Maybe<CtsBouncerAlerts>;
};


export type SubscriptionOnCreateCustomerArgs = {
  id?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateCustomerArgs = {
  id?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type SubscriptionOnDeleteCustomerArgs = {
  id?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateDeviceArgs = {
  id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['AWSJSON']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};


export type SubscriptionOnDeleteDeviceArgs = {
  id?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['AWSJSON']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};


export type SubscriptionOnUpdateDeviceStatusArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionOnUpdateDeviceCommandArgs = {
  deviceId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionOnUpdateDeviceHealthArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type SubscriptionOnUpdateTenantDeviceArgs = {
  tenantId: Scalars['ID'];
};


export type SubscriptionOnUpdateTenantDeviceHealthArgs = {
  tenantId: Scalars['ID'];
};


export type SubscriptionOnCreateCtsMetaEventsArgs = {
  PK?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateTenantMetaEventsArgs = {
  PK?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  seen_by?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SubscriptionOnUpdateTenantMetaEventArgs = {
  PK?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  seen_by?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SubscriptionOnDeleteCtsMetaEventsArgs = {
  PK?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
};


export type SubscriptionOnCreateCtsBouncerAlertsArgs = {
  PK?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  faeredesc?: Maybe<Scalars['String']>;
  longdesc?: Maybe<Scalars['String']>;
};


export type SubscriptionOnUpdateCtsBouncerAlertsArgs = {
  PK?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  faeredesc?: Maybe<Scalars['String']>;
  longdesc?: Maybe<Scalars['String']>;
};


export type SubscriptionOnDeleteCtsBouncerAlertsArgs = {
  PK?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  faeredesc?: Maybe<Scalars['String']>;
  longdesc?: Maybe<Scalars['String']>;
};

export enum SuperAdminCommand {
  ClearError = 'clear_error',
  FactoryReset = 'factory_reset',
  GetError = 'get_error',
  IssueKillswitch = 'issue_killswitch',
  Ping = 'ping',
  Reboot = 'reboot',
  Reconfigure = 'reconfigure',
  RecordPcap = 'record_pcap',
  Register = 'register',
  RemoveOrphans = 'remove_orphans',
  ResetContainer = 'reset_container',
  RestartContainer = 'restart_container',
  RevshellDisable = 'revshell_disable',
  RevshellEnable = 'revshell_enable',
  RunTcpprof = 'run_tcpprof',
  RecordInterface = 'record_interface',
  SendFakeAlert = 'send_fake_alert',
  SendLogs = 'send_logs',
  UpdateOs = 'update_os'
}

export type TableBooleanFilterInput = {
  ne?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
};

export type TableCtsBouncerAlertsFilterInput = {
  PK?: Maybe<TableStringFilterInput>;
  SK?: Maybe<TableStringFilterInput>;
  device_id?: Maybe<TableStringFilterInput>;
  faeredesc?: Maybe<TableStringFilterInput>;
  longdesc?: Maybe<TableStringFilterInput>;
  name?: Maybe<TableStringFilterInput>;
  shortdesc?: Maybe<TableStringFilterInput>;
  timestamp?: Maybe<TableStringFilterInput>;
  devicephysical?: Maybe<TableStringFilterInput>;
  devicevirtual?: Maybe<TableStringFilterInput>;
  dst?: Maybe<TableStringFilterInput>;
  dst_ip?: Maybe<TableStringFilterInput>;
  dstport?: Maybe<TableStringFilterInput>;
  faereconfidence?: Maybe<TableStringFilterInput>;
  customer?: Maybe<TableStringFilterInput>;
  severity?: Maybe<TableStringFilterInput>;
  origins?: Maybe<TableStringFilterInput>;
  src?: Maybe<TableStringFilterInput>;
  src_ip?: Maybe<TableStringFilterInput>;
  srcport?: Maybe<TableStringFilterInput>;
  tenant_id?: Maybe<TableStringFilterInput>;
  type?: Maybe<TableStringFilterInput>;
  site?: Maybe<TableStringFilterInput>;
};

export type TableCtsMetaEventsFilterInput = {
  PK?: Maybe<TableStringFilterInput>;
  SK?: Maybe<TableStringFilterInput>;
  device_id?: Maybe<TableStringFilterInput>;
  event_id?: Maybe<TableStringFilterInput>;
  event_name?: Maybe<TableStringFilterInput>;
  event_type?: Maybe<TableStringFilterInput>;
  tenant_id?: Maybe<TableStringFilterInput>;
  timestamp?: Maybe<TableStringFilterInput>;
  seen_by?: Maybe<TableStringFilterInput>;
};

export type TableCustomerFilterInput = {
  id?: Maybe<TableIdFilterInput>;
  address?: Maybe<TableStringFilterInput>;
  city?: Maybe<TableStringFilterInput>;
  country?: Maybe<TableStringFilterInput>;
  name?: Maybe<TableStringFilterInput>;
  state?: Maybe<TableStringFilterInput>;
  zip?: Maybe<TableIntFilterInput>;
};

export type TableDeviceCommandsFilterInput = {
  added?: Maybe<TableStringFilterInput>;
  command?: Maybe<TableStringFilterInput>;
  command_id?: Maybe<TableIdFilterInput>;
  device_id?: Maybe<TableStringFilterInput>;
  param?: Maybe<TableStringFilterInput>;
  result?: Maybe<TableStringFilterInput>;
  state?: Maybe<TableIntFilterInput>;
};

export type TableDeviceFilterInput = {
  id?: Maybe<TableIdFilterInput>;
  alias?: Maybe<TableStringFilterInput>;
  customer_id?: Maybe<TableStringFilterInput>;
  enabled?: Maybe<TableBooleanFilterInput>;
  name?: Maybe<TableStringFilterInput>;
  site_code?: Maybe<TableStringFilterInput>;
  tenant_id?: Maybe<TableStringFilterInput>;
};

export type TableDeviceStatusFilterInput = {
  devicestatus_id?: Maybe<TableStringFilterInput>;
};

export type TableFloatFilterInput = {
  ne?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  contains?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type TableIdFilterInput = {
  ne?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  beginsWith?: Maybe<Scalars['ID']>;
};

export type TableIntFilterInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  contains?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TableMetaEventInput = {
  event_type?: Maybe<TableStringFilterInput>;
};

export type TableStringFilterInput = {
  ne?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  beginsWith?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TableTenantFilterInput = {
  service?: Maybe<TableStringFilterInput>;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  containers: Scalars['AWSJSON'];
  name: Scalars['String'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
};

export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  items?: Maybe<Array<Template>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  gmsspId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  service?: Maybe<CtsService>;
  soarToken: Scalars['String'];
  external_billing?: Maybe<Scalars['Boolean']>;
  billing_partner?: Maybe<Scalars['String']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_first_name?: Maybe<Scalars['String']>;
  registration_last_name?: Maybe<Scalars['String']>;
};

export type TenantConnection = {
  __typename?: 'TenantConnection';
  items?: Maybe<Array<Tenant>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type TenantInvite = {
  __typename?: 'TenantInvite';
  inviteCode: Scalars['String'];
  email: Scalars['String'];
  tenantId: Scalars['ID'];
};

export type TenantInviteConnection = {
  __typename?: 'TenantInviteConnection';
  items?: Maybe<Array<TenantInvite>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type TenantMetrics = {
  __typename?: 'TenantMetrics';
  dataRetrieved?: Maybe<PrometheusInstantResponse>;
  eventsAnalyzed?: Maybe<PrometheusInstantResponse>;
  indicators?: Maybe<PrometheusInstantResponse>;
  eventsPerSecond?: Maybe<PrometheusRangeResponse>;
  bandwidth?: Maybe<PrometheusRangeResponse>;
};

export type TenantUser = {
  __typename?: 'TenantUser';
  id: Scalars['ID'];
  email: Scalars['String'];
  fcmTokens?: Maybe<Array<Scalars['String']>>;
  firstName: Scalars['String'];
  groups?: Maybe<Array<Scalars['String']>>;
  lastName: Scalars['String'];
  pushToken?: Maybe<Scalars['String']>;
  settings: TenantUserSettings;
  tenant: Tenant;
};

export type TenantUserAlert = {
  __typename?: 'TenantUserAlert';
  alert_id: Scalars['String'];
  seen: Scalars['Boolean'];
  seenAt?: Maybe<Scalars['String']>;
};

export type TenantUserAlertInput = {
  alert_id: Scalars['String'];
  seen: Scalars['Boolean'];
  seenAt?: Maybe<Scalars['String']>;
};

export type TenantUserConnection = {
  __typename?: 'TenantUserConnection';
  items?: Maybe<Array<TenantUser>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type TenantUserSettings = {
  __typename?: 'TenantUserSettings';
  emailNotifications: Scalars['Boolean'];
  mobileNotifications: Scalars['Boolean'];
  webNotifications: Scalars['Boolean'];
  confidenceFilter?: Maybe<Scalars['Int']>;
};

export type UpdateContainerEnvironmentsInput = {
  id: Scalars['ID'];
  environments?: Maybe<Array<ContainerEnvironmentInput>>;
};

export type UpdateCtsBouncerAlertsInput = {
  PK: Scalars['String'];
  SK: Scalars['String'];
  device_id?: Maybe<Scalars['String']>;
  faeredesc?: Maybe<Scalars['String']>;
  longdesc?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortdesc?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  devicephysical?: Maybe<Scalars['String']>;
  devicevirtual?: Maybe<Scalars['String']>;
  dst?: Maybe<Scalars['String']>;
  dst_ip?: Maybe<Scalars['String']>;
  dstport?: Maybe<Scalars['String']>;
  faereconfidence?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  origins?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  src_ip?: Maybe<Scalars['String']>;
  srcport?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
};

export type UpdateCustomerInput = {
  id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

export type UpdateDeviceCommandInput = {
  id?: Maybe<Scalars['ID']>;
  added?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['ID']>;
  param?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Int']>;
};

export type UpdateDeviceHealthInput = {
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};

export type UpdateDeviceInput = {
  id: Scalars['ID'];
  alias?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['AWSJSON']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateDeviceStatusInput = {
  devicestatus_id: Scalars['ID'];
  devicestatus?: Maybe<Scalars['String']>;
  devicestatus_reason?: Maybe<Scalars['String']>;
  e2e_ts?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['Int']>;
  local_ip?: Maybe<Scalars['String']>;
  public_ip?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uptime?: Maybe<Scalars['Int']>;
};

export type UpdateTemplateInput = {
  id: Scalars['ID'];
  containers?: Maybe<Scalars['AWSJSON']>;
  name: Scalars['String'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
};

export type UpdateTenantMetaEventInput = {
  PK: Scalars['String'];
  SK?: Maybe<Scalars['String']>;
  seen_by?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateTenantMetaEventsInput = {
  PK?: Maybe<Scalars['String']>;
  SK: Scalars['String'];
  seen_by?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateTenantUserInput = {
  tenantId: Scalars['ID'];
};

export type UpdateTenantUserSettingsInput = {
  emailNotifications: Scalars['Boolean'];
  mobileNotifications: Scalars['Boolean'];
  webNotifications: Scalars['Boolean'];
  confidenceFilter?: Maybe<Scalars['Int']>;
};

export type UpdateUserGroupsInput = {
  id: Scalars['ID'];
  groups: Array<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  groups?: Maybe<Array<Scalars['String']>>;
};

export enum UserCommand {
  Ping = 'ping'
}

export type UserGroupsUpdate = {
  __typename?: 'UserGroupsUpdate';
  id: Scalars['ID'];
  groups?: Maybe<Array<Scalars['String']>>;
};

export type MetaEventDetailsFragment = (
  { __typename?: 'MetaEvent' }
  & Pick<MetaEvent, 'PK' | 'SK' | 'device_alias' | 'device_id' | 'device_name' | 'event_category' | 'event_id' | 'event_name' | 'last_updated' | 'seen_by' | 'severity' | 'shortdesc' | 'summary' | 'tenant_id' | 'timestamp' | 'title'>
);

export type TenantEventsDetailsFragment = (
  { __typename?: 'MetaEventConnection' }
  & Pick<MetaEventConnection, 'nextToken'>
  & {
    items?: Maybe<Array<(
      { __typename?: 'MetaEvent' }
      & MetaEventDetailsFragment
    )>>
  }
);

export type CtsMetaEventsDetailsFragment = (
  { __typename?: 'CtsMetaEvents' }
  & Pick<CtsMetaEvents, 'SK' | 'seen_by'>
);

export type ListTenantMetaEventsQueryVariables = Exact<{
  filter?: Maybe<TableCtsMetaEventsFilterInput>;
  nextToken?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ListTenantMetaEventsQuery = (
  { __typename?: 'Query' }
  & {
    listTenantMetaEvents?: Maybe<(
      { __typename?: 'MetaEventConnection' }
      & TenantEventsDetailsFragment
    )>
  }
);

export type EventObjectFragment = (
  { __typename?: 'BouncerAlert' }
  & Pick<BouncerAlert, 'action' | 'brand' | 'category' | 'cloud_instanceid' | 'cloud_region' | 'cloud_vendor' | 'dest_host_uniq' | 'dest_host_uniq_enriched' | 'dest_host_uniq_malicious' | 'dest_ip_countries' | 'dest_ip_uniq' | 'dest_ip_uniq_enriched' | 'dest_ip_uniq_malicious' | 'dest_port_uniq' | 'destinationIpv6' | 'device_id' | 'devicevirtual' | 'dst' | 'dst_ip' | 'dstport' | 'event_type' | 'faereconfidence' | 'faererec' | 'faereseverity' | 'from_heaven' | 'http_content_type_uniq' | 'http_method_uniq' | 'http_user_agent_uniq' | 'inbound' | 'interface' | 'killchainCategory' | 'mitreCategory' | 'misp' | 'name' | 'origins' | 'outbound' | 'protocol' | 'protocol_uniq' | 'reliable' | 'report' | 'rulecategory' | 'rulename' | 'servicelevel' | 'severity' | 'severityraw' | 'sha' | 'shortdesc' | 'sigid' | 'signature_uniq' | 'signatureCategory' | 'silenced' | 'site' | 'sourceIpv6' | 'sourcetypes_uniq_formatted' | 'src' | 'src_host_uniq' | 'src_host_uniq_enriched' | 'src_host_uniq_malicious' | 'src_ip' | 'src_ip_traits_formatted' | 'src_ip_uniq' | 'src_ip_uniq_enriched' | 'src_ip_uniq_malicious' | 'src_port_uniq' | 'srcport' | 'srvc_lvl' | 'startdate' | 'stopdate' | 'subcategory' | 'timestamp' | 'timezone' | 'trigger_date' | 'tsconv' | 'type' | 'u_start_reporting_time' | 'url_host_uniq' | 'user' | 'user_uniq' | 'validation' | 'vlan'>
);

export type GetMetaEventDetailsFragment = (
  { __typename?: 'MetaEvent' }
  & {
    event_object?: Maybe<(
      { __typename?: 'BouncerAlert' }
      & EventObjectFragment
    )>
  }
  & MetaEventDetailsFragment
);

export type UpdateSeenByMutationVariables = Exact<{
  input: UpdateTenantMetaEventsInput;
}>;


export type UpdateSeenByMutation = (
  { __typename?: 'Mutation' }
  & {
    updateTenantMetaEvents?: Maybe<(
      { __typename?: 'CtsMetaEvents' }
      & CtsMetaEventsDetailsFragment
    )>
  }
);

export type UpdateAlertDetailsSeenByMutationVariables = Exact<{
  input: UpdateTenantMetaEventInput;
}>;


export type UpdateAlertDetailsSeenByMutation = (
  { __typename?: 'Mutation' }
  & {
    updateTenantMetaEvent?: Maybe<(
      { __typename?: 'CtsMetaEvents' }
      & CtsMetaEventsDetailsFragment
    )>
  }
);

export type GetTenantMetaEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTenantMetaEventQuery = (
  { __typename?: 'Query' }
  & {
    getTenantMetaEvent?: Maybe<(
      { __typename?: 'MetaEvent' }
      & GetMetaEventDetailsFragment
    )>
  }
);

export type TenantEventsDetailsDetailsFragment = (
  { __typename?: 'MetaEvent' }
  & Pick<MetaEvent, 'PK' | 'SK' | 'event_category' | 'event_id' | 'event_name' | 'last_updated' | 'seen_by' | 'severity' | 'shortdesc' | 'summary' | 'tenant_id' | 'timestamp' | 'title'>
);

export type ListTenantMetaEventsBasicQueryVariables = Exact<{
  filter?: Maybe<TableCtsMetaEventsFilterInput>;
  nextToken?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ListTenantMetaEventsBasicQuery = (
  { __typename?: 'Query' }
  & {
    listTenantMetaEvents?: Maybe<(
      { __typename?: 'MetaEventConnection' }
      & TenantEventsDetailsFragment
    )>
  }
);

export type DeviceDetailsBasicsFragment = (
  { __typename?: 'Device' }
  & Pick<Device, 'id' | 'alias' | 'name' | 'state' | 'enabled'>
  & {
    status?: Maybe<(
      { __typename?: 'DeviceStatus' }
      & Pick<DeviceStatus, 'id' | 'state' | 'status' | 'statusReason'>
    )>, tenant?: Maybe<(
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id'>
    )>
  }
);

export type DeviceListDeviceBasicsFragment = (
  { __typename?: 'Device' }
  & DeviceDetailsBasicsFragment
);

export type TenantDevicesDetailsBasicsFragment = (
  { __typename?: 'DeviceConnection' }
  & Pick<DeviceConnection, 'nextToken'>
  & {
    items?: Maybe<Array<(
      { __typename?: 'Device' }
      & DeviceListDeviceBasicsFragment
    )>>
  }
);

export type ListTenantDevicesBasicsQueryVariables = Exact<{
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListTenantDevicesBasicsQuery = (
  { __typename?: 'Query' }
  & {
    listTenantDevices?: Maybe<(
      { __typename?: 'DeviceConnection' }
      & TenantDevicesDetailsBasicsFragment
    )>
  }
);

export type DeviceContractDetailsFragment = (
  { __typename?: 'DeviceContract' }
  & Pick<DeviceContract, 'end' | 'service' | 'snowRef' | 'start' | 'service_tier'>
);

export type DeviceInfoDetailsFragment = (
  { __typename?: 'DeviceInfo' }
  & Pick<DeviceInfo, 'id' | 'cpuCoresH' | 'cpuCoresP' | 'cpuProcessor' | 'machine' | 'memorySwap' | 'memoryTotal' | 'os'>
  & {
    disks?: Maybe<Array<(
      { __typename?: 'DeviceDisk' }
      & Pick<DeviceDisk, 'mountPoint' | 'usage' | 'size' | 'fileSystem'>
    )>>, nics?: Maybe<Array<(
      { __typename?: 'DeviceNIC' }
      & Pick<DeviceNic, 'name' | 'isUp' | 'sniffer' | 'speed'>
    )>>, cloud?: Maybe<(
      { __typename?: 'CloudInfo' }
      & Pick<CloudInfo, 'region' | 'instanceId' | 'instanceType' | 'vendor'>
    )>
  }
);

export type DeviceDetailsFragment = (
  { __typename?: 'Device' }
  & Pick<Device, 'id' | 'alias' | 'name' | 'state' | 'init_key' | 'enabled'>
  & {
    status?: Maybe<(
      { __typename?: 'DeviceStatus' }
      & Pick<DeviceStatus, 'id' | 'state' | 'status' | 'statusReason'>
    )>, tenant?: Maybe<(
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id'>
    )>, contract?: Maybe<(
      { __typename?: 'DeviceContract' }
      & Pick<DeviceContract, 'service' | 'service_tier'>
    )>
  }
);

export type DeviceListDeviceFragment = (
  { __typename?: 'Device' }
  & {
    info?: Maybe<(
      { __typename?: 'DeviceInfo' }
      & DeviceInfoDetailsFragment
    )>, contract?: Maybe<(
      { __typename?: 'DeviceContract' }
      & DeviceContractDetailsFragment
    )>
  }
  & DeviceDetailsFragment
);

export type TenantDevicesDetailsFragment = (
  { __typename?: 'DeviceConnection' }
  & Pick<DeviceConnection, 'nextToken'>
  & {
    items?: Maybe<Array<(
      { __typename?: 'Device' }
      & DeviceListDeviceFragment
    )>>
  }
);

export type OnCreateDeviceFromAppSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateDeviceFromAppSubscription = (
  { __typename?: 'Subscription' }
  & {
    onCreateDeviceFromApp?: Maybe<(
      { __typename?: 'Device' }
      & DeviceDetailsFragment
    )>
  }
);

export type CreateDeviceMutationVariables = Exact<{
  input: CreateDeviceInput;
}>;


export type CreateDeviceMutation = (
  { __typename?: 'Mutation' }
  & {
    createDeviceFromApp?: Maybe<(
      { __typename?: 'Device' }
      & Pick<Device, 'id' | 'alias' | 'authGroup' | 'enabled' | 'name' | 'site' | 'state' | 'template' | 'templateId' | 'init_key'>
      & {
        contract?: Maybe<(
          { __typename?: 'DeviceContract' }
          & DeviceContractDetailsFragment
        )>, tenant?: Maybe<(
          { __typename?: 'Tenant' }
          & Pick<Tenant, 'id'>
        )>
      }
    )>
  }
);

export type ListTenantDevicesQueryVariables = Exact<{
  filter?: Maybe<TableDeviceFilterInput>;
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListTenantDevicesQuery = (
  { __typename?: 'Query' }
  & {
    listTenantDevices?: Maybe<(
      { __typename?: 'DeviceConnection' }
      & TenantDevicesDetailsFragment
    )>
  }
);

export type InstantMetricResultFragment = (
  { __typename?: 'PrometheusInstantResponse' }
  & {
    data?: Maybe<(
      { __typename?: 'PrometheusInstantData' }
      & {
        result?: Maybe<Array<(
          { __typename?: 'PrometheusInstant' }
          & Pick<PrometheusInstant, 'metric'>
          & {
            value?: Maybe<Array<(
              { __typename?: 'PrometheusValue' }
              & Pick<PrometheusValue, 'timestamp' | 'metricValue'>
            )>>
          }
        )>>
      }
    )>
  }
);

export type TenantDeviceMetricsFragment = (
  { __typename?: 'DeviceMetrics' }
  & {
    bandwidth?: Maybe<(
      { __typename?: 'PrometheusInstantResponse' }
      & InstantMetricResultFragment
    )>
  }
);

export type TenantDeviceDetailsFragment = (
  { __typename?: 'Device' }
  & {
    status?: Maybe<(
      { __typename?: 'DeviceStatus' }
      & Pick<DeviceStatus, 'status' | 'state' | 'lastSeen' | 'e2e'>
    )>, info?: Maybe<(
      { __typename?: 'DeviceInfo' }
      & DeviceInfoDetailsFragment
    )>, metrics?: Maybe<(
      { __typename?: 'DeviceMetrics' }
      & TenantDeviceMetricsFragment
    )>, contract?: Maybe<(
      { __typename?: 'DeviceContract' }
      & Pick<DeviceContract, 'service_tier'>
    )>
  }
  & DeviceDetailsFragment
);

export type GetDeviceInitCommandQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDeviceInitCommandQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDeviceInitCommand'>
);

export type GetTenantDeviceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTenantDeviceQuery = (
  { __typename?: 'Query' }
  & {
    getTenantDevice?: Maybe<(
      { __typename?: 'Device' }
      & TenantDeviceDetailsFragment
    )>
  }
);

export type EnrollTenantDeviceMutationVariables = Exact<{
  input: EnrollTenantDeviceInput;
}>;


export type EnrollTenantDeviceMutation = (
  { __typename?: 'Mutation' }
  & {
    enrollTenantDevice: (
      { __typename?: 'Device' }
      & DeviceDetailsFragment
    )
  }
);

export type OnUpdateTenantDeviceSubscriptionVariables = Exact<{
  tenantId: Scalars['ID'];
}>;


export type OnUpdateTenantDeviceSubscription = (
  { __typename?: 'Subscription' }
  & {
    onUpdateTenantDevice?: Maybe<(
      { __typename?: 'Device' }
      & DeviceDetailsFragment
    )>
  }
);

export type RangeMetricResultFragment = (
  { __typename?: 'PrometheusRangeResponse' }
  & {
    data?: Maybe<(
      { __typename?: 'PrometheusRangeData' }
      & {
        result?: Maybe<Array<(
          { __typename?: 'PrometheusRange' }
          & Pick<PrometheusRange, 'metric'>
          & {
            values?: Maybe<Array<(
              { __typename?: 'PrometheusValue' }
              & Pick<PrometheusValue, 'timestamp' | 'metricValue'>
            )>>
          }
        )>>
      }
    )>
  }
);

export type TenantMetricsDetailsFragment = (
  { __typename?: 'TenantMetrics' }
  & {
    dataRetrieved?: Maybe<(
      { __typename?: 'PrometheusInstantResponse' }
      & InstantMetricResultFragment
    )>, eventsAnalyzed?: Maybe<(
      { __typename?: 'PrometheusInstantResponse' }
      & InstantMetricResultFragment
    )>, indicators?: Maybe<(
      { __typename?: 'PrometheusInstantResponse' }
      & InstantMetricResultFragment
    )>, bandwidth?: Maybe<(
      { __typename?: 'PrometheusRangeResponse' }
      & RangeMetricResultFragment
    )>, eventsPerSecond?: Maybe<(
      { __typename?: 'PrometheusRangeResponse' }
      & RangeMetricResultFragment
    )>
  }
);

export type TenantMetricsQueryVariables = Exact<{
  range: Scalars['String'];
  start?: Maybe<Scalars['AWSTimestamp']>;
  end?: Maybe<Scalars['AWSTimestamp']>;
  step?: Maybe<Scalars['String']>;
}>;


export type TenantMetricsQuery = (
  { __typename?: 'Query' }
  & {
    tenantMetrics?: Maybe<(
      { __typename?: 'TenantMetrics' }
      & TenantMetricsDetailsFragment
    )>
  }
);

export type UpdateUserPushTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type UpdateUserPushTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserPushToken'>
);

export type SubscribeToFcmTopicMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SubscribeToFcmTopicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'subscribeToFCMTopic'>
);

export type UpdateTenantUserSettingsMutationVariables = Exact<{
  input: UpdateTenantUserSettingsInput;
}>;


export type UpdateTenantUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & {
    updateTenantUserSettings: (
      { __typename?: 'TenantUserSettings' }
      & Pick<TenantUserSettings, 'emailNotifications' | 'mobileNotifications' | 'webNotifications' | 'confidenceFilter'>
    )
  }
);

export type TenantDetailsFragment = (
  { __typename?: 'Tenant' }
  & Pick<Tenant, 'id' | 'name'>
);

export type ListTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListTenantsQuery = (
  { __typename?: 'Query' }
  & {
    listTenants?: Maybe<(
      { __typename?: 'TenantConnection' }
      & {
        items?: Maybe<Array<(
          { __typename?: 'Tenant' }
          & TenantDetailsFragment
        )>>
      }
    )>
  }
);

export type CurrentUserFragment = (
  { __typename?: 'TenantUser' }
  & Pick<TenantUser, 'id' | 'firstName' | 'lastName' | 'email' | 'groups'>
  & {
    tenant: (
      { __typename?: 'Tenant' }
      & TenantDetailsFragment
    ), settings: (
      { __typename?: 'TenantUserSettings' }
      & Pick<TenantUserSettings, 'emailNotifications' | 'mobileNotifications' | 'webNotifications' | 'confidenceFilter'>
    )
  }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & {
    getCurrentUser: (
      { __typename?: 'TenantUser' }
      & CurrentUserFragment
    )
  }
);

export type UserDetailsFragment = (
  { __typename?: 'TenantUser' }
  & Pick<TenantUser, 'id' | 'email' | 'firstName' | 'lastName'>
);

export type ListOrgUsersQueryVariables = Exact<{
  orgId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListOrgUsersQuery = (
  { __typename?: 'Query' }
  & {
    listOrgUsers?: Maybe<(
      { __typename?: 'TenantUserConnection' }
      & Pick<TenantUserConnection, 'nextToken'>
      & {
        items?: Maybe<Array<(
          { __typename?: 'TenantUser' }
          & UserDetailsFragment
        )>>
      }
    )>
  }
);

export type TenantInviteDetailsFragment = (
  { __typename?: 'TenantInvite' }
  & Pick<TenantInvite, 'email'>
);

export type ListOrgInvitesQueryVariables = Exact<{
  orgId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
}>;


export type ListOrgInvitesQuery = (
  { __typename?: 'Query' }
  & {
    listOrgInvites?: Maybe<(
      { __typename?: 'TenantInviteConnection' }
      & Pick<TenantInviteConnection, 'nextToken'>
      & {
        items?: Maybe<Array<(
          { __typename?: 'TenantInvite' }
          & TenantInviteDetailsFragment
        )>>
      }
    )>
  }
);

export type SendOrgInviteEmailMutationVariables = Exact<{
  orgId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type SendOrgInviteEmailMutation = (
  { __typename?: 'Mutation' }
  & {
    sendOrgInviteEmail: (
      { __typename?: 'TenantInvite' }
      & TenantInviteDetailsFragment
    )
  }
);

export type DevUpdateTenantUserMutationVariables = Exact<{
  input: UpdateTenantUserInput;
}>;


export type DevUpdateTenantUserMutation = (
  { __typename?: 'Mutation' }
  & {
    devUpdateTenantUser: (
      { __typename?: 'TenantUser' }
      & CurrentUserFragment
    )
  }
);

export type DeleteTenantUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeleteTenantUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTenantUser'>
);

export const MetaEventDetailsFragmentDoc = gql`
    fragment MetaEventDetails on MetaEvent {
  PK
  SK
  device_alias
  device_id
  device_name
  event_category
  event_id
  event_name
  last_updated
  seen_by
  severity
  shortdesc
  summary
  tenant_id
  timestamp
  title
}
    `;
export const TenantEventsDetailsFragmentDoc = gql`
    fragment TenantEventsDetails on MetaEventConnection {
  items {
    ...MetaEventDetails
  }
  nextToken
}
    ${MetaEventDetailsFragmentDoc}`;
export const CtsMetaEventsDetailsFragmentDoc = gql`
    fragment CtsMetaEventsDetails on CtsMetaEvents {
  SK
  seen_by
}
    `;
export const EventObjectFragmentDoc = gql`
    fragment EventObject on BouncerAlert {
  action
  brand
  category
  cloud_instanceid
  cloud_region
  cloud_vendor
  dest_host_uniq
  dest_host_uniq_enriched
  dest_host_uniq_malicious
  dest_ip_countries
  dest_ip_uniq
  dest_ip_uniq_enriched
  dest_ip_uniq_malicious
  dest_port_uniq
  destinationIpv6
  device_id
  devicevirtual
  dst
  dst_ip
  dstport
  event_type
  faereconfidence
  faererec
  faereseverity
  from_heaven
  http_content_type_uniq
  http_method_uniq
  http_user_agent_uniq
  inbound
  interface
  killchainCategory
  mitreCategory
  misp
  name
  origins
  outbound
  protocol
  protocol_uniq
  reliable
  report
  rulecategory
  rulename
  servicelevel
  severity
  severityraw
  sha
  shortdesc
  sigid
  signature_uniq
  signatureCategory
  silenced
  site
  sourceIpv6
  sourcetypes_uniq_formatted
  src
  src_host_uniq
  src_host_uniq_enriched
  src_host_uniq_malicious
  src_ip
  src_ip_traits_formatted
  src_ip_uniq
  src_ip_uniq_enriched
  src_ip_uniq_malicious
  src_port_uniq
  srcport
  srvc_lvl
  startdate
  stopdate
  subcategory
  timestamp
  timezone
  trigger_date
  tsconv
  type
  u_start_reporting_time
  url_host_uniq
  user
  user_uniq
  validation
  vlan
}
    `;
export const GetMetaEventDetailsFragmentDoc = gql`
    fragment GetMetaEventDetails on MetaEvent {
  ...MetaEventDetails
  event_object {
    ...EventObject
  }
}
    ${MetaEventDetailsFragmentDoc}
${EventObjectFragmentDoc}`;
export const TenantEventsDetailsDetailsFragmentDoc = gql`
    fragment TenantEventsDetailsDetails on MetaEvent {
  PK
  SK
  event_category
  event_id
  event_name
  last_updated
  seen_by
  severity
  shortdesc
  summary
  tenant_id
  timestamp
  title
}
    `;
export const DeviceDetailsBasicsFragmentDoc = gql`
    fragment DeviceDetailsBasics on Device {
  id
  alias
  name
  state
  status {
    id
    state
    status
    statusReason
  }
  tenant {
    id
  }
  enabled
}
    `;
export const DeviceListDeviceBasicsFragmentDoc = gql`
    fragment DeviceListDeviceBasics on Device {
  ...DeviceDetailsBasics
}
    ${DeviceDetailsBasicsFragmentDoc}`;
export const TenantDevicesDetailsBasicsFragmentDoc = gql`
    fragment TenantDevicesDetailsBasics on DeviceConnection {
  items {
    ...DeviceListDeviceBasics
  }
  nextToken
}
    ${DeviceListDeviceBasicsFragmentDoc}`;
export const DeviceDetailsFragmentDoc = gql`
    fragment DeviceDetails on Device {
  id
  alias
  name
  state
  init_key
  status {
    id
    state
    status
    statusReason
  }
  tenant {
    id
  }
  contract {
    service
    service_tier
  }
  enabled
}
    `;
export const DeviceInfoDetailsFragmentDoc = gql`
    fragment DeviceInfoDetails on DeviceInfo {
  id
  cpuCoresH
  cpuCoresP
  cpuProcessor
  disks {
    mountPoint
    usage
    size
    fileSystem
  }
  machine
  memorySwap
  memoryTotal
  nics {
    name
    isUp
    sniffer
    speed
  }
  cloud {
    region
    instanceId
    instanceType
    vendor
  }
  os
}
    `;
export const DeviceContractDetailsFragmentDoc = gql`
    fragment DeviceContractDetails on DeviceContract {
  end
  service
  snowRef
  start
  service_tier
}
    `;
export const DeviceListDeviceFragmentDoc = gql`
    fragment DeviceListDevice on Device {
  ...DeviceDetails
  info {
    ...DeviceInfoDetails
  }
  contract {
    ...DeviceContractDetails
  }
}
    ${DeviceDetailsFragmentDoc}
${DeviceInfoDetailsFragmentDoc}
${DeviceContractDetailsFragmentDoc}`;
export const TenantDevicesDetailsFragmentDoc = gql`
    fragment TenantDevicesDetails on DeviceConnection {
  items {
    ...DeviceListDevice
  }
  nextToken
}
    ${DeviceListDeviceFragmentDoc}`;
export const InstantMetricResultFragmentDoc = gql`
    fragment InstantMetricResult on PrometheusInstantResponse {
  data {
    result {
      metric
      value {
        timestamp
        metricValue
      }
    }
  }
}
    `;
export const TenantDeviceMetricsFragmentDoc = gql`
    fragment TenantDeviceMetrics on DeviceMetrics {
  bandwidth {
    ...InstantMetricResult
  }
}
    ${InstantMetricResultFragmentDoc}`;
export const TenantDeviceDetailsFragmentDoc = gql`
    fragment TenantDeviceDetails on Device {
  ...DeviceDetails
  status {
    status
    state
    lastSeen
    e2e
  }
  info {
    ...DeviceInfoDetails
  }
  metrics {
    ...TenantDeviceMetrics
  }
  contract {
    service_tier
  }
}
    ${DeviceDetailsFragmentDoc}
${DeviceInfoDetailsFragmentDoc}
${TenantDeviceMetricsFragmentDoc}`;
export const RangeMetricResultFragmentDoc = gql`
    fragment RangeMetricResult on PrometheusRangeResponse {
  data {
    result {
      metric
      values {
        timestamp
        metricValue
      }
    }
  }
}
    `;
export const TenantMetricsDetailsFragmentDoc = gql`
    fragment TenantMetricsDetails on TenantMetrics {
  dataRetrieved {
    ...InstantMetricResult
  }
  eventsAnalyzed {
    ...InstantMetricResult
  }
  indicators {
    ...InstantMetricResult
  }
  bandwidth {
    ...RangeMetricResult
  }
  eventsPerSecond {
    ...RangeMetricResult
  }
}
    ${InstantMetricResultFragmentDoc}
${RangeMetricResultFragmentDoc}`;
export const TenantDetailsFragmentDoc = gql`
    fragment TenantDetails on Tenant {
  id
  name
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on TenantUser {
  id
  firstName
  lastName
  email
  groups
  tenant {
    ...TenantDetails
  }
  settings {
    emailNotifications
    mobileNotifications
    webNotifications
    confidenceFilter
  }
}
    ${TenantDetailsFragmentDoc}`;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on TenantUser {
  id
  email
  firstName
  lastName
}
    `;
export const TenantInviteDetailsFragmentDoc = gql`
    fragment TenantInviteDetails on TenantInvite {
  email
}
    `;
export const ListTenantMetaEventsDocument = gql`
    query listTenantMetaEvents($filter: TableCtsMetaEventsFilterInput, $nextToken: String, $limit: Int) {
  listTenantMetaEvents(
    filter: $filter
    limit: $limit
    sortDirection: DESC
    nextToken: $nextToken
  ) {
    ...TenantEventsDetails
  }
}
    ${TenantEventsDetailsFragmentDoc}`;

/**
 * __useListTenantMetaEventsQuery__
 *
 * To run a query within a React component, call `useListTenantMetaEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTenantMetaEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTenantMetaEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListTenantMetaEventsQuery(baseOptions?: Apollo.QueryHookOptions<ListTenantMetaEventsQuery, ListTenantMetaEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTenantMetaEventsQuery, ListTenantMetaEventsQueryVariables>(ListTenantMetaEventsDocument, options);
}
export function useListTenantMetaEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTenantMetaEventsQuery, ListTenantMetaEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTenantMetaEventsQuery, ListTenantMetaEventsQueryVariables>(ListTenantMetaEventsDocument, options);
}
export type ListTenantMetaEventsQueryHookResult = ReturnType<typeof useListTenantMetaEventsQuery>;
export type ListTenantMetaEventsLazyQueryHookResult = ReturnType<typeof useListTenantMetaEventsLazyQuery>;
export type ListTenantMetaEventsQueryResult = Apollo.QueryResult<ListTenantMetaEventsQuery, ListTenantMetaEventsQueryVariables>;
export function refetchListTenantMetaEventsQuery(variables?: ListTenantMetaEventsQueryVariables) {
  return { query: ListTenantMetaEventsDocument, variables: variables }
}
export const UpdateSeenByDocument = gql`
    mutation updateSeenBy($input: UpdateTenantMetaEventsInput!) {
  updateTenantMetaEvents(input: $input) {
    ...CtsMetaEventsDetails
  }
}
    ${CtsMetaEventsDetailsFragmentDoc}`;
export type UpdateSeenByMutationFn = Apollo.MutationFunction<UpdateSeenByMutation, UpdateSeenByMutationVariables>;

/**
 * __useUpdateSeenByMutation__
 *
 * To run a mutation, you first call `useUpdateSeenByMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeenByMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeenByMutation, { data, loading, error }] = useUpdateSeenByMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSeenByMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeenByMutation, UpdateSeenByMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSeenByMutation, UpdateSeenByMutationVariables>(UpdateSeenByDocument, options);
}
export type UpdateSeenByMutationHookResult = ReturnType<typeof useUpdateSeenByMutation>;
export type UpdateSeenByMutationResult = Apollo.MutationResult<UpdateSeenByMutation>;
export type UpdateSeenByMutationOptions = Apollo.BaseMutationOptions<UpdateSeenByMutation, UpdateSeenByMutationVariables>;
export const UpdateAlertDetailsSeenByDocument = gql`
    mutation updateAlertDetailsSeenBy($input: UpdateTenantMetaEventInput!) {
  updateTenantMetaEvent(input: $input) {
    ...CtsMetaEventsDetails
  }
}
    ${CtsMetaEventsDetailsFragmentDoc}`;
export type UpdateAlertDetailsSeenByMutationFn = Apollo.MutationFunction<UpdateAlertDetailsSeenByMutation, UpdateAlertDetailsSeenByMutationVariables>;

/**
 * __useUpdateAlertDetailsSeenByMutation__
 *
 * To run a mutation, you first call `useUpdateAlertDetailsSeenByMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertDetailsSeenByMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertDetailsSeenByMutation, { data, loading, error }] = useUpdateAlertDetailsSeenByMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertDetailsSeenByMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAlertDetailsSeenByMutation, UpdateAlertDetailsSeenByMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAlertDetailsSeenByMutation, UpdateAlertDetailsSeenByMutationVariables>(UpdateAlertDetailsSeenByDocument, options);
}
export type UpdateAlertDetailsSeenByMutationHookResult = ReturnType<typeof useUpdateAlertDetailsSeenByMutation>;
export type UpdateAlertDetailsSeenByMutationResult = Apollo.MutationResult<UpdateAlertDetailsSeenByMutation>;
export type UpdateAlertDetailsSeenByMutationOptions = Apollo.BaseMutationOptions<UpdateAlertDetailsSeenByMutation, UpdateAlertDetailsSeenByMutationVariables>;
export const GetTenantMetaEventDocument = gql`
    query getTenantMetaEvent($id: ID!) {
  getTenantMetaEvent(id: $id) {
    ...GetMetaEventDetails
  }
}
    ${GetMetaEventDetailsFragmentDoc}`;

/**
 * __useGetTenantMetaEventQuery__
 *
 * To run a query within a React component, call `useGetTenantMetaEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantMetaEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantMetaEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantMetaEventQuery(baseOptions: Apollo.QueryHookOptions<GetTenantMetaEventQuery, GetTenantMetaEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTenantMetaEventQuery, GetTenantMetaEventQueryVariables>(GetTenantMetaEventDocument, options);
}
export function useGetTenantMetaEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantMetaEventQuery, GetTenantMetaEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTenantMetaEventQuery, GetTenantMetaEventQueryVariables>(GetTenantMetaEventDocument, options);
}
export type GetTenantMetaEventQueryHookResult = ReturnType<typeof useGetTenantMetaEventQuery>;
export type GetTenantMetaEventLazyQueryHookResult = ReturnType<typeof useGetTenantMetaEventLazyQuery>;
export type GetTenantMetaEventQueryResult = Apollo.QueryResult<GetTenantMetaEventQuery, GetTenantMetaEventQueryVariables>;
export function refetchGetTenantMetaEventQuery(variables?: GetTenantMetaEventQueryVariables) {
  return { query: GetTenantMetaEventDocument, variables: variables }
}
export const ListTenantMetaEventsBasicDocument = gql`
    query listTenantMetaEventsBasic($filter: TableCtsMetaEventsFilterInput, $nextToken: String, $limit: Int) {
  listTenantMetaEvents(
    filter: $filter
    limit: $limit
    sortDirection: DESC
    nextToken: $nextToken
  ) {
    ...TenantEventsDetails
  }
}
    ${TenantEventsDetailsFragmentDoc}`;

/**
 * __useListTenantMetaEventsBasicQuery__
 *
 * To run a query within a React component, call `useListTenantMetaEventsBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTenantMetaEventsBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTenantMetaEventsBasicQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListTenantMetaEventsBasicQuery(baseOptions?: Apollo.QueryHookOptions<ListTenantMetaEventsBasicQuery, ListTenantMetaEventsBasicQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTenantMetaEventsBasicQuery, ListTenantMetaEventsBasicQueryVariables>(ListTenantMetaEventsBasicDocument, options);
}
export function useListTenantMetaEventsBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTenantMetaEventsBasicQuery, ListTenantMetaEventsBasicQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTenantMetaEventsBasicQuery, ListTenantMetaEventsBasicQueryVariables>(ListTenantMetaEventsBasicDocument, options);
}
export type ListTenantMetaEventsBasicQueryHookResult = ReturnType<typeof useListTenantMetaEventsBasicQuery>;
export type ListTenantMetaEventsBasicLazyQueryHookResult = ReturnType<typeof useListTenantMetaEventsBasicLazyQuery>;
export type ListTenantMetaEventsBasicQueryResult = Apollo.QueryResult<ListTenantMetaEventsBasicQuery, ListTenantMetaEventsBasicQueryVariables>;
export function refetchListTenantMetaEventsBasicQuery(variables?: ListTenantMetaEventsBasicQueryVariables) {
  return { query: ListTenantMetaEventsBasicDocument, variables: variables }
}
export const ListTenantDevicesBasicsDocument = gql`
    query listTenantDevicesBasics($nextToken: String) {
  listTenantDevices(nextToken: $nextToken) {
    ...TenantDevicesDetailsBasics
  }
}
    ${TenantDevicesDetailsBasicsFragmentDoc}`;

/**
 * __useListTenantDevicesBasicsQuery__
 *
 * To run a query within a React component, call `useListTenantDevicesBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTenantDevicesBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTenantDevicesBasicsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListTenantDevicesBasicsQuery(baseOptions?: Apollo.QueryHookOptions<ListTenantDevicesBasicsQuery, ListTenantDevicesBasicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTenantDevicesBasicsQuery, ListTenantDevicesBasicsQueryVariables>(ListTenantDevicesBasicsDocument, options);
}
export function useListTenantDevicesBasicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTenantDevicesBasicsQuery, ListTenantDevicesBasicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTenantDevicesBasicsQuery, ListTenantDevicesBasicsQueryVariables>(ListTenantDevicesBasicsDocument, options);
}
export type ListTenantDevicesBasicsQueryHookResult = ReturnType<typeof useListTenantDevicesBasicsQuery>;
export type ListTenantDevicesBasicsLazyQueryHookResult = ReturnType<typeof useListTenantDevicesBasicsLazyQuery>;
export type ListTenantDevicesBasicsQueryResult = Apollo.QueryResult<ListTenantDevicesBasicsQuery, ListTenantDevicesBasicsQueryVariables>;
export function refetchListTenantDevicesBasicsQuery(variables?: ListTenantDevicesBasicsQueryVariables) {
  return { query: ListTenantDevicesBasicsDocument, variables: variables }
}
export const OnCreateDeviceFromAppDocument = gql`
    subscription onCreateDeviceFromApp {
  onCreateDeviceFromApp {
    ...DeviceDetails
  }
}
    ${DeviceDetailsFragmentDoc}`;

/**
 * __useOnCreateDeviceFromAppSubscription__
 *
 * To run a query within a React component, call `useOnCreateDeviceFromAppSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateDeviceFromAppSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateDeviceFromAppSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateDeviceFromAppSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateDeviceFromAppSubscription, OnCreateDeviceFromAppSubscriptionVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<OnCreateDeviceFromAppSubscription, OnCreateDeviceFromAppSubscriptionVariables>(OnCreateDeviceFromAppDocument, options);
}
export type OnCreateDeviceFromAppSubscriptionHookResult = ReturnType<typeof useOnCreateDeviceFromAppSubscription>;
export type OnCreateDeviceFromAppSubscriptionResult = Apollo.SubscriptionResult<OnCreateDeviceFromAppSubscription>;
export const CreateDeviceDocument = gql`
    mutation createDevice($input: CreateDeviceInput!) {
  createDeviceFromApp(input: $input) {
    id
    alias
    authGroup
    contract {
      ...DeviceContractDetails
    }
    enabled
    name
    site
    state
    template
    templateId
    tenant {
      id
    }
    init_key
  }
}
    ${DeviceContractDetailsFragmentDoc}`;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDeviceMutation, CreateDeviceMutationVariables>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, options);
}
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<CreateDeviceMutation, CreateDeviceMutationVariables>;
export const ListTenantDevicesDocument = gql`
    query listTenantDevices($filter: TableDeviceFilterInput, $nextToken: String) {
  listTenantDevices(filter: $filter, nextToken: $nextToken) {
    ...TenantDevicesDetails
  }
}
    ${TenantDevicesDetailsFragmentDoc}`;

/**
 * __useListTenantDevicesQuery__
 *
 * To run a query within a React component, call `useListTenantDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTenantDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTenantDevicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListTenantDevicesQuery(baseOptions?: Apollo.QueryHookOptions<ListTenantDevicesQuery, ListTenantDevicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTenantDevicesQuery, ListTenantDevicesQueryVariables>(ListTenantDevicesDocument, options);
}
export function useListTenantDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTenantDevicesQuery, ListTenantDevicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTenantDevicesQuery, ListTenantDevicesQueryVariables>(ListTenantDevicesDocument, options);
}
export type ListTenantDevicesQueryHookResult = ReturnType<typeof useListTenantDevicesQuery>;
export type ListTenantDevicesLazyQueryHookResult = ReturnType<typeof useListTenantDevicesLazyQuery>;
export type ListTenantDevicesQueryResult = Apollo.QueryResult<ListTenantDevicesQuery, ListTenantDevicesQueryVariables>;
export function refetchListTenantDevicesQuery(variables?: ListTenantDevicesQueryVariables) {
  return { query: ListTenantDevicesDocument, variables: variables }
}
export const GetDeviceInitCommandDocument = gql`
    query getDeviceInitCommand($id: ID!) {
  getDeviceInitCommand(id: $id)
}
    `;

/**
 * __useGetDeviceInitCommandQuery__
 *
 * To run a query within a React component, call `useGetDeviceInitCommandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceInitCommandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceInitCommandQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeviceInitCommandQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceInitCommandQuery, GetDeviceInitCommandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeviceInitCommandQuery, GetDeviceInitCommandQueryVariables>(GetDeviceInitCommandDocument, options);
}
export function useGetDeviceInitCommandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceInitCommandQuery, GetDeviceInitCommandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeviceInitCommandQuery, GetDeviceInitCommandQueryVariables>(GetDeviceInitCommandDocument, options);
}
export type GetDeviceInitCommandQueryHookResult = ReturnType<typeof useGetDeviceInitCommandQuery>;
export type GetDeviceInitCommandLazyQueryHookResult = ReturnType<typeof useGetDeviceInitCommandLazyQuery>;
export type GetDeviceInitCommandQueryResult = Apollo.QueryResult<GetDeviceInitCommandQuery, GetDeviceInitCommandQueryVariables>;
export function refetchGetDeviceInitCommandQuery(variables?: GetDeviceInitCommandQueryVariables) {
  return { query: GetDeviceInitCommandDocument, variables: variables }
}
export const GetTenantDeviceDocument = gql`
    query getTenantDevice($id: ID!) {
  getTenantDevice(id: $id) {
    ...TenantDeviceDetails
  }
}
    ${TenantDeviceDetailsFragmentDoc}`;

/**
 * __useGetTenantDeviceQuery__
 *
 * To run a query within a React component, call `useGetTenantDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantDeviceQuery(baseOptions: Apollo.QueryHookOptions<GetTenantDeviceQuery, GetTenantDeviceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTenantDeviceQuery, GetTenantDeviceQueryVariables>(GetTenantDeviceDocument, options);
}
export function useGetTenantDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantDeviceQuery, GetTenantDeviceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTenantDeviceQuery, GetTenantDeviceQueryVariables>(GetTenantDeviceDocument, options);
}
export type GetTenantDeviceQueryHookResult = ReturnType<typeof useGetTenantDeviceQuery>;
export type GetTenantDeviceLazyQueryHookResult = ReturnType<typeof useGetTenantDeviceLazyQuery>;
export type GetTenantDeviceQueryResult = Apollo.QueryResult<GetTenantDeviceQuery, GetTenantDeviceQueryVariables>;
export function refetchGetTenantDeviceQuery(variables?: GetTenantDeviceQueryVariables) {
  return { query: GetTenantDeviceDocument, variables: variables }
}
export const EnrollTenantDeviceDocument = gql`
    mutation enrollTenantDevice($input: EnrollTenantDeviceInput!) {
  enrollTenantDevice(input: $input) {
    ...DeviceDetails
  }
}
    ${DeviceDetailsFragmentDoc}`;
export type EnrollTenantDeviceMutationFn = Apollo.MutationFunction<EnrollTenantDeviceMutation, EnrollTenantDeviceMutationVariables>;

/**
 * __useEnrollTenantDeviceMutation__
 *
 * To run a mutation, you first call `useEnrollTenantDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollTenantDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollTenantDeviceMutation, { data, loading, error }] = useEnrollTenantDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollTenantDeviceMutation(baseOptions?: Apollo.MutationHookOptions<EnrollTenantDeviceMutation, EnrollTenantDeviceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EnrollTenantDeviceMutation, EnrollTenantDeviceMutationVariables>(EnrollTenantDeviceDocument, options);
}
export type EnrollTenantDeviceMutationHookResult = ReturnType<typeof useEnrollTenantDeviceMutation>;
export type EnrollTenantDeviceMutationResult = Apollo.MutationResult<EnrollTenantDeviceMutation>;
export type EnrollTenantDeviceMutationOptions = Apollo.BaseMutationOptions<EnrollTenantDeviceMutation, EnrollTenantDeviceMutationVariables>;
export const OnUpdateTenantDeviceDocument = gql`
    subscription onUpdateTenantDevice($tenantId: ID!) {
  onUpdateTenantDevice(tenantId: $tenantId) {
    ...DeviceDetails
  }
}
    ${DeviceDetailsFragmentDoc}`;

/**
 * __useOnUpdateTenantDeviceSubscription__
 *
 * To run a query within a React component, call `useOnUpdateTenantDeviceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateTenantDeviceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateTenantDeviceSubscription({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useOnUpdateTenantDeviceSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnUpdateTenantDeviceSubscription, OnUpdateTenantDeviceSubscriptionVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<OnUpdateTenantDeviceSubscription, OnUpdateTenantDeviceSubscriptionVariables>(OnUpdateTenantDeviceDocument, options);
}
export type OnUpdateTenantDeviceSubscriptionHookResult = ReturnType<typeof useOnUpdateTenantDeviceSubscription>;
export type OnUpdateTenantDeviceSubscriptionResult = Apollo.SubscriptionResult<OnUpdateTenantDeviceSubscription>;
export const TenantMetricsDocument = gql`
    query tenantMetrics($range: String!, $start: AWSTimestamp, $end: AWSTimestamp, $step: String) {
  tenantMetrics(range: $range, start: $start, end: $end, step: $step) {
    ...TenantMetricsDetails
  }
}
    ${TenantMetricsDetailsFragmentDoc}`;

/**
 * __useTenantMetricsQuery__
 *
 * To run a query within a React component, call `useTenantMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantMetricsQuery({
 *   variables: {
 *      range: // value for 'range'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useTenantMetricsQuery(baseOptions: Apollo.QueryHookOptions<TenantMetricsQuery, TenantMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TenantMetricsQuery, TenantMetricsQueryVariables>(TenantMetricsDocument, options);
}
export function useTenantMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantMetricsQuery, TenantMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TenantMetricsQuery, TenantMetricsQueryVariables>(TenantMetricsDocument, options);
}
export type TenantMetricsQueryHookResult = ReturnType<typeof useTenantMetricsQuery>;
export type TenantMetricsLazyQueryHookResult = ReturnType<typeof useTenantMetricsLazyQuery>;
export type TenantMetricsQueryResult = Apollo.QueryResult<TenantMetricsQuery, TenantMetricsQueryVariables>;
export function refetchTenantMetricsQuery(variables?: TenantMetricsQueryVariables) {
  return { query: TenantMetricsDocument, variables: variables }
}
export const UpdateUserPushTokenDocument = gql`
    mutation updateUserPushToken($token: String!) {
  updateUserPushToken(token: $token)
}
    `;
export type UpdateUserPushTokenMutationFn = Apollo.MutationFunction<UpdateUserPushTokenMutation, UpdateUserPushTokenMutationVariables>;

/**
 * __useUpdateUserPushTokenMutation__
 *
 * To run a mutation, you first call `useUpdateUserPushTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPushTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPushTokenMutation, { data, loading, error }] = useUpdateUserPushTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdateUserPushTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPushTokenMutation, UpdateUserPushTokenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserPushTokenMutation, UpdateUserPushTokenMutationVariables>(UpdateUserPushTokenDocument, options);
}
export type UpdateUserPushTokenMutationHookResult = ReturnType<typeof useUpdateUserPushTokenMutation>;
export type UpdateUserPushTokenMutationResult = Apollo.MutationResult<UpdateUserPushTokenMutation>;
export type UpdateUserPushTokenMutationOptions = Apollo.BaseMutationOptions<UpdateUserPushTokenMutation, UpdateUserPushTokenMutationVariables>;
export const SubscribeToFcmTopicDocument = gql`
    mutation subscribeToFCMTopic($token: String!) {
  subscribeToFCMTopic(token: $token)
}
    `;
export type SubscribeToFcmTopicMutationFn = Apollo.MutationFunction<SubscribeToFcmTopicMutation, SubscribeToFcmTopicMutationVariables>;

/**
 * __useSubscribeToFcmTopicMutation__
 *
 * To run a mutation, you first call `useSubscribeToFcmTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToFcmTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToFcmTopicMutation, { data, loading, error }] = useSubscribeToFcmTopicMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSubscribeToFcmTopicMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToFcmTopicMutation, SubscribeToFcmTopicMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubscribeToFcmTopicMutation, SubscribeToFcmTopicMutationVariables>(SubscribeToFcmTopicDocument, options);
}
export type SubscribeToFcmTopicMutationHookResult = ReturnType<typeof useSubscribeToFcmTopicMutation>;
export type SubscribeToFcmTopicMutationResult = Apollo.MutationResult<SubscribeToFcmTopicMutation>;
export type SubscribeToFcmTopicMutationOptions = Apollo.BaseMutationOptions<SubscribeToFcmTopicMutation, SubscribeToFcmTopicMutationVariables>;
export const UpdateTenantUserSettingsDocument = gql`
    mutation updateTenantUserSettings($input: UpdateTenantUserSettingsInput!) {
  updateTenantUserSettings(input: $input) {
    emailNotifications
    mobileNotifications
    webNotifications
    confidenceFilter
  }
}
    `;
export type UpdateTenantUserSettingsMutationFn = Apollo.MutationFunction<UpdateTenantUserSettingsMutation, UpdateTenantUserSettingsMutationVariables>;

/**
 * __useUpdateTenantUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateTenantUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantUserSettingsMutation, { data, loading, error }] = useUpdateTenantUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantUserSettingsMutation, UpdateTenantUserSettingsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTenantUserSettingsMutation, UpdateTenantUserSettingsMutationVariables>(UpdateTenantUserSettingsDocument, options);
}
export type UpdateTenantUserSettingsMutationHookResult = ReturnType<typeof useUpdateTenantUserSettingsMutation>;
export type UpdateTenantUserSettingsMutationResult = Apollo.MutationResult<UpdateTenantUserSettingsMutation>;
export type UpdateTenantUserSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateTenantUserSettingsMutation, UpdateTenantUserSettingsMutationVariables>;
export const ListTenantsDocument = gql`
    query listTenants {
  listTenants {
    items {
      ...TenantDetails
    }
  }
}
    ${TenantDetailsFragmentDoc}`;

/**
 * __useListTenantsQuery__
 *
 * To run a query within a React component, call `useListTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTenantsQuery(baseOptions?: Apollo.QueryHookOptions<ListTenantsQuery, ListTenantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTenantsQuery, ListTenantsQueryVariables>(ListTenantsDocument, options);
}
export function useListTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTenantsQuery, ListTenantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTenantsQuery, ListTenantsQueryVariables>(ListTenantsDocument, options);
}
export type ListTenantsQueryHookResult = ReturnType<typeof useListTenantsQuery>;
export type ListTenantsLazyQueryHookResult = ReturnType<typeof useListTenantsLazyQuery>;
export type ListTenantsQueryResult = Apollo.QueryResult<ListTenantsQuery, ListTenantsQueryVariables>;
export function refetchListTenantsQuery(variables?: ListTenantsQueryVariables) {
  return { query: ListTenantsDocument, variables: variables }
}
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  getCurrentUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export function refetchGetCurrentUserQuery(variables?: GetCurrentUserQueryVariables) {
  return { query: GetCurrentUserDocument, variables: variables }
}
export const ListOrgUsersDocument = gql`
    query listOrgUsers($orgId: ID!, $nextToken: String) {
  listOrgUsers(orgId: $orgId, nextToken: $nextToken) {
    items {
      ...UserDetails
    }
    nextToken
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useListOrgUsersQuery__
 *
 * To run a query within a React component, call `useListOrgUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrgUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrgUsersQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListOrgUsersQuery(baseOptions: Apollo.QueryHookOptions<ListOrgUsersQuery, ListOrgUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListOrgUsersQuery, ListOrgUsersQueryVariables>(ListOrgUsersDocument, options);
}
export function useListOrgUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrgUsersQuery, ListOrgUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListOrgUsersQuery, ListOrgUsersQueryVariables>(ListOrgUsersDocument, options);
}
export type ListOrgUsersQueryHookResult = ReturnType<typeof useListOrgUsersQuery>;
export type ListOrgUsersLazyQueryHookResult = ReturnType<typeof useListOrgUsersLazyQuery>;
export type ListOrgUsersQueryResult = Apollo.QueryResult<ListOrgUsersQuery, ListOrgUsersQueryVariables>;
export function refetchListOrgUsersQuery(variables?: ListOrgUsersQueryVariables) {
  return { query: ListOrgUsersDocument, variables: variables }
}
export const ListOrgInvitesDocument = gql`
    query listOrgInvites($orgId: ID!, $nextToken: String) {
  listOrgInvites(orgId: $orgId, nextToken: $nextToken) {
    items {
      ...TenantInviteDetails
    }
    nextToken
  }
}
    ${TenantInviteDetailsFragmentDoc}`;

/**
 * __useListOrgInvitesQuery__
 *
 * To run a query within a React component, call `useListOrgInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrgInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrgInvitesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListOrgInvitesQuery(baseOptions: Apollo.QueryHookOptions<ListOrgInvitesQuery, ListOrgInvitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListOrgInvitesQuery, ListOrgInvitesQueryVariables>(ListOrgInvitesDocument, options);
}
export function useListOrgInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrgInvitesQuery, ListOrgInvitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListOrgInvitesQuery, ListOrgInvitesQueryVariables>(ListOrgInvitesDocument, options);
}
export type ListOrgInvitesQueryHookResult = ReturnType<typeof useListOrgInvitesQuery>;
export type ListOrgInvitesLazyQueryHookResult = ReturnType<typeof useListOrgInvitesLazyQuery>;
export type ListOrgInvitesQueryResult = Apollo.QueryResult<ListOrgInvitesQuery, ListOrgInvitesQueryVariables>;
export function refetchListOrgInvitesQuery(variables?: ListOrgInvitesQueryVariables) {
  return { query: ListOrgInvitesDocument, variables: variables }
}
export const SendOrgInviteEmailDocument = gql`
    mutation sendOrgInviteEmail($orgId: ID!, $email: String!) {
  sendOrgInviteEmail(orgId: $orgId, email: $email) {
    ...TenantInviteDetails
  }
}
    ${TenantInviteDetailsFragmentDoc}`;
export type SendOrgInviteEmailMutationFn = Apollo.MutationFunction<SendOrgInviteEmailMutation, SendOrgInviteEmailMutationVariables>;

/**
 * __useSendOrgInviteEmailMutation__
 *
 * To run a mutation, you first call `useSendOrgInviteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrgInviteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrgInviteEmailMutation, { data, loading, error }] = useSendOrgInviteEmailMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendOrgInviteEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendOrgInviteEmailMutation, SendOrgInviteEmailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendOrgInviteEmailMutation, SendOrgInviteEmailMutationVariables>(SendOrgInviteEmailDocument, options);
}
export type SendOrgInviteEmailMutationHookResult = ReturnType<typeof useSendOrgInviteEmailMutation>;
export type SendOrgInviteEmailMutationResult = Apollo.MutationResult<SendOrgInviteEmailMutation>;
export type SendOrgInviteEmailMutationOptions = Apollo.BaseMutationOptions<SendOrgInviteEmailMutation, SendOrgInviteEmailMutationVariables>;
export const DevUpdateTenantUserDocument = gql`
    mutation devUpdateTenantUser($input: UpdateTenantUserInput!) {
  devUpdateTenantUser(input: $input) {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type DevUpdateTenantUserMutationFn = Apollo.MutationFunction<DevUpdateTenantUserMutation, DevUpdateTenantUserMutationVariables>;

/**
 * __useDevUpdateTenantUserMutation__
 *
 * To run a mutation, you first call `useDevUpdateTenantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevUpdateTenantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [devUpdateTenantUserMutation, { data, loading, error }] = useDevUpdateTenantUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDevUpdateTenantUserMutation(baseOptions?: Apollo.MutationHookOptions<DevUpdateTenantUserMutation, DevUpdateTenantUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DevUpdateTenantUserMutation, DevUpdateTenantUserMutationVariables>(DevUpdateTenantUserDocument, options);
}
export type DevUpdateTenantUserMutationHookResult = ReturnType<typeof useDevUpdateTenantUserMutation>;
export type DevUpdateTenantUserMutationResult = Apollo.MutationResult<DevUpdateTenantUserMutation>;
export type DevUpdateTenantUserMutationOptions = Apollo.BaseMutationOptions<DevUpdateTenantUserMutation, DevUpdateTenantUserMutationVariables>;
export const DeleteTenantUserDocument = gql`
    mutation deleteTenantUser($userId: ID!) {
  deleteTenantUser(userId: $userId)
}
    `;
export type DeleteTenantUserMutationFn = Apollo.MutationFunction<DeleteTenantUserMutation, DeleteTenantUserMutationVariables>;

/**
 * __useDeleteTenantUserMutation__
 *
 * To run a mutation, you first call `useDeleteTenantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantUserMutation, { data, loading, error }] = useDeleteTenantUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteTenantUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTenantUserMutation, DeleteTenantUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTenantUserMutation, DeleteTenantUserMutationVariables>(DeleteTenantUserDocument, options);
}
export type DeleteTenantUserMutationHookResult = ReturnType<typeof useDeleteTenantUserMutation>;
export type DeleteTenantUserMutationResult = Apollo.MutationResult<DeleteTenantUserMutation>;
export type DeleteTenantUserMutationOptions = Apollo.BaseMutationOptions<DeleteTenantUserMutation, DeleteTenantUserMutationVariables>;

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "MetaEventDataSource",
        "possibleTypes": [
          {
            "name": "MetaEventBlobDataSource"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PrometheusResponse",
        "possibleTypes": [
          {
            "name": "PrometheusInstantResponse"
          },
          {
            "name": "PrometheusRangeResponse"
          }
        ]
      }
    ]
  }
};
export default result;

